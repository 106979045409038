import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import _ from 'lodash';
import { RelationalOperator } from '../../../../types/segment.types';
import NegateToggle from './NegateToggle';

interface RelationOperatorSelectProps {
  value: RelationalOperator | null;
  values: RelationalOperator[];
  onChange: any;
  negate: boolean;
  onNegateChange: any;
  disabled?: boolean;
  error?: string;
}

interface Option {
  title: string;
  value: RelationalOperator;
}

const Options: Option[] = [
  { title: 'Equal', value: 'EQ' },
  { title: 'Less Than', value: 'LT' },
  { title: 'Less Than or Equal', value: 'LTE' },
  { title: 'Between', value: 'BETWEEN' },
  { title: 'Greater Than', value: 'GT' },
  { title: 'Greater Than or Equal', value: 'GTE' },
  { title: 'One of', value: 'ONE_OF' },
  { title: 'Starts With', value: 'PREFIX' },
  { title: 'Contains', value: 'CONTAINS' },
  { title: 'Ends With', value: 'SUFFIX' },
  { title: 'Anniversary', value: 'ANNIVERSARY' },
  { title: 'Empty', value: 'IS_NULL' },
];

const RelationOperatorSelect: React.FC<RelationOperatorSelectProps> = ({
  value,
  values,
  onChange,
  negate,
  onNegateChange,
  disabled,
  error,
}) => {
  const options = _.filter(Options, (o) => _.includes(values, o.value));
  return (
    <FormControl fullWidth disabled={disabled} error={error ? true : false}>
      <InputLabel size="small">
        {value ? (
          <NegateToggle
            btnProps={{ disabled: disabled }}
            value={negate}
            onChange={onNegateChange}
          />
        ) : disabled ? (
          ''
        ) : (
          'Relation'
        )}
      </InputLabel>
      <Select
        sx={{ opacity: disabled ? 0.5 : 1 }}
        inputProps={{
          sx: value ? { pt: 1.5, pb: 0.5 } : { borderColor: 'red' },
        }}
        value={value || ''}
        onChange={onChange}
        size="small"
        label={disabled ? '' : 'Relation'}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default RelationOperatorSelect;
