import React, { FC, ReactNode } from "react";
import { SwipeableDrawer, Box } from "@mui/material";
import { globalThemeConfig } from "../../../../../../styles/theme";

interface MobileDrawerProps {
  open?: boolean;
  onClose?: () => void;
  children: ReactNode | ReactNode[];
}

const MobileDrawer: FC<MobileDrawerProps> = ({
  open = false,
  onClose = () => { },
  children,
}) => {
  const iOS =
    typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Box sx={{ display: { xs: "block", lg: "none" } }}>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        variant="temporary"
        anchor="left"
        open={open}
        onOpen={onClose}
        onClose={onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          "& > .MuiDrawer-paper": {
            width: `${globalThemeConfig.drawerWidth}px`,
          },
        }}
      >
        {children}
      </SwipeableDrawer>
    </Box>
  );
};

export default MobileDrawer;
