import { useQuery } from '@apollo/client';
import {
  BubbleChart,
  Campaign,
  CheckCircleOutline,
  People as Customers,
  Groups,
  Segment,
  WebRounded,
} from '@mui/icons-material';
import { Grid, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { ReactComponent as DashboardBannerLeftDarkImage } from '../../assets/illustrations/Dashboard/dashboardBannerDarkLeft.svg';
import { ReactComponent as DashboardBannerRightDarkImage } from '../../assets/illustrations/Dashboard/dashboardBannerDarkRight.svg';
import { ReactComponent as DashboardBannerLeftImage } from '../../assets/illustrations/Dashboard/dashboardBannerLeft.svg';
import { ReactComponent as DashboardBannerRightImage } from '../../assets/illustrations/Dashboard/dashboardBannerRight.svg';
import Banner from '../../components/Banner';
import DashboardKpiCard from '../../components/DashboardKpiCard';
import KpiCard from '../../components/KpiCard';
import Loading from '../../components/Loading';
import Navigation from '../../components/Navigation/Navigation';
import TabView from '../../components/TabView';
import { GET_CURRENT_CAMPAIGNS } from '../../graphql/local/queries/campaigns'; // Adjust the path and query name accordingly
import { GET_OPERATOR_STATS } from '../../graphql/local/queries/dashboard';
import useOperator from '../../hooks/useOperator';
import { DashboardQueryResponse } from '../../types/dashboard.types';

// Import the CampaignCard from your second code
import { useNavigate } from 'react-router-dom';
import CampaignCard from '../../components/Campaign/CampaignCard';
import SegmentCard from '../../components/Segment/SegmentCard';
import TemplateCard from '../../components/Template/TemplateCard';
import { GET_LIBRARY_TEMPLATES } from '../../graphql/local';
import { LIST_SEGMENTS } from '../../graphql/local/queries/segments';
import {
  CampaignQueryResponse,
  CurrentCampaignStatus,
} from '../../types/campaign.types';
import { ListSegmentsQueryResponse } from '../../types/segment.types';
import { LibraryTemplatesQueryResponse } from '../../types/template.types';

export default function Dashboard() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [operator] = useOperator();
  const tabValues = ['Campaigns', 'Segments', 'Templates'];
  const [tabIndex, setTabIndex] = React.useState(0);
  const navigate = useNavigate();

  const {
    loading,
    error,
    data: operatorStats,
    refetch: totalCustomersCountRefetcher,
  } = useQuery<DashboardQueryResponse>(GET_OPERATOR_STATS, {
    variables: {
      operatorId: operator?.id || 0,
    },
    pollInterval: 15000,
  });

  const handleRefresh = useCallback(() => {
    totalCustomersCountRefetcher();
  }, [totalCustomersCountRefetcher]);

  const totalCustomersCount = operatorStats?.operator?.customersCount;
  const totalCampaignsCount = operatorStats?.operator?.campaignsCount;
  const totalSegmentsCount = operatorStats?.operator?.segmentsCount;
  const totalTemplatesCount = operatorStats?.operator?.libraryTemplatesCount;

  const [isLoading, setIsLoading] = React.useState(loading);

  const {
    data: campaignData,
    loading: campaignLoading,
    error: campaignError,
  } = useQuery<CampaignQueryResponse>(GET_CURRENT_CAMPAIGNS, {
    variables: {
      operatorId: operator?.id || 0,
      status: CurrentCampaignStatus.RUNNING,
      notifyOnNetworkStatusChange: true,
    },
  });

  const {
    data: segmentsData,
    loading: segmentLoading,
    error: segmentError,
  } = useQuery<ListSegmentsQueryResponse>(LIST_SEGMENTS, {
    variables: {
      operatorId: operator?.id || 0,
      notifyOnNetworkStatusChange: true,
    },
  });

  const {
    data: templatesData,
    loading: templateLoading,
    error: templateError,
  } = useQuery<LibraryTemplatesQueryResponse>(GET_LIBRARY_TEMPLATES, {
    variables: {
      operatorId: operator?.id || 0,
      notifyOnNetworkStatusChange: true,
    },
  });

  React.useEffect(() => {
    setIsLoading(campaignLoading || segmentLoading || templateLoading);
  }, [campaignLoading, segmentLoading, templateLoading]);

  // UseEffect for refreshing data
  React.useEffect(() => {
    handleRefresh();
  }, [operator, handleRefresh]);

  // Loading and error handling logic
  if (campaignLoading || segmentLoading || templateLoading) {
    return <Loading />;
  }

  if (campaignError) {
    console.error('Error fetching campaign data:', campaignError);
    return (
      <Navigation>
        <p>Error fetching campaign data. Please try again later.</p>
      </Navigation>
    );
  }

  if (segmentError) {
    console.error('Error fetching segments data:', segmentError);
    return (
      <Navigation>
        <p>Error fetching segments data. Please try again later.</p>
      </Navigation>
    );
  }

  if (templateError) {
    console.error('Error fetching templates data:', templateError);
    return (
      <Navigation>
        <p>Error fetching templates data. Please try again later.</p>
      </Navigation>
    );
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    // Handle tab change for Dashboard page
    setTabIndex(tabValues.indexOf(newValue));
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Navigation>
        <p>Error: {error.message}</p>
      </Navigation>
    );
  }

  return (
    <Navigation>
      <Banner
        leftImage={
          isDarkMode ? (
            <DashboardBannerLeftDarkImage />
          ) : (
            <DashboardBannerLeftImage />
          )
        }
        rightImage={
          isDarkMode ? (
            <DashboardBannerRightDarkImage />
          ) : (
            <DashboardBannerRightImage />
          )
        }
        kpiCards={[
          <KpiCard
            icon={<Groups />}
            title="Customers Count"
            value={totalCustomersCount?.toLocaleString() || '0'}
          ></KpiCard>,
          <KpiCard
            icon={<Campaign />}
            title="Campaigns Count"
            value={totalCampaignsCount?.toLocaleString() || '0'}
          ></KpiCard>,
          <KpiCard
            icon={<Segment />}
            title="Segments Count"
            value={totalSegmentsCount?.toLocaleString() || '0'}
          ></KpiCard>,
        ]}
      />
      <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
        <Grid item xs={12} md={4} sx={{ height: '100%' }}>
          <DashboardKpiCard
            icon={<Customers />}
            title="Total Customers"
            value={totalCustomersCount || 0}
            expectedValue={1000000}
            icon1={<CheckCircleOutline />}
            title1="Max Customers"
            value1={1000000}
          ></DashboardKpiCard>
        </Grid>

        <Grid item xs={12} md={4} sx={{ height: '100%' }}>
          <DashboardKpiCard
            icon={<BubbleChart />}
            title="Total Segments"
            value={totalSegmentsCount || 0}
            expectedValue={100}
            icon1={<CheckCircleOutline />}
            title1="Max Segments"
            value1={100}
          ></DashboardKpiCard>
        </Grid>

        <Grid item xs={12} md={4} sx={{ height: '100%' }}>
          <DashboardKpiCard
            icon={<WebRounded />}
            title="Total Templates"
            value={totalTemplatesCount || 0}
            expectedValue={50}
            icon1={<CheckCircleOutline />}
            title1="Max Templates"
            value1={50}
          ></DashboardKpiCard>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TabView
            tabValues={tabValues}
            defaultValue="Campaigns"
            onChange={handleTabChange}
          />
        </Grid>
      </Grid>

      {/* Use CampaignCard component for displaying latest campaigns */}
      <Grid container spacing={2}>
        {tabIndex === 0 &&
          (campaignData?.operator.currentCampaigns.items || [])
            .slice(0, 3)
            .map((campaign: any) => (
              <Grid item key={campaign.id} xs={12} md={6} lg={6} xl={4}>
                <CampaignCard
                  campaignId={campaign.id}
                  campaignState={campaign.state}
                  campaignTitle={campaign.title}
                  messageType={campaign.messageType}
                  schedulingType={campaign.executionSpec.schedulingType}
                  onDetailsClick={() => {
                    navigate(`/campaigns/${campaign.id}`);
                  }}
                  stats={campaign.stats}
                  createTime={campaign.createTime}
                  progress={campaign.progress}
                />
              </Grid>
            ))}
        {tabIndex === 1 &&
          (segmentsData?.operator.segments.items || [])
            .slice(0, 3)
            .map((segment: any) => (
              <Grid item key={segment.id} xs={12} md={6} lg={6} xl={4}>
                <SegmentCard segment={segment} />
              </Grid>
            ))}
        {tabIndex === 2 &&
          (templatesData?.operator.libraryTemplates.items || [])
            .slice(0, 3)
            .map((template: any) => (
              <Grid item key={template.id} xs={12} md={6} lg={6} xl={4}>
                <TemplateCard
                  templateStatus={template.status}
                  templateUrls={template.templateUrls}
                  templateTitle={template.title}
                  templateDescription={template.description}
                  createTime={template.createTime}
                  contentKind={template.contentKind}
                  onDetailsClick={() => {
                    navigate(`/templates/${template.id}`);
                  }}
                />
              </Grid>
            ))}
      </Grid>
    </Navigation>
  );
}
