import { Remove } from '@mui/icons-material';
import {
  Divider,
  Grid,
  IconButton,
  Stack,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { ChangeEvent } from 'react';
import useSchemas from '../../../../hooks/useSchemas';
import {
  PreconditionInput,
  Schema,
  SegmentInput,
} from '../../../../types/segment.types';
import AttributeSelect from '../components/AttributeSelect';
import RelationOperatorSelect from '../components/RelationOperatorSelect';
import ValueInput from '../components/ValueInput/ValueInput';

const setNull = { target: { value: null } } as ChangeEvent<any>;

interface PreconditionFormProps {
  value: PreconditionInput;
  path: string;
  onAdd: () => void;
  onRemove: () => void;
  schemaId: string | null;
  addButtonVisible: boolean;
  errors?: FormikErrors<PreconditionInput> | string;
}

const PreconditionForm: React.FC<PreconditionFormProps> = ({
  value,
  path,
  onRemove,
  schemaId,
  errors,
}) => {
  const isSmallerThanMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const schemas = useSchemas();
  const { handleChange, setFieldError } = useFormikContext<SegmentInput>();

  const schema: Schema | null = schemas ? schemas[schemaId || ''] : null;

  const attributes = _.keyBy(
    _.map(schema?.preconditionAttributes, (attr) =>
      _.assign({}, attr, {
        category: schema?.title || '',
        label: `${attr.title}`,
      }),
    ),
    'id',
  );

  const formikErrors =
    errors && typeof errors !== 'string'
      ? (errors as FormikErrors<PreconditionInput>)
      : undefined;
  //const errorText = errors && typeof errors === 'string' ? errors : undefined;

  return schema?.preconditionAttributes ? (
    <Grid
      container
      direction={isSmallerThanMd ? 'column' : 'row'}
      spacing={1}
      sx={{
        borderLeftColor: 'divider',
        borderLeftStyle: 'dashed',
        ml: isSmallerThanMd ? 0 : 5,
        pl: 1,
        pt: 1.5,
      }}
    >
      {isSmallerThanMd ? null : (
        <Grid item xs={0.1} sx={{ p: 0 }}>
          <Divider
            sx={{
              height: 22,
              ml: -2,
              borderStyle: 'dashed',
            }}
          />
        </Grid>
      )}
      <Grid item xs={3}>
        <Stack direction="row" sx={{ ml: -1 }}>
          <IconButton onClick={onRemove} color="primary" size="small">
            <Remove fontSize="small" />
          </IconButton>
          <AttributeSelect
            attributes={attributes}
            inputLabel="Where"
            value={value.attributeId}
            error={formikErrors?.attributeId}
            onChange={(e: React.ChangeEvent<any>) => {
              handleChange(`${path}.attributeId`)(e);
              if (formikErrors?.attributeId) {
                setFieldError(`${path}.attributeId`, undefined);
              } else {
                setFieldError(`${path}`, undefined);
              }
              handleChange(`${path}.relation`)(setNull);
              handleChange(`${path}.values`)(setNull);
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="row">
          <RelationOperatorSelect
            onChange={(e: React.ChangeEvent<any>) => {
              const empty = { target: { value: null } } as ChangeEvent<any>;
              handleChange(`${path}.relation`)(e);
              if (formikErrors?.relation) {
                setFieldError(`${path}.relation`, undefined);
              } else {
                setFieldError(`${path}`, undefined);
              }
              handleChange(`${path}.values`)(empty);
            }}
            value={value.relation}
            values={
              attributes[value.attributeId || '']?.relationalOperators || []
            }
            error={formikErrors?.relation}
            onNegateChange={handleChange(`${path}.negate`)}
            negate={value.negate}
          />
        </Stack>
      </Grid>
      <Grid item xs={5}>
        <ValueInput
          attribute={attributes[value.attributeId || '']}
          onChange={handleChange(`${path}.values`)}
          value={value.values}
          relation={value.relation}
          error={formikErrors?.values}
        />
      </Grid>
    </Grid>
  ) : null;
};

export default PreconditionForm;
