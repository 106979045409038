import { FormControl, FormHelperText, TextField } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import {
  Attribute,
  RelationalOperator,
} from '../../../../../types/segment.types';
import BooleanInput from './components/BooleanInput';
import HiddenInput from './components/HiddenInput';
import MultipleInput from './components/MultipleInput';
import MultipleSelectInput from './components/MultipleSelectInput';
import RangeInput from './components/RangeInput';
import SelectInput from './components/SelectInput';
import SingleInput from './components/SingleInput';

type ValueInputVariant =
  | 'single'
  | 'multiple'
  | 'boolean'
  | 'select'
  | 'range'
  | 'hidden';

interface ValueInputProps {
  value: string[] | null;
  onChange: any;
  relation: RelationalOperator | null;
  attribute: Attribute;
  error?: string;
}

const ValueInput: React.FC<ValueInputProps> = (props) => {
  let variant: ValueInputVariant = 'single';

  switch (props.attribute?.dataType) {
    case 'BOOLEAN':
      variant = 'boolean';
      break;
    case 'STRING':
    case 'INTEGER':
    case 'DOUBLE':
    case 'MONEY':
    case 'DATE':
    case 'DATETIME':
      variant =
        props.relation === 'ANNIVERSARY' || props.relation === 'IS_NULL'
          ? 'hidden'
          : props.attribute.choices !== null
          ? 'select'
          : 'single';
      break;
    default:
      variant = 'single';
      break;
  }

  if (variant === 'single' && props.relation === 'ONE_OF') {
    variant = 'multiple';
  }

  if (variant === 'single' && props.relation === 'BETWEEN') {
    variant = 'range';
  }

  if (props.relation === null || !props.attribute) {
    return (
      <FormControl fullWidth error={props.error ? true : false}>
        <TextField size="small" disabled fullWidth sx={{ opacity: 0.4 }} />
        <FormHelperText>{props.error}</FormHelperText>
      </FormControl>
    );
  }

  switch (variant) {
    case 'hidden':
      return <HiddenInput {...props} />;
    case 'boolean':
      return <BooleanInput {...props} />;
    case 'multiple':
      return <MultipleInput {...props} dataType={props.attribute.dataType} />;
    case 'single':
      return <SingleInput {...props} dataType={props.attribute.dataType} />;
    case 'range':
      return <RangeInput {...props} dataType={props.attribute.dataType} />;
    case 'select':
      const options = _.keyBy(
        _.map(props.attribute?.choices, (o) => ({
          label: o.label,
          id: o.values.join(','),
          value: o.values,
        })),
        'id',
      );
      return props.relation === 'ONE_OF' ? (
        <MultipleSelectInput
          {...props}
          options={options}
          forceChoices={props.attribute.forceChoices}
        />
      ) : (
        <SelectInput {...props} options={options} />
      );
    default:
      break;
  }
  return null;
};

export default ValueInput;
