import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Archive,
  ArrowBackIos,
  Cancel,
  CheckCircle,
  Edit,
  Error,
  Event,
  Info,
  Pause,
  PlayArrow,
  Save,
  Schedule,
  Warning,
} from '@mui/icons-material';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { BarChart } from '@mui/x-charts/BarChart';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { EventCalendar } from 'react-mui-event-calendar';
import { useNavigate, useParams } from 'react-router-dom';
import CampaignExecutionDetails from '../../../components/Campaign/CampaignExecutionDetails';
import RadialChart from '../../../components/Chart/RadialChart';
import Navigation from '../../../components/Navigation/Navigation';
import { GET_CAMPAIGN_BY_ID } from '../../../graphql/local/queries/campaigns';
import useOperator from '../../../hooks/useOperator';

import {
  CampaignStateColorMapping,
  CampaignStateMapping,
  ExecutionSchedulingTypeMapping,
} from '../../../mappings';
import {
  CampaignDetailQueryResponse,
  CampaignState,
  Execution,
  ExecutionSchedulingType,
  ExecutionState,
} from '../../../types/campaign.types';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Img } from 'react-image';
import SegmentChip from '../../../components/Campaign/SegmentChip/SegmentChip';
import CreateCampaign from '../CreateCampaign';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function CampaignDetails() {
  const navigate = useNavigate();

  const params = useParams<{ campaignId: string; templateId: string }>();
  const backToCampaignsButtonClicked = () => {
    navigate('/campaigns');
  };

  const [isTitleEditing, setIsTitleEditing] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [templatePreviewUrl, setTemplatePreviewUrl] = React.useState('');

  const UPDATE_CAMPAIGN_TITLE = gql`
    mutation UpdateCampaignTitle($input: UpdateCampaignInput!) {
      updateCampaign(input: $input) {
        id
      }
    }
  `;

  const [updateCampaignTitle] = useMutation(UPDATE_CAMPAIGN_TITLE);

  const toggleEditTitle = () => {
    if (isTitleEditing) {
      updateCampaignTitle({
        variables: {
          input: {
            operatorId: operator?.id || 0,
            id: params.campaignId,
            title: title,
          },
        },
      });
      setIsTitleEditing(false);
      refetch();
    } else {
      setIsTitleEditing(true);
    }
  };

  const cancelEditTitle = () => {
    setTitle(campaign?.title || '');
    setIsTitleEditing(false);
  };

  const GET_CONNECTION_BY_ID = gql`
    query GetConnectionById($operatorId: ID!, $id: ID!) {
      operator(id: $operatorId) {
        connection(id: $id) {
          connectorKind
          connectorId
        }
      }
    }
  `;

  const GET_SENDER_PROFILE_BY_ID = gql`
    query GetSenderProfileById($operatorId: ID!, $id: ID!) {
      operator(id: $operatorId) {
        senderProfile(id: $id) {
          emailProfile {
            fromAddress
            fromName
          }
          smsProfile {
            sender
          }
        }
      }
    }
  `;

  const GET_LIBRARY_TEMPLATE_BY_ID = gql`
    query GetLibraryTemplateById($operatorId: ID!, $id: ID!) {
      operator(id: $operatorId) {
        libraryTemplate(id: $id) {
          senderProfile {
            emailProfile {
              fromAddress
              fromName
            }
            smsProfile {
              sender
            }
          }
        }
      }
    }
  `;

  const [isErrorWindowOpen, setIsErrorWindowOpen] = React.useState(false);

  const handleErrorOpen = () => {
    setIsErrorWindowOpen(true);
  };

  const handleErrorClose = () => {
    setIsErrorWindowOpen(false);
  };

  const [operator, { prevOperator }] = useOperator();

  useEffect(() => {
    if (operator!.id !== prevOperator!.id) {
      navigate('/campaigns');
    }
  }, [navigate, operator, prevOperator]);

  const { loading, error, data, refetch } =
    useQuery<CampaignDetailQueryResponse>(GET_CAMPAIGN_BY_ID, {
      variables: {
        operatorId: operator?.id || 0,
        id: params.campaignId,
      },
      pollInterval: 15000,
      onCompleted(data) {
        setTitle(data.operator.campaign.title);
        if (
          data.operator.campaign.templateUrls.previewUrl &&
          !templatePreviewUrl
        ) {
          setTemplatePreviewUrl(data.operator.campaign.templateUrls.previewUrl);
        }
      },
    });

  const STOP_CAMPAIGN = gql`
    mutation StopCampaign($input: StopCampaignInput!) {
      stopCampaign(input: $input) {
        id
      }
    }
  `;
  const [stopCampaign, stopLoading] = useMutation(STOP_CAMPAIGN, {
    notifyOnNetworkStatusChange: true,
  });

  const START_CAMPAIGN = gql`
    mutation StartCampaign($input: StartCampaignInput!) {
      startCampaign(input: $input) {
        id
      }
    }
  `;
  const [startCampaign, startLoading] = useMutation(START_CAMPAIGN, {
    notifyOnNetworkStatusChange: true,
  });

  const pauseCampaignButtonClicked = async () => {
    try {
      await stopCampaign({
        variables: {
          input: {
            operatorId: operator?.id || 0,
            id: params.campaignId,
            idT: params.templateId,
          },
        },
      });
    } catch (e) {
      handleErrorOpen();
    } finally {
      refetch();
    }
  };

  const resumeCampaignButtonClicked = async () => {
    try {
      await startCampaign({
        variables: {
          input: {
            operatorId: operator?.id || 0,
            id: params.campaignId,
          },
        },
      });
    } catch (e) {
      handleErrorOpen();
    } finally {
      refetch();
    }
  };

  const ARCHIVE_CAMPAIGN = gql`
    mutation ArchiveCampaign($input: ArchiveCampaignInput!) {
      archiveCampaign(input: $input) {
        id
      }
    }
  `;

  const [archiveCampaignMutation] = useMutation(ARCHIVE_CAMPAIGN);

  const archiveCampaignButtonClicked = async () => {
    try {
      await archiveCampaignMutation({
        variables: {
          input: {
            operatorId: operator?.id || 0,
            id: params.campaignId,
          },
        },
      });

      // Handle success, update UI, etc.
      console.log('Campaign Archived:', params.campaignId, operator?.id);
    } catch (error) {
      // Handle error, display error message, etc.
      console.error('Error archiving campaign:', error);
    } finally {
      refetch();
    }
    navigate('/campaigns');
  };

  const campaign = data?.operator.campaign;

  const nextExecutionDate =
    [...(data?.operator.campaign.executions?.items || [])]
      .sort((a: Execution, b: Execution) => {
        return dayjs(b.scheduleTime).isBefore(dayjs(a.scheduleTime)) ? 1 : -1;
      })
      .filter(
        (execution) =>
          execution.state === ExecutionState.SCHEDULED ||
          execution.state === ExecutionState.START_REQUESTED,
      )?.[0]?.scheduleTime || undefined;

  const { data: connectionData, loading: connectionLoading } = useQuery(
    GET_CONNECTION_BY_ID,
    {
      variables: {
        operatorId: operator?.id || 0,
        id: data?.operator.campaign.deliverySpec.connectionId,
      },
      skip: !data?.operator.campaign.deliverySpec.connectionId,
    },
  );

  const { data: libraryTemplateData, loading: libraryTemplateLoading } =
    useQuery(GET_LIBRARY_TEMPLATE_BY_ID, {
      variables: {
        operatorId: operator?.id || 0,
        id: data?.operator.campaign.templatingSpec.libraryTemplateId,
      },
      skip: !data?.operator.campaign.templatingSpec.libraryTemplateId,
    });

  const { data: senderProfileData, loading: senderProfileLoading } = useQuery(
    GET_SENDER_PROFILE_BY_ID,
    {
      variables: {
        operatorId: operator?.id || 0,
        id: data?.operator.campaign.templatingSpec.templateContent
          .senderProfileId,
      },
      skip: !data?.operator.campaign.templatingSpec.templateContent,
    },
  );

  const campaignCreateTime = dayjs(campaign?.createTime || '');
  const isEditable = campaign?.state !== CampaignState.ARCHIVED;
  const [isLoading, setIsLoading] = React.useState(true);
  const theme = useTheme();

  const ExecutionsLegendItems = [
    {
      title: 'Finished',
      icon: <CheckCircle fontSize="small" color="success" />,
    },
    {
      title: 'Scheduled',
      icon: <Schedule fontSize="small" color="secondary" />,
    },
    { title: 'In Progress', icon: <Info fontSize="small" color="info" /> },
    { title: 'Error', icon: <Error fontSize="small" color="error" /> },
    { title: 'Aborted', icon: <Warning fontSize="small" color="warning" /> },
  ];

  const executionsCalendarData = [
    ...(data?.operator.campaign.executions?.items || []),
  ]
    .sort((a: Execution, b: Execution) => {
      return dayjs(b.scheduleTime).isBefore(dayjs(a.scheduleTime)) ? 1 : -1;
    })
    .map((execution) => {
      const scheduleTime = dayjs(execution.scheduleTime);
      const isAborted =
        execution.state ===
          data?.operator.campaign.executions?.items?.[0]?.state &&
        execution.isAborted;

      return {
        id: execution.id,
        title: scheduleTime.format('MMMM DD, YYYY - HH:mm'),
        color: isAborted
          ? theme.palette.warning.main
          : execution.state === ExecutionState.SUCCEEDED
          ? theme.palette.success.main
          : execution.state === ExecutionState.FAILED
          ? theme.palette.error.main
          : execution.state === ExecutionState.SCHEDULED ||
            execution.state === ExecutionState.START_REQUESTED
          ? theme.palette.secondary.main
          : theme.palette.info.main,
        popupContent: (
          <CampaignExecutionDetails
            refreshHandler={refetch}
            execution={execution}
            operatorId={operator?.id || '0'}
            campaignId={campaign?.id || '0'}
          />
        ),

        date: scheduleTime.toDate(),
      };
    });

  React.useEffect(() => {
    setIsLoading(
      loading ||
        connectionLoading ||
        libraryTemplateLoading ||
        senderProfileLoading,
    );
  }, [
    loading,
    connectionLoading,
    libraryTemplateLoading,
    senderProfileLoading,
  ]);

  const [updateCampaignOpen, setUpdateCampaignOpen] = React.useState(false);

  const editCampaignButtonClicked = () => {
    setUpdateCampaignOpen(true);
  };

  const handleClose = () => {
    setUpdateCampaignOpen(false);
  };

  return (
    <Navigation>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Button
          onClick={backToCampaignsButtonClicked}
          startIcon={<ArrowBackIos />}
          sx={{ color: 'gray' }}
          disabled={startLoading.loading || stopLoading.loading}
        >
          <Typography
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'inline',
                xl: 'inline',
              },
            }}
          >
            BACK TO CAMPAIGNS
          </Typography>
        </Button>

        <Dialog fullWidth maxWidth="md" open={updateCampaignOpen}>
          <Box
            style={{
              maxHeight: '90%',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            <CreateCampaign
              closeHandler={handleClose}
              refreshHandler={refetch}
              campaign={campaign}
            />
          </Box>
        </Dialog>

        <Box>
          {campaign && campaign.state !== CampaignState.ARCHIVED && (
            <Button
              onClick={editCampaignButtonClicked}
              startIcon={<Edit />}
              sx={{ color: 'gray' }}
            >
              <Typography
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'inline',
                    xl: 'inline',
                  },
                }}
              >
                Edit
              </Typography>
            </Button>
          )}

          {!(
            campaign &&
            (campaign.state === CampaignState.ARCHIVED ||
              campaign.state === CampaignState.ARCHIVING)
          ) && (
            <>
              {campaign && campaign.state === CampaignState.ACTIVE_STOPPED ? (
                <>
                  <Button
                    onClick={resumeCampaignButtonClicked}
                    startIcon={<PlayArrow />}
                    sx={{ color: 'gray' }}
                    disabled={startLoading.loading || stopLoading.loading}
                  >
                    <Typography
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'none',
                          md: 'none',
                          lg: 'inline',
                          xl: 'inline',
                        },
                      }}
                    >
                      Resume
                    </Typography>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={pauseCampaignButtonClicked}
                    startIcon={<Pause />}
                    sx={{ color: 'gray' }}
                  >
                    <Typography
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'none',
                          md: 'none',
                          lg: 'inline',
                          xl: 'inline',
                        },
                      }}
                    >
                      Pause
                    </Typography>
                  </Button>
                </>
              )}
            </>
          )}

          {/* <Button
              onClick={backToCampaignsButtonClicked}
              startIcon={<ControlPointDuplicate />}
              sx={{ color: 'gray' }}
            >
              <Typography
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'inline',
                    xl: 'inline',
                  },
                }}
              >
                Clone
              </Typography>
            </Button> */}

          {!(
            campaign &&
            (campaign.state === CampaignState.ARCHIVED ||
              campaign.state === CampaignState.ARCHIVING)
          ) && (
            <Button
              onClick={archiveCampaignButtonClicked}
              startIcon={<Archive />}
              sx={{ color: 'gray' }}
            >
              <Typography
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'inline',
                    xl: 'inline',
                  },
                }}
              >
                Archive
              </Typography>
            </Button>
          )}
        </Box>
      </Box>

      {/* Navigation End */}

      <Dialog
        open={isErrorWindowOpen}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ERROR</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            An error occured. Please try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading ? <LinearProgress /> : null}
      {error ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </Alert>
      ) : null}
      {campaign && (
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontSize: '1rem', color: 'gray' }}
                    >
                      {
                        ExecutionSchedulingTypeMapping[
                          campaign.executionSpec.schedulingType
                        ]
                      }
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: {
                          xs: 'flex-start',
                          sm: 'flex-start',
                          md: 'flex-start',
                          lg: 'flex-end',
                          xl: 'flex-end',
                        },
                        alignItems: 'center',
                      }}
                    >
                      <Chip
                        label={CampaignStateMapping[campaign.state]}
                        color={CampaignStateColorMapping[campaign.state]}
                        sx={{ mr: 2 }}
                      />
                      <Chip label={`ID ${campaign.id}`} variant="outlined" />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      pr: 2,
                      pl: 2,
                      pb: 2,
                    }}
                  >
                    {isTitleEditing ? (
                      <TextField
                        id="campaignTitle"
                        label="Campaign Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }}
                      ></TextField>
                    ) : (
                      <Typography variant="h5">{campaign.title}</Typography>
                    )}
                    {isTitleEditing && (
                      <IconButton
                        onClick={cancelEditTitle}
                        size="large"
                        sx={{ color: 'black' }}
                      >
                        <Cancel />
                      </IconButton>
                    )}
                    {isEditable && (
                      <Tooltip
                        title={isTitleEditing ? 'Save' : 'Edit Campaign Title'}
                      >
                        <IconButton
                          onClick={toggleEditTitle}
                          size="large"
                          sx={{ color: 'black' }}
                        >
                          {isTitleEditing ? <Save /> : <Edit />}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      pr: 2,
                      pl: 2,
                      pb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Event />
                      <Typography
                        sx={{ display: 'inline', ml: 1, color: 'gray' }}
                      >
                        {campaignCreateTime.format('MMMM DD, YYYY - hh:mm A')}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ p: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h6"> Targeting </Typography>
                  </Box>

                  {campaign.targetingSpec.includedSegments &&
                    campaign.targetingSpec.includedSegments.length > 0 && (
                      <>
                        <Typography variant="caption">
                          Selected Segment(s)
                        </Typography>
                        <Grid container>
                          {campaign.targetingSpec.includedSegments.map(
                            (segment) => (
                              <Grid item xs={12} mb={1} key={segment.id}>
                                <SegmentChip segmentId={segment.id} />
                              </Grid>
                            ),
                          )}
                        </Grid>
                      </>
                    )}
                  {campaign.targetingSpec.excludedSegments &&
                    campaign.targetingSpec.excludedSegments.length > 0 && (
                      <>
                        <Typography variant="caption">
                          Excldued Segment(s)
                        </Typography>
                        <Grid container>
                          {campaign.targetingSpec.excludedSegments.map(
                            (segment) => (
                              <Grid item xs={12} mb={1} key={segment.id}>
                                <Chip
                                  key={segment.id}
                                  label={segment.title}
                                  size="small"
                                />
                              </Grid>
                            ),
                          )}
                        </Grid>
                      </>
                    )}

                  <Typography variant="h6" sx={{ pt: 1 }}>
                    Provider
                  </Typography>
                  <Typography variant="body1">
                    {connectionData?.operator.connection.connectorKind} -{' '}
                    {connectionData?.operator.connection.connectorId}
                  </Typography>
                  <Grid container sx={{ pt: 1 }}>
                    {/* From Address */}
                    <Grid item xs={12} md={6}>
                      <Typography variant="caption">
                        {connectionData?.operator.connection.connectorKind ===
                        'EMAIL'
                          ? 'From Address'
                          : 'Sender'}
                      </Typography>
                      <Typography variant="body1">
                        {senderProfileData?.operator.senderProfile?.emailProfile
                          ?.fromAddress ||
                          libraryTemplateData?.operator.libraryTemplate
                            .senderProfile?.emailProfile?.fromAddress ||
                          senderProfileData?.operator.senderProfile?.smsProfile
                            ?.sender ||
                          libraryTemplateData?.operator.libraryTemplate
                            .senderProfile?.smsProfile?.sender}
                      </Typography>
                    </Grid>

                    {/* From Name */}
                    <Grid item xs={12} md={6}>
                      <Typography variant="caption">
                        {connectionData?.operator.connection.connectorKind ===
                        'EMAIL'
                          ? 'From Name'
                          : ''}
                      </Typography>
                      <Typography variant="body1">
                        {senderProfileData?.operator.senderProfile?.emailProfile
                          ?.fromName ||
                          libraryTemplateData?.operator.libraryTemplate
                            .senderProfile?.emailProfile?.fromName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
                    {`Global campaign marketing limits will${
                      campaign.targetingSpec.ignoreMarketingLimits
                        ? ' NOT '
                        : ' '
                    }be applied.`}
                  </Alert>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ pt: 1 }}>
                      Campaign Progress
                    </Typography>

                    {campaign.progress ? (
                      <BarChart
                        colors={[
                          theme.palette.info.main,
                          theme.palette.success.main,
                          theme.palette.error.main,
                        ]}
                        xAxis={[
                          {
                            scaleType: 'band',
                            data: ['Targetting', 'Rendering', 'Delivery'],
                          },
                        ]}
                        series={[
                          {
                            data: [
                              campaign?.progress?.targetingExpectedCount,
                              campaign?.progress?.renderingExpectedCount,
                              campaign?.progress?.deliveryExpectedCount,
                            ],
                            stack: 'A',
                            label: 'Expected',
                          },
                          {
                            data: [
                              campaign?.progress?.targetingSucceededCount,
                              campaign?.progress?.renderingSucceededCount,
                              campaign?.progress?.deliverySucceededCount,
                            ],
                            stack: 'B',
                            label: 'Succeeded',
                          },
                          {
                            data: [
                              campaign?.progress?.targetingFailedCount,
                              campaign?.progress?.renderingFailedCount,
                              campaign?.progress?.deliveryFailedCount +
                                campaign?.progress?.deliveryRejectedCount,
                            ],
                            stack: 'C',
                            label: 'Failed',
                          },
                        ]}
                        sx={{ width: '100%' }}
                        height={300}
                      />
                    ) : (
                      <Typography variant="body1">
                        No execution progress data available
                      </Typography>
                    )}
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{ p: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h6"> Executions </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                      }}
                    >
                      {ExecutionsLegendItems.map((item, index) => (
                        <Tooltip key={index} title={item.title}>
                          <Chip
                            icon={
                              <Box
                                sx={{
                                  display: 'flex',
                                }}
                              >
                                {item.icon}
                              </Box>
                            }
                            label={
                              <Box
                                sx={{
                                  display: {
                                    xs: 'none',
                                    md: 'flex',
                                  },
                                }}
                              >
                                {item.title}
                              </Box>
                            }
                            size="small"
                            sx={{
                              mr: 0.5,
                              color: 'inherit',
                              borderColor: 'inherit',
                            }}
                          />
                        </Tooltip>
                      ))}
                    </Box>
                  </Box>

                  <Box sx={{ pt: 2 }}>
                    <EventCalendar
                      dataSource={executionsCalendarData || []}
                      width={'100%'}
                      readonly
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {(campaign.executionSpec.schedulingType ===
                  ExecutionSchedulingType.IMMEDIATE_EXECUTION ||
                  campaign.executionSpec.schedulingType ===
                    ExecutionSchedulingType.CUSTOM_SCHEDULED_EXECUTION) && (
                  <Card
                    sx={{
                      p: 2,
                    }}
                  >
                    <Typography variant="h6"> Schedule </Typography>
                    {campaign.executionSpec.schedulingType ===
                      ExecutionSchedulingType.IMMEDIATE_EXECUTION && (
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="caption">Start Date</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="caption">Time</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            {campaignCreateTime.format('MMMM DD, YYYY')}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            {campaignCreateTime.format('hh:mm A')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="caption">Timezone</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            {operator?.timezone || 'UTC'}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {campaign.executionSpec.schedulingType ===
                      ExecutionSchedulingType.CUSTOM_SCHEDULED_EXECUTION &&
                      campaign.executionSpec.customScheduling.startTimes.map(
                        (startTime) => (
                          <Grid container key={startTime}>
                            <Grid item xs={6} key={startTime}>
                              <Typography variant="caption">
                                Start Date
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="caption">Time</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {dayjs(startTime).format('MMMM DD, YYYY')}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {dayjs(startTime).format('hh:mm A')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="caption">
                                Timezone
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                {operator?.timezone || 'UTC'}
                              </Typography>
                            </Grid>
                          </Grid>
                        ),
                      )}

                    <Box
                      sx={{
                        pt: 2,
                      }}
                    >
                      {nextExecutionDate && (
                        <Alert variant="outlined" severity="info">
                          {`This campaign's next execution will be at ${dayjs(
                            nextExecutionDate,
                          )
                            .tz(dayjs.tz.guess())
                            .format(
                              'DD/MM/YYYY HH:mm',
                            )} according to your current location.`}
                        </Alert>
                      )}
                    </Box>
                  </Card>
                )}
                {campaign.executionSpec.schedulingType ===
                  ExecutionSchedulingType.RECURRING_EXECUTION && (
                  <Card
                    sx={{
                      p: 2,
                    }}
                  >
                    <Typography variant="h6"> Schedule </Typography>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="caption">Time Period</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Every{' '}
                          {campaign.executionSpec.recurringScheduling.period
                            .daily && 'Day'}
                          {campaign.executionSpec.recurringScheduling.period
                            .weekly && 'Week'}
                          {campaign.executionSpec.recurringScheduling.period
                            .monthly && 'Month'}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="caption">Day</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="caption">Hour</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="caption">Minute</Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body1">
                          {campaign.executionSpec.recurringScheduling.period
                            .weekly != null
                            ? campaign.executionSpec.recurringScheduling.period
                                .weekly.day.value
                            : campaign.executionSpec.recurringScheduling.period
                                .monthly != null
                            ? campaign.executionSpec.recurringScheduling.period
                                .monthly.day.value
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1">
                          {campaign.executionSpec.recurringScheduling.period
                            .daily != null
                            ? dayjs
                                .tz(
                                  `2023-01-01 ${campaign.executionSpec.recurringScheduling.period.daily.hour.value}:00`,
                                  'UTC',
                                )
                                .tz(operator?.timezone)
                                .format('HH')
                            : campaign.executionSpec.recurringScheduling.period
                                .weekly != null
                            ? dayjs
                                .tz(
                                  `2023-01-01 ${campaign.executionSpec.recurringScheduling.period.weekly.day.hour.value}:00`,
                                  'UTC',
                                )
                                .tz(operator?.timezone)
                                .format('HH')
                            : campaign.executionSpec.recurringScheduling.period
                                .monthly != null
                            ? dayjs
                                .tz(
                                  `2023-01-01 ${campaign.executionSpec.recurringScheduling.period.monthly.day.hour.value}:00`,
                                  'UTC',
                                )
                                .tz(operator?.timezone)
                                .format('HH')
                            : '-'}{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1">
                          {campaign.executionSpec.recurringScheduling.period
                            .daily != null
                            ? dayjs
                                .tz(
                                  `2023-01-01 00:${campaign.executionSpec.recurringScheduling.period.daily.hour.minute.value}`,
                                  'UTC',
                                )
                                .tz(operator?.timezone)
                                .format('mm')
                            : campaign.executionSpec.recurringScheduling.period
                                .weekly != null
                            ? dayjs
                                .tz(
                                  `2023-01-01 00:${campaign.executionSpec.recurringScheduling.period.weekly.day.hour.minute.value}`,
                                  'UTC',
                                )
                                .tz(operator?.timezone)
                                .format('mm')
                            : campaign.executionSpec.recurringScheduling.period
                                .monthly != null
                            ? dayjs
                                .tz(
                                  `2023-01-01 00:${campaign.executionSpec.recurringScheduling.period.monthly.day.hour.minute.value}`,
                                  'UTC',
                                )
                                .tz(operator?.timezone)
                                .format('mm')
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Typography variant="caption">Start Date</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <Typography variant="caption">Time</Typography>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Typography variant="body1">
                          {dayjs(
                            campaign.executionSpec.recurringScheduling
                              .startTime || campaign.createTime,
                          ).format('MMMM DD, YYYY')}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <Typography variant="body1">
                          {dayjs(
                            campaign.executionSpec.recurringScheduling
                              .startTime || campaign.createTime,
                          ).format('hh:mm A')}
                        </Typography>
                      </Grid>
                      {campaign.executionSpec.recurringScheduling.endTime !=
                      null ? (
                        <>
                          <Grid item xs={6} md={4}>
                            <Typography variant="caption">End Date</Typography>
                          </Grid>
                          <Grid item xs={6} md={8}>
                            <Typography variant="caption">Time</Typography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Typography variant="body1">
                              {dayjs(
                                campaign.executionSpec.recurringScheduling
                                  .endTime,
                              ).format('MMMM DD, YYYY')}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              {dayjs(
                                campaign.executionSpec.recurringScheduling
                                  .endTime,
                              ).format('hh:mm A')}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="caption">End Date</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1">Never</Typography>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <Typography variant="caption">Timezone</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {operator?.timezone || 'UTC'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        pt: 2,
                      }}
                    >
                      {nextExecutionDate && (
                        <Alert variant="outlined" severity="info">
                          {`This campaign's next execution will be at ${dayjs(
                            nextExecutionDate,
                          )
                            .tz(dayjs.tz.guess())
                            .format(
                              'DD/MM/YYYY HH:mm',
                            )} according to your current location.`}
                        </Alert>
                      )}
                    </Box>
                  </Card>
                )}
              </Grid>
              <Grid item xs={12}>
                <Card
                  sx={{
                    p: 2,
                  }}
                >
                  <Typography variant="h6"> Activity </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <RadialChart
                      labels={[
                        'Filtered',
                        'Targeted',
                        'Delivered',
                        'Views',
                        'Clicked',
                      ]}
                      series={[
                        campaign.stats.filteredCount,
                        campaign.stats.targetedCount,
                        campaign.stats.deliveredCount,
                        campaign.stats.viewCount,
                        campaign.stats.clickCount,
                      ]}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        xs: '100%',
                      },
                      alignItems: 'center',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h6" sx={{ pt: 2 }}>
                        Template
                      </Typography>
                    </Box>
                    {!templatePreviewUrl ? (
                      <CircularProgress sx={{ p: 2, m: 2 }} size={100} />
                    ) : (
                      <Img
                        src={templatePreviewUrl}
                        style={{ width: '90%', paddingBottom: '25px' }}
                      />
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Navigation>
  );
}
