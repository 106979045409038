import { FormControl, FormHelperText, Stack, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import _ from 'lodash';
import { ChangeEvent } from 'react';
import { AttributeDataType } from '../../../../../../types/segment.types';

interface RangeInputProps {
  value: string[] | null;
  onChange: any;
  disabled?: boolean;
  dataType: AttributeDataType;
  error?: string;
}

const RangeInput: React.FC<RangeInputProps> = ({
  onChange,
  value,
  disabled,
  dataType,
  error,
}) => {
  const handleChange = (index: 0 | 1) => (e: ChangeEvent<HTMLInputElement>) => {
    const val =
      index === 0 ? [e.target.value, value?.[1]] : [value?.[0], e.target.value];
    onChange({
      target: {
        value: e.target.value !== null ? val : null,
      },
    });
  };

  return (
    <FormControl fullWidth error={error ? true : false}>
      {dataType === 'DATE' || dataType === 'DATETIME' ? (
        <Stack direction="row" spacing={1}>
          <DateTimePicker
            timeSteps={{ minutes: 1 }}
            value={value && value.length === 2 ? value[0] : null}
            onChange={(newValue) => {
              onChange({
                target: {
                  value: newValue !== null ? [newValue, value?.[1]] : null,
                },
              });
            }}
            slotProps={{
              textField: {
                size: 'small',
                variant: 'standard',
                fullWidth: true,
                sx: { m: 0, mt: 1, px: 1.5 },
                InputProps: { disableUnderline: true },
              },
            }}
          />
          <DateTimePicker
            timeSteps={{ minutes: 1 }}
            value={value && value.length === 2 ? value[1] : null}
            onChange={(newValue) => {
              onChange({
                target: {
                  value: newValue !== null ? [value?.[0], newValue] : null,
                },
              });
            }}
            slotProps={{
              textField: {
                size: 'small',
                variant: 'standard',
                fullWidth: true,
                sx: { m: 0, mt: 1, px: 1.5 },
                InputProps: { disableUnderline: true },
              },
            }}
          />
        </Stack>
      ) : (
        <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            size="small"
            placeholder="Enter value"
            variant="outlined"
            onChange={handleChange(0)}
            disabled={disabled}
            value={value && value.length === 2 ? value[0] : null}
            type={
              _.includes(['INTEGER', 'DOUBLE', 'MONEY'], dataType)
                ? 'number'
                : 'text'
            }
          />{' '}
          <TextField
            fullWidth
            size="small"
            placeholder="Enter value"
            variant="outlined"
            onChange={handleChange(1)}
            disabled={disabled}
            value={value && value.length === 2 ? value[1] : null}
            type={
              _.includes(['INTEGER', 'DOUBLE', 'MONEY'], dataType)
                ? 'number'
                : 'text'
            }
          />
        </Stack>
      )}
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default RangeInput;
