import { Add, Remove } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FormikErrors, FormikValues, useFormikContext } from 'formik';
import React from 'react';

interface CampaignScheduleCustomProps {
  errors: FormikErrors<{
    startTimes: string;
  }>;
  values: FormikValues;
  handleChange: any;
}

const CampaignScheduleCustom: React.FC<CampaignScheduleCustomProps> = ({
  errors,
  values,
  handleChange,
}) => {
  const { setFieldValue } = useFormikContext();
  const addStartTime = (index: number) => (): void => {
    if (values.startTimes[index] === null) {
      return;
    }
    setFieldValue('startTimes', [...values.startTimes, null]);
  };

  const removeStartTime = (index: number) => (): void => {
    setFieldValue(
      'startTimes',
      values.startTimes.filter((_: any, i: number) => i !== index),
    );
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        py: {
          md: 5,
          xs: 2,
        },
      }}
    >
      {values.startTimes.map((startTime: any, index: number) => (
        <Grid item xs={12} key={index}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <DateTimePicker
              disabled={
                values.schedulingTypeDisabled &&
                dayjs(values.startTimes[index]).isBefore(dayjs())
              }
              value={
                startTime === null ? null : dayjs(values.startTimes[index])
              }
              ampm={false}
              minDateTime={dayjs().add(4, 'minute')}
              onChange={(value): void => {
                setFieldValue(`startTimes.${index}`, value);
              }}
              label={`Start Time ${index + 1}`}
              sx={{
                width: '100%',
                mr: 2,
              }}
            />
            {(index > 0 || values.startTimes.length > 0) && (
              <IconButton
                onClick={removeStartTime(index)}
                color="primary"
                size="large"
                disabled={
                  values.schedulingTypeDisabled &&
                  dayjs(values.startTimes[index]).isBefore(dayjs())
                }
              >
                <Remove />
              </IconButton>
            )}
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} mt={1}>
        <Box
          sx={{
            mt: -1,
            ml: 1,
          }}
        >
          <Button
            size="small"
            onClick={addStartTime(values.startTimes.length)}
            startIcon={<Add />}
          >
            Add Schedule
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              defaultChecked={values.ignoreMarketingLimit}
              onChange={handleChange('ignoreMarketingLimit')}
            />
          }
          label={
            <Typography variant="body2">
              Ignore Global Marketing Frequency Limit
            </Typography>
          }
        />
      </Grid>
    </Grid>
  );
};

export default CampaignScheduleCustom;
