import { useQuery, gql } from '@apollo/client';
import _ from 'lodash';
import { createContext } from 'react';
import useOperator from '../../hooks/useOperator';
import { Schema } from '../../types/segment.types';

type SchemasProviderProps = { children: React.ReactNode };

const SchemasContext = createContext<{ [id: string]: Schema } | undefined>(
  undefined,
);

interface ListSchemasQueryVariables {
  operatorId: string;
}

type ListSchemasQueryResponse = {
  operator: {
    schemas: Schema[];
  };
};

const LIST_SCHEMAS = gql`
  query ListSchemas($operatorId: ID!) {
    operator(id: $operatorId) {
      schemas {
        id
        description
        title
        attributes {
          dataType
          description
          forceChoices
          id
          relationalOperators
          schemaId
          title
          choices {
            label
            values
          }
        }
        preconditionAttributes {
          choices {
            label
            values
          }
          dataType
          description
          forceChoices
          id
          relationalOperators
          schemaId
          title
        }
      }
    }
  }
`;

function SchemasProvider({ children }: SchemasProviderProps) {
  const [operator] = useOperator();

  const { data } = useQuery<
    ListSchemasQueryResponse,
    ListSchemasQueryVariables
  >(LIST_SCHEMAS, {
    variables: { operatorId: operator!.id },
  });

  const schemas = data?.operator.schemas;
  const value = _.keyBy(schemas, 'id') as { [id: string]: Schema };

  return (
    <SchemasContext.Provider value={value}>{children}</SchemasContext.Provider>
  );
}

export { SchemasContext, SchemasProvider };
