import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import { FormikWizard } from 'formik-wizard-form';
import React from 'react';
import * as Yup from 'yup';
import CampaignSchedule from '../../../components/Campaign/CreateCampaignForm/CampaignSchedule';
import CampaignTypeAndTitle from '../../../components/Campaign/CreateCampaignForm/CampaignTypeAndTitle';
import CreateCampaignPreview from '../../../components/Campaign/CreateCampaignForm/CreateCampaignPreview';
import SelectConnection from '../../../components/Campaign/CreateCampaignForm/SelectConnection';
import SelectSegments from '../../../components/Campaign/CreateCampaignForm/SelectSegments';
import SelectTemplate from '../../../components/Campaign/CreateCampaignForm/SelectTemplate';
import useOperator from '../../../hooks/useOperator';
import {
  Campaign,
  CreateCampaignInput,
  ExecutionSchedulingType,
  PeriodType,
  UpdateCampaignInput,
} from '../../../types/campaign.types';

interface CreateCampaignProps {
  campaign?: Campaign;
  closeHandler: () => void;
  refreshHandler: () => void;
}

const CreateCampaign: React.FC<CreateCampaignProps> = ({
  campaign,
  closeHandler,
  refreshHandler,
}) => {
  const isSmallerThanMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const stepLabels = [
    'Campaign Type',
    'Schedule',
    'Targets',
    'Template',
    'Connection',
    'Overview',
  ];
  const templateValidationSchema = Yup.object()
    .shape({
      templateId: Yup.string(),
      templateUploadId: Yup.string(),
      senderProfileId: Yup.string(),
      templatePreviewUrl: Yup.string(),
    })
    .test('at-least-one-template', '', function (values) {
      const {
        templateId,
        templateUploadId,
        senderProfileId,
        templatePreviewUrl,
      } = values;
      if (templatePreviewUrl) {
        return true;
      }
      if (!templateId && !templateUploadId) {
        return this.createError({
          path: 'templateId',
          message:
            'At least one of Template ID or Template Upload ID is required',
        });
      }
      if (templateUploadId && !senderProfileId) {
        return this.createError({
          path: 'senderProfileId',
          message: 'Sender Profile ID is required',
        });
      }
      return true;
    });

  const schedulingValidationSchema = Yup.object()
    .shape({
      startTimes: Yup.array(),
      schedulingType: Yup.string(),
      timePeriod: Yup.string(),
      day: Yup.number(),
      time: Yup.date().notRequired(),
      startTime: Yup.date().notRequired(),
      endTime: Yup.date().notRequired(),
      startImmediately: Yup.boolean(),
      neverStop: Yup.boolean(),
    })
    .test('custom-scheduling', '', function (values) {
      const {
        timePeriod,
        day,
        time,
        startTime,
        startTimes,
        endTime,
        schedulingType,
        neverStop,
      } = values;
      if (
        schedulingType === ExecutionSchedulingType.CUSTOM_SCHEDULED_EXECUTION
      ) {
        if (startTimes?.length === 0) {
          return this.createError({
            path: 'startTimes',
            message: 'At least one start time required.',
          });
        }
        if (startTimes?.some((element) => !dayjs(element).isValid())) {
          return this.createError({
            path: 'startTimes',
            message: 'Start time can not be empty.',
          });
        }
      } else if (
        schedulingType === ExecutionSchedulingType.RECURRING_EXECUTION
      ) {
        if (timePeriod === '' || !timePeriod) {
          return this.createError({
            path: 'timePeriod',
            message: 'Time period is required',
          });
        }
        if (!time) {
          return this.createError({
            path: 'time',
            message: 'Time is required',
          });
        }

        if (
          timePeriod === PeriodType.WEEKLY ||
          timePeriod === PeriodType.MONTHLY
        ) {
          if (!day) {
            return this.createError({
              path: 'day',
              message: 'Day is required',
            });
          }
        }

        if (!startTime) {
          return this.createError({
            path: 'startTime',
            message: 'Start time is required',
          });
        }

        if (neverStop === false && !endTime) {
          return this.createError({
            path: 'endTime',
            message: 'End time is required',
          });
        }

        if (startTime && endTime && dayjs(startTime).isAfter(dayjs(endTime))) {
          return this.createError({
            path: 'endTime',
            message: 'End time should be after start time',
          });
        }
      }
      return true;
    });
  const [finished, setFinished] = React.useState(false);
  const [creating, setCreating] = React.useState(false);
  const [, setFinalValues] = React.useState({});
  const [operator] = useOperator();
  const [isErrorWindowOpen, setIsErrorWindowOpen] = React.useState(false);

  const handleErrorOpen = () => {
    setIsErrorWindowOpen(true);
  };

  const handleErrorClose = () => {
    setIsErrorWindowOpen(false);
  };

  const initialValues = {
    isUpdating: campaign ? true : false,
    campaignId: campaign ? campaign.id : '',
    schedulingType: campaign ? campaign.executionSpec.schedulingType : '',
    schedulingTypeDisabled: campaign ? true : false,
    campaignType: campaign ? campaign.messageType : '',
    campaignTypeDisabled: campaign ? true : false,
    title: campaign ? campaign.title : '',
    includedSegments: campaign
      ? campaign.targetingSpec.includedSegments.map((segment) => {
          return segment.id;
        })
      : [],
    segments: [],
    excludedSegments: campaign
      ? campaign.targetingSpec.excludedSegments
        ? campaign.targetingSpec.excludedSegments.map((segment) => {
            return segment.id;
          })
        : []
      : [],
    startTimes: campaign
      ? campaign.executionSpec.schedulingType ===
        ExecutionSchedulingType.CUSTOM_SCHEDULED_EXECUTION
        ? campaign.executionSpec.customScheduling.startTimes
        : []
      : [],
    provider: '',
    timePeriod: campaign
      ? campaign.executionSpec.recurringScheduling?.period.daily
        ? PeriodType.DAILY
        : campaign.executionSpec.recurringScheduling?.period.weekly
        ? PeriodType.WEEKLY
        : campaign.executionSpec.recurringScheduling?.period.monthly
        ? PeriodType.MONTHLY
        : ''
      : '',
    day:
      campaign?.executionSpec.recurringScheduling?.period.weekly?.day?.value ||
      campaign?.executionSpec.recurringScheduling?.period.monthly?.day?.value ||
      '',
    time: campaign
      ? dayjs
          .tz(
            `2023-01-01 ${
              campaign.executionSpec.recurringScheduling?.period.daily?.hour
                ?.value ||
              campaign.executionSpec.recurringScheduling?.period.weekly?.day
                ?.hour?.value ||
              campaign.executionSpec.recurringScheduling?.period.monthly?.day
                ?.hour?.value ||
              0
            }:${
              campaign.executionSpec.recurringScheduling?.period.daily?.hour
                ?.minute?.value ||
              campaign.executionSpec.recurringScheduling?.period.weekly?.day
                ?.hour?.minute?.value ||
              campaign.executionSpec.recurringScheduling?.period.monthly?.day
                ?.hour?.minute?.value ||
              0
            }`,
            'UTC',
          )
          .tz(operator?.timezone)
      : null,
    startTime: campaign
      ? campaign.executionSpec.recurringScheduling?.startTime
        ? dayjs(campaign.executionSpec.recurringScheduling?.startTime)
        : null
      : null,
    endTime: campaign
      ? campaign.executionSpec.recurringScheduling?.endTime
        ? dayjs(campaign.executionSpec.recurringScheduling?.endTime)
        : null
      : null,
    templateName: '',
    templatePreviewUrl: campaign ? campaign.templateUrls.previewUrl : '',
    templateId: '',
    templateUploadId: '',
    templateUploadFileName: '',
    connectionId: campaign ? campaign.deliverySpec.connectionId : '',
    connectionName: '',
    startImmediately: campaign
      ? campaign.executionSpec.schedulingType ===
        ExecutionSchedulingType.IMMEDIATE_EXECUTION
      : false,
    neverStop:
      campaign?.executionSpec.recurringScheduling?.endTime === null
        ? true
        : false,
    ignoreMarketingLimit: campaign
      ? campaign.targetingSpec.ignoreMarketingLimits
      : false,
    subject: '',
    senderProfileId: '',
  };

  const CREATE_CAMPAIGN = gql`
    mutation CreateCampaign($input: CreateCampaignInput!) {
      createCampaign(input: $input) {
        id
      }
    }
  `;

  const [createCampaign] = useMutation(CREATE_CAMPAIGN);

  const UPDATE_CAMPAIGN = gql`
    mutation UpdateCampaign($input: UpdateCampaignInput!) {
      updateCampaign(input: $input) {
        id
      }
    }
  `;

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN);

  const updateCampaignButtonClicked = async (values: any) => {
    setCreating(true);
    var updateCampaignInput: UpdateCampaignInput = {
      id: campaign?.id || '0',
      operatorId: operator?.id || '0',
      title: values.title,
      deliverySpec: {
        connectionId: values.connectionId,
      },
      executionSpec: {
        schedulingType: values.schedulingType as ExecutionSchedulingType,
        recurringScheduling:
          values.schedulingType === ExecutionSchedulingType.RECURRING_EXECUTION
            ? {
                startTime:
                  dayjs(values.startTime).tz('UTC').toISOString() || null,
                endTime: values.endTime
                  ? dayjs(values.endTime).tz('UTC').toISOString()
                  : null,
                period: {
                  daily:
                    values.timePeriod === PeriodType.DAILY
                      ? {
                          hour: {
                            minute: {
                              value: parseInt(
                                dayjs(values.time).tz('UTC').format('m'),
                              ),
                            },
                            value: parseInt(
                              dayjs(values.time).tz('UTC').format('H'),
                            ),
                          },
                        }
                      : null,
                  monthly:
                    values.timePeriod === PeriodType.MONTHLY
                      ? {
                          day: {
                            hour: {
                              minute: {
                                value: parseInt(
                                  dayjs(values.time).tz('UTC').format('m'),
                                ),
                              },
                              value: parseInt(
                                dayjs(values.time).tz('UTC').format('H'),
                              ),
                            },
                            value: values.day,
                          },
                        }
                      : null,
                  weekly:
                    values.timePeriod === PeriodType.WEEKLY
                      ? {
                          day: {
                            hour: {
                              minute: {
                                value: parseInt(
                                  dayjs(values.time).tz('UTC').format('m'),
                                ),
                              },
                              value: parseInt(
                                dayjs(values.time).tz('UTC').format('H'),
                              ),
                            },
                            value: values.day,
                          },
                        }
                      : null,
                },
              }
            : null,
        customScheduling:
          values.schedulingType ===
          ExecutionSchedulingType.CUSTOM_SCHEDULED_EXECUTION
            ? {
                startTimes: values.startTimes,
              }
            : null,
      },
      targetingSpec: {
        includedSegments: values.includedSegments,
        excludedSegments:
          values.excludedSegments.length > 0 ? values.excludedSegments : null,
        ignoreMarketingLimits: values.ignoreMarketingLimit,
      },

      trackingSpec: {
        trackingDomainId: '1',
      },
    };
    if (values.templatePreviewUrl === '') {
      updateCampaignInput.templatingSpec = {
        libraryTemplateId: values.templateId || null,
        templateContent:
          values.templateId === ''
            ? {
                senderProfileId: values.senderProfileId,
                uploadId: values.templateUploadId,
              }
            : null,
      };
    }

    try {
      await updateCampaign({
        variables: {
          input: updateCampaignInput,
        },
      });

      closeHandler();
      refreshHandler();
      setCreating(false);
    } catch (error: any) {
      setCreating(false);
      handleErrorOpen();
      console.error('Error updating campaign:', error);
    }
  };

  const createCampaignButtonClicked = async (values: any) => {
    setCreating(true);
    const createCampaignInput: CreateCampaignInput = {
      operatorId: operator?.id || '0',
      title: values.title,
      deliverySpec: {
        connectionId: values.connectionId,
      },
      executionSpec: {
        schedulingType: values.schedulingType as ExecutionSchedulingType,
        recurringScheduling:
          values.schedulingType === ExecutionSchedulingType.RECURRING_EXECUTION
            ? {
                startTime:
                  dayjs(values.startTime).tz('UTC').toISOString() || null,
                endTime: values.endTime
                  ? dayjs(values.endTime).tz('UTC').toISOString()
                  : null,
                period: {
                  daily:
                    values.timePeriod === PeriodType.DAILY
                      ? {
                          hour: {
                            minute: {
                              value: parseInt(
                                dayjs(values.time).tz('UTC').format('m'),
                              ),
                            },
                            value: parseInt(
                              dayjs(values.time).tz('UTC').format('H'),
                            ),
                          },
                        }
                      : null,
                  monthly:
                    values.timePeriod === PeriodType.MONTHLY
                      ? {
                          day: {
                            hour: {
                              minute: {
                                value: parseInt(
                                  dayjs(values.time).tz('UTC').format('m'),
                                ),
                              },
                              value: parseInt(
                                dayjs(values.time).tz('UTC').format('H'),
                              ),
                            },
                            value: values.day,
                          },
                        }
                      : null,
                  weekly:
                    values.timePeriod === PeriodType.WEEKLY
                      ? {
                          day: {
                            hour: {
                              minute: {
                                value: parseInt(
                                  dayjs(values.time).tz('UTC').format('m'),
                                ),
                              },
                              value: parseInt(
                                dayjs(values.time).tz('UTC').format('H'),
                              ),
                            },
                            value: values.day,
                          },
                        }
                      : null,
                },
              }
            : null,
        customScheduling:
          values.schedulingType ===
          ExecutionSchedulingType.CUSTOM_SCHEDULED_EXECUTION
            ? {
                startTimes: values.startTimes,
              }
            : null,
      },
      messageType: values.campaignType,
      targetingSpec: {
        includedSegments: values.includedSegments,
        excludedSegments:
          values.excludedSegments.length > 0 ? values.excludedSegments : null,
        ignoreMarketingLimits: values.ignoreMarketingLimit,
      },
      templatingSpec: {
        libraryTemplateId: values.templateId || null,
        templateContent:
          values.templateId === ''
            ? {
                senderProfileId: values.senderProfileId,
                uploadId: values.templateUploadId,
              }
            : null,
      },
      trackingSpec: {
        trackingDomainId: '1',
      },
    };

    try {
      await createCampaign({
        variables: {
          input: createCampaignInput,
        },
      });

      closeHandler();
      refreshHandler();
      setCreating(false);
    } catch (error: any) {
      setCreating(false);
      handleErrorOpen();
      console.error('Error creating campaign:', error);
    }
  };

  return (
    <Card>
      <Box p={2}>
        <Typography variant="h6">
          {campaign ? 'Update Campaign' : 'Create Campaign'}
        </Typography>
        <Dialog
          open={isErrorWindowOpen}
          onClose={handleErrorClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">ERROR</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Campaign couldn't be created. Please try again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleErrorClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Box mt={2} sx={{}}>
          <FormikWizard
            initialValues={initialValues}
            onSubmit={(values) => {
              setFinalValues(values);
              setFinished(true);

              campaign
                ? updateCampaignButtonClicked(values)
                : createCampaignButtonClicked(values);
            }}
            validateOnNext
            activeStepIndex={0}
            steps={[
              {
                component: CampaignTypeAndTitle,

                validationSchema: Yup.object().shape({
                  campaignType: Yup.string().required(
                    'Campaign type is required',
                  ),
                  schedulingType: Yup.string().required('Type is required'),
                  title: Yup.string().required('Title is required'),
                }),
              },
              {
                component: CampaignSchedule,
                validationSchema: schedulingValidationSchema,
              },
              {
                component: SelectSegments,
                validationSchema: Yup.object().shape({
                  includedSegments: Yup.array().min(
                    1,
                    'At least one segment should be selected',
                  ),
                  excludedSegments: Yup.array(),
                }),
              },
              {
                component: SelectTemplate,
                validationSchema: templateValidationSchema,
              },
              {
                component: SelectConnection,
                validationSchema: Yup.object().shape({
                  connectionId: Yup.string().required(
                    'A connection should be selected',
                  ),
                }),
              },

              {
                component: CreateCampaignPreview,
              },
            ]}
          >
            {({
              currentStepIndex = 0,
              renderComponent,
              handlePrev,
              handleNext,
              isNextDisabled,
              isPrevDisabled,
            }) => {
              return (
                <>
                  <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={currentStepIndex}>
                      <Step completed={currentStepIndex > 0}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[0]}
                        </StepLabel>
                      </Step>
                      <Step completed={currentStepIndex > 1}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[1]}
                        </StepLabel>
                      </Step>
                      <Step completed={currentStepIndex > 2}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[2]}
                        </StepLabel>
                      </Step>
                      <Step completed={currentStepIndex > 3}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[3]}
                        </StepLabel>
                      </Step>
                      <Step completed={currentStepIndex > 4}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[4]}
                        </StepLabel>
                      </Step>

                      <Step completed={finished}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[5]}
                        </StepLabel>
                      </Step>
                    </Stepper>
                  </Box>
                  <Box mt={2} display={{ md: 'none' }}>
                    <Typography variant="button">
                      {stepLabels[currentStepIndex]}
                    </Typography>
                  </Box>
                  <Box>{renderComponent()}</Box>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                      <Button variant="contained" onClick={closeHandler}>
                        Cancel
                      </Button>
                    </Box>
                    <Box display="flex">
                      <Button
                        sx={{ mr: '1rem' }}
                        variant="contained"
                        disabled={isPrevDisabled || creating}
                        onClick={handlePrev}
                      >
                        Previous
                      </Button>

                      <LoadingButton
                        loading={creating}
                        variant="contained"
                        disabled={isNextDisabled}
                        onClick={handleNext}
                      >
                        {currentStepIndex === 4
                          ? 'Overview'
                          : currentStepIndex === 5
                          ? creating
                            ? campaign
                              ? 'Updating'
                              : 'Creating...'
                            : campaign
                            ? 'Update'
                            : 'Create'
                          : 'Next'}
                      </LoadingButton>
                    </Box>
                  </Box>
                </>
              );
            }}
          </FormikWizard>
        </Box>
      </Box>
    </Card>
  );
};

export default CreateCampaign;
