import { memo } from 'react';
import { Handle, Position } from 'reactflow';

export interface GroupDataProps {
  isFirst?: boolean;
  isLast?: boolean;
}

interface GroupNodeProps {
  data: GroupDataProps;
  isConnectable: boolean;
  type: string;
}

const GroupNode = memo(({ data }: GroupNodeProps) => {
  return (
    <>
      {!data.isFirst && (
        <Handle
          type="target"
          position={Position.Top}
          id="topTarget"
          style={{ background: '#555', marginLeft: 30 }}
          isConnectable={false}
        />
      )}
      {!data.isLast && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="bottomSource"
          style={{ background: '#555', marginLeft: -30 }}
          isConnectable={false}
        />
      )}
    </>
  );
});

export default GroupNode;
