import { gql, useLazyQuery } from '@apollo/client';
import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useState } from 'react';

interface DownloadAllCustomersProps
  extends React.PropsWithChildren<{
    variables: AllCustomersCSVQueryVariables;
    onClick: () => void;
    startIcon: React.ReactNode;
    disabled?: boolean;
  }> {}
interface AllCustomersCSVQueryVariables {
  operatorId: string;
}

type AllCustomersCSVQueryResponse = {
  operator: {
    customersS3RefUrl: string;
  };
};

export const GET_ALL_CUSTOMERS_S3REFURL = gql`
  query GetAllCustomersS3RefUrl($operatorId: ID!) {
    operator(id: $operatorId) {
      customersS3RefUrl
    }
  }
`;

const DownloadAllCustomers: React.FC<DownloadAllCustomersProps> = ({
  variables,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [executeQuery, { loading, error }] =
    useLazyQuery<AllCustomersCSVQueryResponse>(GET_ALL_CUSTOMERS_S3REFURL, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data?.operator) {
          const { customersS3RefUrl } = data.operator;
          if (customersS3RefUrl) {
            const link = document.createElement('a');
            link.setAttribute('href', customersS3RefUrl);
            link.setAttribute('download', 'all_customers.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloading(false);
          } else {
            console.error('customersS3RefUrl is null or undefined');
          }
        }
      },
    });

  const handleDownload = () => {
    if (!downloading) {
      setDownloading(true);
      executeQuery({ variables, fetchPolicy: 'no-cache' });
    }
  };

  return (
    <Button
      onClick={handleDownload}
      disabled={loading || error !== undefined || downloading}
      startIcon={<FileDownload />}
    >
      Export All Customers
    </Button>
  );
};

export default DownloadAllCustomers;
