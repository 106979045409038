import { Box, Button, Fade, List, ListItem, Stack } from '@mui/material';

import { Add } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import { RuleSetInput, SegmentInput } from '../../../../types/segment.types';
import RuleSetForm from '../forms/RuleSet';

interface SegmentDefinitionProps {}

const SegmentDefinition: React.FC<SegmentDefinitionProps> = () => {
  const { setFieldValue, values, errors, validateForm } =
    useFormikContext<SegmentInput>();
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const prevCountRef = useRef<number>(0);

  const handleAddRuleSet = (): void => {
    const ruleSet: RuleSetInput = {
      key: uuidv4(),
      logic: 'AND',
      set: null,
      rules: [
        {
          key: uuidv4(),
          logic: 'AND',
          conditions: [
            {
              key: uuidv4(),
              attributeId: null,
              relation: null,
              values: null,
              negate: false,
              preconditions: null,
            },
          ],
        },
      ],
    };
    validateForm().then((v) => {
      if (!v.ruleSets) {
        setFieldValue('ruleSets', [...values.ruleSets, ruleSet]);
      }
    });
  };

  const handleRemoveRuleSet = (key: string): void => {
    setFieldValue('ruleSets', [
      ...values.ruleSets.filter((ruleSet: any) => ruleSet.key !== key),
    ]);
  };

  useEffect(() => {
    if (
      bottomRef.current !== null &&
      values.ruleSets.length > prevCountRef.current
    ) {
      bottomRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
    prevCountRef.current = values.ruleSets.length;
  }, [values.ruleSets.length]);

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        pr: 1,
        maxHeight: 'calc(100vh - 280px)',
      }}
    >
      <List>
        <TransitionGroup>
          {values.ruleSets.map((ruleSet: RuleSetInput, index: number) => (
            <Fade key={ruleSet.key} timeout={500}>
              <ListItem sx={{ my: 2 }}>
                <RuleSetForm
                  value={ruleSet}
                  errors={errors.ruleSets?.[index]}
                  path={`ruleSets.${index}`}
                  handleDelete={() => {
                    handleRemoveRuleSet(ruleSet.key);
                  }}
                  isFirst={index === 0}
                  hideDelete={values.ruleSets.length < 2}
                />
              </ListItem>
            </Fade>
          ))}
        </TransitionGroup>
      </List>

      <Box sx={{ px: 3, mb: 2 }}>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={handleAddRuleSet}
          startIcon={<Add />}
        >
          Add Rule Set
        </Button>
      </Box>
      <div ref={bottomRef}></div>
    </Stack>
  );
};

export default SegmentDefinition;
