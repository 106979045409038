import {
  Box,
  CircularProgress,
  Grid,
  Icon,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactElement } from 'react';

interface KpiCardProps {
  icon: ReactElement;
  title: string;
  value?: string;
}

const KpiCard: React.FC<KpiCardProps> = ({ icon, title, value }) => {
  const theme = useTheme();
  return (
    <Grid container rowSpacing={3} columnSpacing={1}>
      <Grid item xs={12} sm={12}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Icon sx={{ color: theme.palette.primary.main, mr: 0.5 }}>
            {icon}
          </Icon>
          <Typography variant="h6" sx={{ fontWeight: 'light' }}>
            {title}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h4" align="center">
          {value !== undefined ? value : <CircularProgress />}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default KpiCard;
