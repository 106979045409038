export type Segments = {
  id: string;
  title: string;
  description: string;
  createTime: string;
};

export enum CampaignState {
  ACTIVE_RUNNING = '_10_ACTIVE__10_RUNNING',
  ACTIVE_STOPPED = '_10_ACTIVE__20_STOPPED',
  CREATING = '_20_IN_PROGRESS__10_CREATING',
  UPDATING = '_20_IN_PROGRESS__20_UPDATING',
  ARCHIVING = '_20_IN_PROGRESS__30_ARCHIVING',
  DELETING = '_20_IN_PROGRESS__40_DELETING',
  ARCHIVED = '_30_ARCHIVED',
}

export enum ExecutionState {
  SCHEDULED = '_100_SCHEDULED',
  START_REQUESTED = '_110_START_REQUESTED',
  TARGETING = '_200_TARGETING',
  MESSAGE_PROCESSING = '_210_MESSAGE_PROCESSING',
  DELIVERY = '_220_DELIVERY',
  SUCCEEDED = '_300_SUCCEEDED',
  FAILED = '_310_FAILED',
}

export enum PeriodType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum MessageType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  WEB_PUSH = 'WEB_PUSH',
}
export enum ExecutionSchedulingType {
  RECURRING_EXECUTION = 'RECURRING_EXECUTION',
  CUSTOM_SCHEDULED_EXECUTION = 'CUSTOM_SCHEDULED_EXECUTION',
  IMMEDIATE_EXECUTION = 'IMMEDIATE_EXECUTION',
}

export interface CampaignStats {
  clickCount: number;
  deliveredCount: number;
  filteredCount: number;
  targetedCount: number;
  viewCount: number;
}

export interface Execution {
  deliveryCompleteTime?: string;
  error?: string;
  errorTime?: string;
  startTime?: string;
  targetingCompleteTime?: string;
  messageProcessingCompleteTime?: string;
  isAborted?: boolean;
  scheduleTime: string;
  id: string;
  isDeadlineMissed?: boolean;
  messageType: string;
  stats: CampaignStats;
  state: ExecutionState;
  progress: ExecutionProgress;
}

export interface ExecutionProgress {
  deliveryExpectedCount: number;
  deliveryFailedCount: number;
  deliveryRejectedCount: number;
  deliverySucceededCount: number;
  renderingExpectedCount: number;
  renderingFailedCount: number;
  renderingSucceededCount: number;
  targetingExpectedCount: number;
  targetingFailedCount: number;
  targetingSucceededCount: number;
}

export interface CampaignProgress {
  deliveryExpectedCount: number;
  deliveryFailedCount: number;
  deliveryRejectedCount: number;
  deliverySucceededCount: number;
  renderingExpectedCount: number;
  renderingFailedCount: number;
  renderingSucceededCount: number;
  targetingExpectedCount: number;
  targetingFailedCount: number;
  targetingSucceededCount: number;
}

export interface MinuteSpan {
  value: number;
}

export interface HourSpan {
  value: number;
  minute: MinuteSpan;
}

export interface DaySpan {
  value: number;
  hour: HourSpan;
}

export interface DailyPeriod {
  hour: HourSpan;
}

export interface WeeklyPeriod {
  day: DaySpan;
}

export interface MonthlyPeriod {
  day: DaySpan;
}

export interface Period {
  daily: DailyPeriod;
  weekly: WeeklyPeriod;
  monthly: MonthlyPeriod;
}

export interface CustomSchedulingType {
  startTimes: string[];
}
export interface RecurringExecutionSchedulingType {
  period: Period;
  startTime?: string;
  endTime?: string;
}

export type Campaign = {
  id: string;
  title: string;
  state: CampaignState;
  messageType: MessageType;
  templateUrls: {
    previewUrl: string;
  }
  executionSpec: {
    schedulingType: ExecutionSchedulingType;
    customScheduling: CustomSchedulingType;
    recurringScheduling: RecurringExecutionSchedulingType;
  };
  targetingSpec: {
    excludedSegments: Segments[];
    includedSegments: Segments[];
    ignoreMarketingLimits: boolean;
  };
  executions?: { items: Execution[] };
  stats: CampaignStats;
  createTime: string;
  deliverySpec: {
    connectionId: string;
  };
  templatingSpec: {
    templateContent: {
      senderProfileId: string;
      uploadId: string;
    };
    libraryTemplateId: string;
  };
  progress: CampaignProgress;
}


export interface CampaignQueryVariables {
  nextToken?: string | null;
  status?: CurrentCampaignStatus | null;
}


export enum CurrentCampaignStatus {
  RUNNING = 'RUNNING',
  PAUSED = 'PAUSED',
  ARCHIVED = "ARCHIVED"
}

export type CampaignQueryResponse = {
  operator: {
    currentCampaigns: { items: Campaign[]; nextToken?: string | null };
    archivedCampaigns: { items: Campaign[]; nextToken?: string | null };
  };
};

export type CampaignDetailQueryResponse = {
  operator: {
    campaign: Campaign;
  };
};

export type CreateCampaignInput = {
  operatorId: string;
  messageType: MessageType;
  title: string;
  description?: string | null;
  templatingSpec: TemplatingSpecInput;
  deliverySpec: DeliverySpecInput;
  trackingSpec: TrackingSpecInput;
  targetingSpec: TargetingSpecInput;
  executionSpec: ExecutionSpecInput;
};

export type UpdateCampaignInput = {
  id: string;
  operatorId: string;
  title: string;
  description?: string | null;
  templatingSpec?: TemplatingSpecInput;
  deliverySpec: DeliverySpecInput;
  trackingSpec: TrackingSpecInput;
  targetingSpec: TargetingSpecInput;
  executionSpec: ExecutionSpecInput;
};

export type TemplatingSpecInput = {
  templateContent: TemplateContentInput | null;
  libraryTemplateId: string | null;
};

export type TemplateContentInput = {
  senderProfileId: string;
  uploadId: string;
};

export type DeliverySpecInput = {
  connectionId: string;
};

export type TrackingSpecInput = {
  trackingDomainId: string;
};

export type TargetingSpecInput = {
  includedSegments: string[];
  excludedSegments: string[] | null;
  ignoreMarketingLimits: boolean;
};

export type ExecutionSpecInput = {
  schedulingType: ExecutionSchedulingType;
  recurringScheduling: RecurringExecutionSchedulingInput | null;
  customScheduling: CustomScheduleExecutionSchedulingInput | null;
};

export type RecurringExecutionSchedulingInput = {
  startTime: string | null;
  endTime: string | null;
  period: PeriodInput;
};

export type PeriodInput = {
  daily: DailyPeriodInput | null;
  weekly: WeeklyPeriodInput | null;
  monthly: MonthlyPeriodInput | null;
};

export type DailyPeriodInput = {
  hour: HourSpanInput;
};

export type MonthlyPeriodInput = {
  day: DaySpanInput;
};

export type WeeklyPeriodInput = {
  day: DaySpanInput;
};

export type DaySpanInput = {
  value: number;
  hour: HourSpanInput;
};

export type HourSpanInput = {
  value: number;
  minute: MinuteSpanInput;
};

export type MinuteSpanInput = {
  value: number;
};

export type CustomScheduleExecutionSchedulingInput = {
  startTimes: string[];
};


export type CustomerCountQueryResponse = {
  operator: {
    customersCount?: number;
  };
};