import { gql, useMutation, useQuery } from '@apollo/client';
import { Refresh, Warning } from '@mui/icons-material';
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useOperator from '../../../hooks/useOperator';
import { Segment } from '../../../types/segment.types';

interface SegmentChipProps {
  segmentId: string;
}

interface UpdateSegmentCustomersCountMutationVariables {
  operatorId: string;
  segmentId: string;
}

interface UpdateSegmentCustomersCountMutationResponse {
  updateSegmentCustomersCount: {
    customersCount: number;
    customersCountUpdateTime: string;
  };
}
export type SegmentQueryResponse = {
  operator: {
    segment: Segment;
    customersCount: number;
  };
};

const UPDATE_SEGMENT_CUSTOMERS_COUNT = gql`
  mutation UpdateSegmentCustomersCount($operatorId: ID!, $segmentId: ID!) {
    updateSegmentCustomersCount(
      operatorId: $operatorId
      segmentId: $segmentId
    ) {
      customersCount
      customersCountUpdateTime
    }
  }
`;

const SegmentChip: React.FC<SegmentChipProps> = (props) => {
  const [operator] = useOperator();

  const GET_SEGMENT = gql`
    query GetSegment($operatorId: ID!, $id: ID!) {
      operator(id: $operatorId) {
        customersCount
        segment(id: $id) {
          id
          title
          description
          customersCount
          customersCountUpdateTime
        }
      }
    }
  `;

  const { data } = useQuery<SegmentQueryResponse>(GET_SEGMENT, {
    variables: {
      operatorId: operator!.id,
      id: props.segmentId,
    },
  });

  const [count, setCount] = useState<number | null>(null);
  const [countUpdateTime, setCountUpdateTime] = useState<string | null>(null);

  const [updateSegmentCustomersCount, { loading: countLoading }] = useMutation<
    UpdateSegmentCustomersCountMutationResponse,
    UpdateSegmentCustomersCountMutationVariables
  >(UPDATE_SEGMENT_CUSTOMERS_COUNT, {
    onCompleted: (data) => {
      setCount(data.updateSegmentCustomersCount.customersCount);
      setCountUpdateTime(
        data.updateSegmentCustomersCount.customersCountUpdateTime,
      );
    },
    notifyOnNetworkStatusChange: true,
  });

  const segment = data?.operator.segment;

  const refreshCount = () => {
    if (segment && !countLoading) {
      updateSegmentCustomersCount({
        variables: {
          operatorId: operator!.id,
          segmentId: segment!.id,
        },
      });
    }
  };
  useEffect(() => {
    segment && setCountUpdateTime(segment.customersCountUpdateTime);
  }, [segment]);
  const [isInfoOpen, setInfoOpen] = useState(false);

  const handleInfoClick = () => {
    setInfoOpen(!isInfoOpen);
  };

  const [percentage, setPercentage] = useState<number | null>(null);

  useEffect(() => {
    if (
      segment &&
      segment.customersCount !== null &&
      data.operator.customersCount !== undefined
    ) {
      const calculatedPercentage =
        (segment.customersCount / data.operator.customersCount) * 100;

      setPercentage(calculatedPercentage);
    }
  }, [segment, data?.operator.customersCount]);

  if (segment) {
    const showWarningIcon =
      segment &&
      segment.customersCount !== null &&
      segment.customersCount >= 100000;

    return (
      <Stack direction="row" spacing={0.2} alignItems="center">
        <Tooltip title={`Count last updated at: ${countUpdateTime}`}>
          <Chip
            onDelete={refreshCount}
            deleteIcon={<Refresh />}
            label={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                gap={1}
              >
                <Typography variant="subtitle1">{segment.title}</Typography>
                {countLoading ? (
                  <CircularProgress size={15} />
                ) : (
                  <Typography variant="caption">
                    {count || segment.customersCount}
                  </Typography>
                )}
              </Box>
            }
          />
        </Tooltip>

        {showWarningIcon && (
          <Tooltip
            open={isInfoOpen}
            onClick={handleInfoClick}
            color="main"
            onClose={() => setInfoOpen(false)}
            title={
              segment.customersCount === 0
                ? 'Segment is empty'
                : percentage !== null && percentage > 10
                ? `This segment has more customers than ${percentage?.toFixed(
                    2,
                  )}%
                  of your total customers.`
                : undefined
            }
          >
            <IconButton color="warning">
              <Warning />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    );
  }
  return <>asdasd</>;
};

export default SegmentChip;
