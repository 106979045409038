import { CircularProgress } from '@mui/material';
import Navigation from '../Navigation';

const Loading = () => {
  return (
    <Navigation>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '50px',
        }}
      >
        <CircularProgress />
      </div>
    </Navigation>
  );
};

export default Loading;

const PlainLoading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50px',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export { PlainLoading };
