import { useQuery } from '@apollo/client';
import { AccountCircle, FileDownload, People, Send } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  LinearProgress,
  ListItemIcon,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import {
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import md5 from 'md5';
import React, { useEffect, useMemo, useRef } from 'react';
import { ReactComponent as ReportsBannerLeftDarkImage } from '../../assets/illustrations/Reports/reportsBannerDarkLeft.svg';
import { ReactComponent as ReportsBannerRightDarkImage } from '../../assets/illustrations/Reports/reportsBannerDarkRight.svg';
import { ReactComponent as ReportsBannerLeftImage } from '../../assets/illustrations/Reports/reportsBannerLeft.svg';
import { ReactComponent as ReportsBannerRightImage } from '../../assets/illustrations/Reports/reportsBannerRight.svg';
import Banner from '../../components/Banner';
import DownloadAllCustomers from '../../components/Download/customersCSV';
import KpiCard from '../../components/KpiCard';
import Navigation from '../../components/Navigation/Navigation';
import { GET_OPERATOR_STATS } from '../../graphql/local/queries';
import { GET_CUSTOMERS } from '../../graphql/local/queries/customers';
import useOperator from '../../hooks/useOperator';
import { Customer, CustomersQueryResponse } from '../../types/customers.types';
import { DashboardQueryResponse } from '../../types/dashboard.types';

export default function Customers() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [operator] = useOperator();

  const {
    loading,
    error,
    data: operatorStats,
  } = useQuery<DashboardQueryResponse>(GET_OPERATOR_STATS, {
    variables: {
      operatorId: operator!.id,
    },
    //pollInterval: 15000,
  });

  const [pageTokens, setPageTokens] = React.useState<
    Map<number, string | null>
  >(new Map([[0, null]]));

  const customersCount = operatorStats?.operator?.customersCount;

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const {
    loading: customersLoading,
    error: customersError,
    data: customersData,
    fetchMore: customersFetchMore,
  } = useQuery<CustomersQueryResponse>(GET_CUSTOMERS, {
    variables: {
      operatorId: operator!.id,
      nextToken: pageTokens.get(pagination.pageIndex) || null,
    },
  });
  const customersDataRef = useRef(customersData);
  useEffect(() => {
    customersDataRef.current = customersData;
  }, [customersData]);

  useEffect(() => {
    if (loading || !customersDataRef.current) {
      return;
    }

    var token: string | null = null;

    if (pageTokens.has(pagination.pageIndex)) {
      token = pageTokens.get(pagination.pageIndex)!;
    } else {
      token = customersDataRef.current?.operator.customers.nextToken || null;
      setPageTokens((prev) => new Map(prev.set(pagination.pageIndex, token)));
    }

    customersFetchMore({
      variables: {
        nextToken: token,
      },
    });
  }, [
    pagination.pageIndex,
    pageTokens,
    loading,
    customersFetchMore,
    customersDataRef,
  ]);

  const [isLoading, setIsLoading] = React.useState(loading);

  React.useEffect(() => {
    setIsLoading(loading || customersLoading);
  }, [loading, customersLoading]);

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const columns = useMemo<MRT_ColumnDef<Customer>[]>(
    () => [
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`, //accessorFn used to join multiple data into a single cell
        id: 'name', //id is still required when using accessorFn instead of accessorKey
        header: 'Full Name',
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <img
              alt="avatar"
              height={30}
              src={`https://www.gravatar.com/avatar/${md5(
                row.original.email_address,
              )}?s=200`}
              loading="lazy"
              style={{ borderRadius: '50%' }}
            />
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      // {
      //   accessorKey: 'first_name', //simple recommended way to define a column
      //   header: 'Name',
      //   muiTableHeadCellProps: { style: { color: 'black' } }, //custom props
      //   enableHiding: false, //disable a feature for this column
      //   enableColumnFilter: false,
      // },
      // {
      //   accessorKey: 'last_name', //simple recommended way to define a column
      //   header: 'Surname',
      //   muiTableHeadCellProps: { style: { color: 'black' } }, //custom props
      //   enableHiding: false, //disable a feature for this column
      //   enableColumnFilter: false,
      // },
      {
        accessorKey: 'email_address', //simple recommended way to define a column
        header: 'Email Address',
        muiTableHeadCellProps: {}, //custom props
        enableHiding: false, //disable a feature for this column
        enableColumnFilter: false,
      },
      {
        accessorKey: 'mobile_number', //simple recommended way to define a column
        header: 'Mobile Number',
        muiTableHeadCellProps: {}, //custom props
        enableHiding: false, //disable a feature for this column
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const handleExportRows = (rows: MRT_Row<Customer>[]) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const [exportLoading, setExportLoading] = React.useState(false);
  const [exportCancel, setExportCancel] = React.useState(false);
  const exportAbortController = useRef<AbortController | null>(null);

  const handleExportData = async () => {
    try {
      setExportLoading(true);
      setExportCancel(false);

      exportAbortController.current = new AbortController();

      const { signal } = exportAbortController.current;

      const allData = [];
      let token = pageTokens.get(0);

      do {
        if (signal.aborted || exportCancel) {
          // Check if cancel button is pressed
          setExportCancel(false);
          setExportLoading(false);
          return;
        }

        const { data } = await customersFetchMore({
          variables: {
            nextToken: token,
          },
          context: { fetchOptions: { signal } },
        });

        const items = data?.operator.customers.items || [];
        allData.push(...items);

        token = data?.operator.customers.nextToken;
      } while (token);
    } finally {
      setExportLoading(false);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: customersData?.operator.customers.items || [],
    manualPagination: true,
    rowCount: customersCount || 0,
    onPaginationChange: setPagination,
    state: { pagination },
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableGlobalFilter: false,
    enableColumnFilterModes: false,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    // enableRowNumbers: false,
    rowNumberDisplayMode: 'original',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      showFirstButton: false,
      showLastButton: false,
      showRowsPerPage: false,
    },

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          // View profile logic...
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        View Profile
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // Send email logic...
          const email = row.original.email_address;
          window.open(`mailto:${email}`);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        Send Email
      </MenuItem>,
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <DownloadAllCustomers
          variables={{ operatorId: operator!.id }}
          onClick={handleExportData}
          startIcon={<FileDownload />}
          disabled={exportLoading}
        ></DownloadAllCustomers>

        {/* <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownload />}
        >
          Export All Rows
        </Button> */}
        {/* <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownload />}
        >
          Export Page Rows
        </Button> */}
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownload />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <img
          alt="avatar"
          height={200}
          src={`https://www.gravatar.com/avatar/${md5(
            row.original.email_address,
          )}?s=200`}
          loading="lazy"
          style={{ borderRadius: '50%' }}
        />

        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">City:</Typography>
          <Typography variant="caption">
            &quot;{row.original.city}&quot;
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">Date Of Birth:</Typography>
          <Typography variant="caption">
            &quot;{row.original.date_of_birth}&quot;
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">TimeZone:</Typography>
          <Typography variant="caption">
            &quot;{row.original.time_zone}&quot;
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">Country Code:</Typography>
          <Typography variant="caption">
            &quot;{row.original.country_code}&quot;
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">Post Code:</Typography>
          <Typography variant="caption">
            &quot;{row.original.post_code}&quot;
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">Sign Up Date:</Typography>
          <Typography variant="caption">
            &quot;{row.original.sign_up_date}&quot;
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">Tags:</Typography>
          <Typography variant="caption">
            &quot;{row.original.tags}&quot;
          </Typography>
        </Box>
      </Box>
    ),
  });

  return (
    <Navigation>
      <Banner
        leftImage={
          isDarkMode ? (
            <ReportsBannerLeftDarkImage />
          ) : (
            <ReportsBannerLeftImage />
          )
        }
        rightImage={
          isDarkMode ? (
            <ReportsBannerRightDarkImage />
          ) : (
            <ReportsBannerRightImage />
          )
        }
        kpiCards={[
          <KpiCard
            icon={<People />}
            title="Total Customers"
            value={customersCount?.toLocaleString() || '0'}
          ></KpiCard>,
          <KpiCard
            icon={<People />}
            title="This Week Income"
            value="0"
          ></KpiCard>,
          <KpiCard icon={<People />} title="Total Clicks" value="0"></KpiCard>,
        ]}
      />
      {isLoading && <LinearProgress />}
      {(error || customersError) && (
        <Alert severity="error">
          <AlertTitle>Error fetching customers.</AlertTitle>
          {error?.message || customersError?.message}
        </Alert>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialReactTable table={table} />
        </Grid>
      </Grid>
    </Navigation>
  );
}
