

export enum LibraryTemplateStatus {
  READY = 'READY',
  WAITING_UPLOAD = 'WAITING_UPLOAD',
}

export enum ContentKind {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  WEB_PUSH = 'WEB_PUSH',
}

export interface LibraryTemplateUrls {
  contentUrl: string;
  previewUrl: string;
  thumbnailUrl: string;
}

export type Template = {
  id: string;
  status: LibraryTemplateStatus;
  title: string;
  description: string;
  createTime: string;
  contentKind: ContentKind;
  templateUrls: LibraryTemplateUrls;
};

export interface LibraryTemplateQueryVariables {
  nextToken?: string | null;
}

export type LibraryTemplatesQueryResponse = {
  operator: {
    libraryTemplates: { items: Template[]; nextToken?: string | null };
  };
};

export type LibraryTemplateQueryResponse = {
  operator: {
    libraryTemplate: Template;
  };
};


export type DeleteLibraryTemplateInput = {
  operatorId: string;
  id: string;
};


export enum TemplateUploadType {
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  SMS_TEMPLATE = 'SMS_TEMPLATE',
  WEB_PUSH_TEMPLATE = 'WEB_PUSH_TEMPLATE',
}


export type CreateLibraryTemplateInput = {
  operatorId: string;
  title: string;
  description?: string | null;
  senderProfileId?: string | null;
  contentKind: ContentKind;
  uploadId: string;
};

export type TemplatesStatsQueryResponse = {
  operator: {
    libraryTemplatesCount?: number;
  };
};
