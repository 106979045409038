import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import useOperator from '../../../hooks/useOperator';

interface CreateDomainProps {
  isOpen: boolean;
  handleClose: () => void;
  operatorId?: string;
}

const CREATE_DOMAIN = gql`
  mutation CreateDomain($dnsName: String!) {
    createDomain(input: { dnsName: $dnsName }) {
      id
    }
  }
`;

const CreateDomain: React.FC<CreateDomainProps> = ({ isOpen, handleClose }) => {
  const [dnsName, setDnsName] = useState('');
  const [createDomainMutation] = useMutation(CREATE_DOMAIN);
  const [operator] = useOperator();

  const close = () => {
    handleClose();
    setDnsName('');
  };

  const handleCreateDomain = async () => {
    try {
      await createDomainMutation({
        variables: {
          input: { dnsName, operatorId: operator?.id || 0 },
        },
      });
      handleClose();
    } catch (error) {
      console.error('Error creating domain:', error);
    }
  };

  return (
    <Dialog open={isOpen} maxWidth={'md'} fullWidth>
      <DialogTitle>Create Domain</DialogTitle>
      <DialogContent>
        <TextField
          label="DNS Name"
          placeholder='DNS Name (e.g. "example.com")'
          variant="standard"
          value={dnsName}
          onChange={(e) => setDnsName(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={handleCreateDomain}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDomain;
