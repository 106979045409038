import React from 'react';
import ReactFlow, { Controls, Edge, MarkerType, Node } from 'reactflow';

import {
  Box,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import 'reactflow/dist/style.css';
import useSchemas from '../../../hooks/useSchemas';
import { RuleSet } from '../../../types/segment.types';
import ConditionNode, { ConditionDataProps } from './Nodes/ConditionNode';
import GroupNode, { GroupDataProps } from './Nodes/GroupNode';

//const connectionLineStyle = { stroke: '#fff' };

const nodeTypes = {
  conditionNode: ConditionNode,
  preconditionNode: ConditionNode,
  group: GroupNode,
};

const proOptions = { hideAttribution: true };

type SegmentRulesViewProps = {
  ruleSets: RuleSet[];
  width?: number;
  height?: number;
};

const RelationOptions: Record<string, string> = {
  EQ: '',
  LT: 'less than',
  LTE: 'less than or equal to',
  BETWEEN: 'between',
  GT: 'greater than',
  GTE: 'greater than or equal to',
  ONE_OF: 'one of',
  PREFIX: 'starts with',
  CONTAINS: 'contains',
  SUFFIX: 'ends with',
  ANNIVERSARY: 'anniversary',
  IS_NULL: 'empty',
};

const SegmentRulesView: React.FC<SegmentRulesViewProps> = ({
  ruleSets,
  height,
}) => {
  const schemas = useSchemas();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const isSmallerThanLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg'),
  );

  const attributes = _.keyBy(
    _.flatMap(schemas, (schema) =>
      _.map(schema.attributes, (attr) =>
        _.assign({}, attr, {
          category: schema.title,
          label: `${schema.title} ${attr.title}`,
        }),
      ),
    ),
    'id',
  );

  const preconditionAttributes = _.keyBy(
    _.flatMap(schemas, (schema) =>
      _.map(schema.preconditionAttributes, (attr) =>
        _.assign({}, attr, {
          category: schema.title,
          label: `${schema.title} ${attr.title}`,
        }),
      ),
    ),
    'id',
  );

  const nodes: Node<ConditionDataProps | GroupDataProps>[] = [];
  const conditionNodes: Node<ConditionDataProps | GroupDataProps>[] = [];
  const ruleSetNodes: Node<ConditionDataProps | GroupDataProps>[] = [];
  const ruleNodes: Node<ConditionDataProps | GroupDataProps>[] = [];

  const edges: Edge<ConditionDataProps>[] = [];

  var flowHeight = 10;

  ruleSets.forEach((ruleSet, index) => {
    var ruleSetHeight = 20;
    const ruleSetId = `ruleSet-${index}`;

    ruleSet.rules.forEach((rule, ruleIndex) => {
      var ruleHeight = 20;
      const ruleId = `${ruleSetId}/rule-${ruleIndex}`;

      rule.conditions.forEach((condition, conditionIndex) => {
        const conditionId = `${ruleId}/condition-${conditionIndex}`;
        const node: Node<ConditionDataProps> = {
          id: conditionId,
          type: 'conditionNode',
          draggable: false,
          data: {
            attributeName:
              attributes[condition.attributeId]?.category +
              ' ' +
              attributes[condition.attributeId]?.title,
            attributeValues: condition.values,
            relationText:
              (condition.negate ? 'is not ' : 'is ') +
              RelationOptions[condition.relation],
            isFirst: conditionIndex === 0,
            isLast: conditionIndex === rule.conditions.length - 1,
            hasPrecondition: !!condition.preconditions?.length,
          },
          parentNode: ruleId,
          position: { x: 20, y: ruleHeight },
        };
        conditionNodes.push(node);

        if (
          condition.preconditions === null ||
          condition.preconditions.length === 0
        ) {
          ruleHeight += 60;
        }

        condition.preconditions?.forEach((precondition, preconditionIndex) => {
          const preconditionId = `${conditionId}/precondition-${preconditionIndex}`;
          const node: Node<ConditionDataProps> = {
            id: preconditionId,
            type: 'preconditionNode',
            draggable: false,
            data: {
              attributeName:
                preconditionAttributes[precondition.attributeId]?.title,
              attributeValues: precondition.values,
              relationText: precondition.negate
                ? 'is not '
                : 'is ' + RelationOptions[precondition.relation],
              isFirst: preconditionIndex === 0,
              isLast: preconditionIndex === condition.preconditions!.length - 1,
            },
            position: { x: 430, y: ruleHeight },
            parentNode: ruleId,
          };
          conditionNodes.push(node);
          ruleHeight += condition.preconditions?.length === 1 ? 60 : 40;

          const edge: Edge<ConditionDataProps> = {
            id: `${preconditionId}-precondition`,
            source: conditionId,
            target: preconditionId,
            label: 'WHERE',
            labelStyle: { fontSize: 7 },
            animated: false,
            sourceHandle: 'rightSource',
            targetHandle: 'leftTarget',
          };
          edges.push(edge);
        });

        if (conditionIndex > 0) {
          const prevConditionId = `${ruleId}/condition-${conditionIndex - 1}`;
          edges.push({
            id: `${conditionId}-precondition`,
            source: prevConditionId,
            target: conditionId,
            label: rule.logic,
            labelStyle: {
              fontSize: 8,
              //fill: theme.palette.primary.main,
              fontWeight: 600,
              padding: 0,
              margin: 0,
            },
            animated: true,
          });
        }
      });
      const ruleNode: Node<GroupDataProps> = {
        id: ruleId,
        type: 'group',
        draggable: false,
        data: {
          isFirst: ruleIndex === 0,
          isLast: ruleIndex === ruleSet.rules.length - 1,
        },
        position: { x: 20, y: ruleSetHeight },
        style: {
          minWidth: 760,
          height: ruleHeight - 10,
          borderStyle: 'dashed',
          borderWidth: 0.5,
        },
        parentNode: ruleSetId,
      };
      ruleNodes.push(ruleNode);
      ruleSetHeight += ruleHeight + 30;

      if (ruleIndex > 0) {
        const prevRuleId = `${ruleSetId}/rule-${ruleIndex - 1}`;
        edges.push({
          id: `${ruleId}-edge`,
          source: prevRuleId,
          target: ruleId,
          label: ruleSet.logic,
          //style: { stroke: theme.palette.primary.main },
          labelStyle: {
            fontSize: 10,
            //fill: theme.palette.primary.main,
            fontWeight: 800,
            padding: 0,
            margin: 0,
          },
          animated: true,
        });
      }
    });

    let ruleSetEdgeColor = theme.palette.success.main;
    if (ruleSet.set === 'EXCEPT') {
      ruleSetEdgeColor = theme.palette.warning.main;
    } else if (ruleSet.set === 'INTERSECT') {
      ruleSetEdgeColor = theme.palette.info.main;
    }

    const ruleSetNode: Node<GroupDataProps> = {
      id: ruleSetId,
      type: 'group',
      draggable: false,
      data: {
        isFirst: index === 0,
        isLast: index === ruleSets.length - 1,
      },
      position: { x: 10, y: flowHeight },
      style: {
        width: 800,
        //backgroundColor: 'transparent',
        height: ruleSetHeight - 20,
        borderColor: ruleSetEdgeColor,
        borderStyle: 'none',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        borderBottomStyle: 'dashed',
        borderBottomWidth: 1.5,
      },
    };
    flowHeight += ruleSetHeight + 40;
    ruleSetNodes.push(ruleSetNode);

    if (index > 0) {
      const prevRuleSetId = `ruleSet-${index - 1}`;
      edges.push({
        id: `${ruleSetId}-edge`,
        source: prevRuleSetId,
        target: ruleSetId,
        label: ruleSet.set,
        style: { stroke: ruleSetEdgeColor },
        markerEnd: MarkerType.ArrowClosed,
        labelStyle: {
          fontSize: 12,
          fill: ruleSetEdgeColor,
          fontWeight: 1000,
          padding: 0,
          margin: 0,
        },
        animated: true,
      });
    }
  });
  nodes.push(...ruleSetNodes);
  nodes.push(...ruleNodes);
  nodes.push(...conditionNodes);

  return (
    <Paper elevation={3}>
      <Typography variant="h6" component="div" sx={{ p: 2, pb: 0 }}>
        Segment Rules
      </Typography>
      <Box
        width={'100%'}
        height={
          height
            ? height
            : isSmallerThanMd
            ? flowHeight / 2 + 75
            : isSmallerThanLg
            ? flowHeight / 1.5 + 75
            : flowHeight + 75
        }
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          proOptions={proOptions}
          nodeTypes={nodeTypes}
          fitView
          defaultViewport={{
            zoom: 0.01,
            x: 200,
            y: height ? -height / 2 : -flowHeight / 2,
          }}
          fitViewOptions={{
            padding: 0.05,
            includeHiddenNodes: false,
            minZoom: 0.1,
            maxZoom: 1.8,
            duration: height ? 1000 : 0,
          }}
          panOnScroll
          selectionOnDrag
        >
          <Controls
            showInteractive={false}
            position="top-left"
            style={{ marginLeft: 10 }}
          />
        </ReactFlow>
      </Box>
    </Paper>
  );
};

export default SegmentRulesView;
