import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FormikErrors, FormikValues, useFormikContext } from 'formik';
import React from 'react';
import { ReccuringPeriodMapping } from '../../../mappings';
import { PeriodType } from '../../../types/campaign.types';

interface CampaignScheduleRecurringProps {
  errors: FormikErrors<{
    title: string;
    type: string;
    timePeriod: string;
    day: string;
    time: string;
    startTime: string;
    endTime: string;
    schedulingType: string;
  }>;
  values: FormikValues;
  handleChange: any;
}

const CampaignScheduleRecurring: React.FC<CampaignScheduleRecurringProps> = ({
  errors,
  values,
  handleChange,
}) => {
  const { setFieldValue } = useFormikContext();
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  React.useEffect(() => {
    if (values.startImmediately) {
      values.startTime = dayjs().add(10, 'second');
      setFieldValue('startTime', values.startTime);
    }
    if (values.neverStop) {
      values.endTime = null;
      setFieldValue('endTime', values.endTime);
    }
  }, [values, setFieldValue]);
  return (
    <Grid
      container
      spacing={3}
      sx={{
        py: {
          md: 5,
          xs: 2,
        },
      }}
    >
      <Grid
        item
        md={
          values.timePeriod === '' || values.timePeriod === PeriodType.DAILY
            ? 8
            : 6
        }
        xs={12}
      >
        <FormControl fullWidth>
          <InputLabel id="period-label">Time Period</InputLabel>
          <Select
            fullWidth
            labelId="period-label"
            label="Time Period"
            id="timePeriod"
            error={!!errors.timePeriod}
            value={values.timePeriod}
            onChange={handleChange('timePeriod')}
            displayEmpty
          >
            {Object.values(PeriodType).map((period) => (
              <MenuItem key={period} value={period}>
                {ReccuringPeriodMapping[period]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {values.timePeriod !== '' && values.timePeriod !== PeriodType.DAILY ? (
        <Grid item md={3} xs={6}>
          <FormControl fullWidth>
            <InputLabel id="day-label">Day</InputLabel>
            <Select
              fullWidth
              label="Select Day"
              labelId="day-label"
              id="day"
              error={!!errors.day}
              value={values.day}
              onChange={handleChange('day')}
              displayEmpty
            >
              {values.timePeriod === PeriodType.WEEKLY
                ? days.map((day, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {day}
                    </MenuItem>
                  ))
                : Array.from({ length: 31 }, (_, i) => i + 1).map(
                    (day: number) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ),
                  )}
            </Select>
          </FormControl>
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        item
        md={
          values.timePeriod === '' || values.timePeriod === PeriodType.DAILY
            ? 4
            : 3
        }
        xs={
          values.timePeriod === '' || values.timePeriod === PeriodType.DAILY
            ? 12
            : 6
        }
      >
        <FormControl fullWidth>
          <TimePicker
            label="Select Time"
            value={values.time === null ? null : values.time}
            onChange={(value): void => {
              setFieldValue('time', value);
            }}
            ampm={false}
            sx={{ width: '100%' }}
          />
        </FormControl>
      </Grid>

      <Grid item md={4} xs={6}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <DateTimePicker
                label="Start Date"
                disablePast={!values.isUpdating}
                value={values.startTime === null ? null : values.startTime}
                onChange={(value): void => {
                  setFieldValue('startTime', value);
                }}
                disabled={values.startImmediately}
                ampm={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  defaultChecked={values.startImmediately}
                />
              }
              label={
                <Typography variant="caption">Start Immediately</Typography>
              }
              onChange={handleChange('startImmediately')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={6}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <DateTimePicker
                label="End Date"
                disablePast
                value={values.endTime === null ? null : values.endTime}
                onChange={(value): void => {
                  setFieldValue('endTime', value);
                }}
                disabled={values.neverStop}
                ampm={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox size="small" defaultChecked={values.neverStop} />
              }
              label={<Typography variant="caption">Never Stop</Typography>}
              onChange={handleChange('neverStop')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              defaultChecked={values.ignoreMarketingLimit}
              onChange={handleChange('ignoreMarketingLimit')}
            />
          }
          label={
            <Typography variant="body2">
              Ignore Global Marketing Frequency Limit
            </Typography>
          }
        />
      </Grid>
    </Grid>
  );
};

export default CampaignScheduleRecurring;
