import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { ControlPoint, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { Img } from 'react-image';
import Loading from '../../../components/Loading';
import Navigation from '../../../components/Navigation';
import useOperator from '../../../hooks/useOperator';
import { OperatorQueryResponse } from '../../../types/operator.types';
import CreateConnection from './CreateConnection';
import CreateDomain from './CreateDomain';
import CreateSenderProfile from './CreateSenderProfile';

export default function OperatorDetails() {
  const [currentOperator] = useOperator();
  const [isErrorWindowOpen, setIsErrorWindowOpen] = React.useState(false);

  const [isConfirmWindowOpen, setIsConfirmWindowOpen] = React.useState(false);
  const [deleteWhat, setDeleteWhat] = React.useState('');
  const [agreeFunction, setAgreeFunction] = React.useState(() => () => {});

  const handleConfirmWindowClose = () => {
    setIsConfirmWindowOpen(false);
  };

  const [errorMsg, setErrorMsg] = React.useState('');

  const GET_OPERATOR = gql`
    query Operator($id: ID!) {
      operator(id: $id) {
        id
        title
        timezone
        avatarUrl
        dailyMarketingLimit
        domains {
          id
          dnsName
        }
        connections {
          id
          label
          connectorKind
          connectorConfigJSON
        }
        senderProfiles {
          items {
            id
            title
            kind
            emailProfile {
              fromAddress
              fromName
            }
            smsProfile {
              sender
            }
          }
        }
      }
    }
  `;

  const GET_CONNECTORS = gql`
    query Connectors {
      listConnectors {
        id
        name
        description
        kind
        configFields {
          name
          description
          kind
          required
        }
        properties {
          rateLimitMPM
        }
      }
    }
  `;
  const DELETE_SENDER_PROFILE = gql`
    mutation DeleteSenderProfile($id: ID!, $operatorId: ID!) {
      deleteSenderProfile(input: { id: $id, operatorId: $operatorId }) {
        id
      }
    }
  `;

  const DELETE_DOMAIN = gql`
    mutation DeleteDomain($id: ID!, $operatorId: ID!) {
      deleteDomain(input: { id: $id, operatorId: $operatorId }) {
        id
      }
    }
  `;

  const TERMINATE_CONNECTION = gql`
    mutation TerminateConnection($connectionId: ID!, $operatorId: ID!) {
      terminateConnection(
        input: { connectionId: $connectionId, operatorId: $operatorId }
      ) {
        id
      }
    }
  `;

  const { data: connectorsData } = useQuery(GET_CONNECTORS);

  const { loading, error, data, refetch } = useQuery<OperatorQueryResponse>(
    GET_OPERATOR,
    {
      variables: { id: currentOperator?.id },
    },
  );

  const operator = data?.operator;
  const [isLoading, setIsLoading] = React.useState(loading);
  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleErrorClose = () => {
    setIsErrorWindowOpen(false);
  };

  // Sender Profile
  const [isCreateSenderProfileOpen, setIsCreateSenderProfileOpen] =
    React.useState(false);

  const handleCreateSenderProfileOpen = () => {
    setIsCreateSenderProfileOpen(true);
  };
  const handleCreateSenderProfileClose = () => {
    refetch();
    setIsCreateSenderProfileOpen(false);
  };

  const [deleteSenderProfileMutation] = useMutation(DELETE_SENDER_PROFILE);

  const deleteSenderProfile = (senderProfileId: string) => {
    setAgreeFunction(() => () => handleDeleteSenderProfile(senderProfileId));
    setDeleteWhat('Delete Sender Profile');
    setIsConfirmWindowOpen(true);
  };

  const handleDeleteSenderProfile = async (senderProfileId: string) => {
    try {
      await deleteSenderProfileMutation({
        variables: { id: senderProfileId, operatorId: currentOperator?.id },
      });

      refetch();
    } catch (error) {
      setErrorMsg('Error deleting sender profile');
      console.error('Error deleting sender profile:', error);
      // Display an error message to the user if needed
      setIsErrorWindowOpen(true);
    } finally {
      setIsConfirmWindowOpen(false);
    }
  };

  // Domain
  const [isCreateDomainOpen, setIsCreateDomainOpen] = React.useState(false);

  const handleCreateDomainOpen = () => {
    setIsCreateDomainOpen(true);
  };

  const handleCreateDomainClose = () => {
    refetch();
    setIsCreateDomainOpen(false);
  };

  const [deleteDomainMutation] = useMutation(DELETE_DOMAIN);

  const deleteDomain = (domainId: string) => {
    setAgreeFunction(() => () => handleDeleteDomain(domainId));
    setDeleteWhat('Delete Domain');
    setIsConfirmWindowOpen(true);
  };

  const handleDeleteDomain = async (domainId: string) => {
    try {
      await deleteDomainMutation({
        variables: { id: domainId, operatorId: currentOperator?.id },
      });

      // Refetch the data to update the UI
      refetch();
    } catch (error) {
      setErrorMsg('Error deleting domain');
      console.error('Error deleting domain:', error);
      // Display an error message to the user if needed
      setIsErrorWindowOpen(true);
    } finally {
      setIsConfirmWindowOpen(false);
    }
  };

  // Connection

  const [isCreateConnectionOpen, setIsCreateConnectionOpen] =
    React.useState(false);

  const handleCreateConnectionOpen = () => {
    setIsCreateConnectionOpen(true);
  };

  const handleCreateConnectionClose = () => {
    refetch();
    setIsCreateConnectionOpen(false);
  };

  const [terminateConnectionMutation] = useMutation(TERMINATE_CONNECTION);

  const terminateConnection = (connectionId: string) => {
    setAgreeFunction(() => () => handleTerminateConnection(connectionId));
    setDeleteWhat('Terminate Connection');
    setIsConfirmWindowOpen(true);
  };

  const handleTerminateConnection = async (connectionId: string) => {
    try {
      // Call the mutation to remove the connection
      await terminateConnectionMutation({
        variables: { connectionId, operatorId: currentOperator?.id },
      });

      // Refetch the data to update the UI
      refetch();
    } catch (error) {
      setErrorMsg(`${(error as ApolloError).message.slice(7)}`);
      console.error('Error terminating connection:', error);
      setIsErrorWindowOpen(true);
    } finally {
      setIsConfirmWindowOpen(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Navigation>
      <CreateConnection
        isOpen={isCreateConnectionOpen}
        handleClose={handleCreateConnectionClose}
        connectors={connectorsData?.listConnectors || []}
        operatorId={currentOperator?.id}
      />

      <CreateDomain
        isOpen={isCreateDomainOpen}
        handleClose={handleCreateDomainClose}
        operatorId={currentOperator?.id}
      />

      <CreateSenderProfile
        isOpen={isCreateSenderProfileOpen}
        handleClose={handleCreateSenderProfileClose}
        operatorId={currentOperator?.id}
      />

      <Dialog
        open={isConfirmWindowOpen}
        onClose={handleConfirmWindowClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{deleteWhat}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmWindowClose}>Disagree</Button>
          <Button onClick={agreeFunction} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isErrorWindowOpen}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ERROR</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Card sx={{ p: 2 }}>
        <Grid
          container
          spacing={2}
          sx={{
            p: 2,
            display: 'flex',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                borderRadius: '10%',
                overflow: 'hidden',
                width: 80,
                height: 80,
              }}
            >
              <Img src={['https://placehold.co/80']} width={80} height={80} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Typography variant="h5">{operator?.title}</Typography>
          </Grid>
        </Grid>
      </Card>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} mb={1}>
                <Typography variant="h6">Sender Profiles</Typography>
              </Grid>
              {operator?.senderProfiles?.items?.map((senderProfile) => (
                <Grid container alignItems="center" key={senderProfile?.id}>
                  <Grid item xs={10} p={2}>
                    <Typography variant="body1">
                      {senderProfile?.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <IconButton
                      aria-label="delete"
                      onClick={() => deleteSenderProfile(senderProfile?.id)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12} mt={1}>
                <Button
                  variant="contained"
                  onClick={handleCreateSenderProfileOpen}
                  sx={{ width: '100%' }}
                >
                  <ControlPoint />
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} mb={1}>
                <Typography variant="h6">Branded Domains</Typography>
              </Grid>
              {operator?.domains?.map((domain) => (
                <Grid container alignItems="center" key={domain?.id}>
                  <Grid item xs={10} p={2}>
                    <Typography variant="body1">
                      {domain?.dnsName || 'Unknown Domain'}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <IconButton
                      aria-label="delete"
                      onClick={() => domain?.id && deleteDomain(domain.id)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12} mt={1}>
                <Button
                  variant="contained"
                  onClick={handleCreateDomainOpen}
                  sx={{ width: '100%' }}
                >
                  <ControlPoint />
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} mb={1}>
                <Typography variant="h6">Connections</Typography>
              </Grid>
              {operator?.connections?.map((connection) => (
                <Grid container alignItems="center" key={connection?.id}>
                  <Grid item xs={10} p={2}>
                    <Typography variant="body1">{connection?.label}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        connection && terminateConnection(connection.id)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12} mt={1}>
                <Button
                  variant="contained"
                  onClick={handleCreateConnectionOpen}
                  sx={{ width: '100%' }}
                >
                  <ControlPoint />
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Navigation>
  );
}
