import React, { FC, ReactNode } from "react";
import { Drawer, Box } from "@mui/material";
import { globalThemeConfig } from "../../../../../../styles/theme";

interface DesktopDrawerProps {
  open?: boolean;
  children: ReactNode | ReactNode[];
}

const DesktopDrawer: FC<DesktopDrawerProps> = ({
  open = false,
  children,
}) => {
  return (
    <Box sx={{ display: { xs: "none", lg: "block" } }}>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
          "& > .MuiDrawer-paper": {
            bgcolor: "background.default",
            border: "none",
            width: `${globalThemeConfig.drawerWidth}px`,
          },
        }}
      >
        {children}
      </Drawer>
    </Box>
  );
};

export default DesktopDrawer;
