import { FormControl, FormHelperText } from '@mui/material';

interface HiddenInputProps {
  value: string[] | null;
  onChange: any;
  disabled?: boolean;
  error?: string;
}

const HiddenInput: React.FC<HiddenInputProps> = ({
  value,
  disabled,
  error,
}) => {
  return (
    <FormControl fullWidth disabled={disabled} error={error ? true : false}>
      <input
        type="hidden"
        value={value && value.length === 1 ? value[0] : ''}
      />

      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default HiddenInput;
