import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

const SetExcludeIcon: React.FC<SvgIconProps> = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg
        fill="none"
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 2C3.11929 2 2 3.11929 2 4.5V10.5L2.0002 10.5319L2.00063 10.5567C2.01149 11.0447 2.1622 11.4982 2.41426 11.8788L2.42696 11.8978C2.61097 12.1701 2.84725 12.4043 3.12139 12.5859C3.4646 12.8132 3.86716 12.958 4.30086 12.9922L4.30765 12.9927C4.37113 12.9975 4.43528 13 4.5 13H7V15.5C7 16.8807 8.11929 18 9.5 18H15.5C16.8807 18 18 16.8807 18 15.5V9.5C18 8.11929 16.8807 7 15.5 7H13V4.5C13 4.43296 12.9974 4.36653 12.9922 4.30082C12.958 3.86713 12.8131 3.46457 12.5858 3.12136L12.573 3.10223C12.3891 2.8299 12.1528 2.59578 11.8788 2.41424C11.4982 2.16218 11.0447 2.01148 10.5567 2.00063C10.5426 2.00032 10.5285 2.00012 10.5144 2.00004L10.5 2H4.5ZM5.14309 3L3 5.1431V4.5C3 3.67157 3.67157 3 4.5 3H5.14309ZM3 6.55732L6.5573 3H8.14309L3 8.14312V6.55732ZM9.5573 3H10.5C10.7316 3 10.9509 3.05249 11.1468 3.14622L3.14623 11.1468C3.05249 10.951 3 10.7316 3 10.5V9.55733L9.5573 3ZM11.8538 3.85336C11.9475 4.04916 12 4.26845 12 4.5V5.293L10.293 7H9.5C9.1439 7 8.80519 7.07445 8.49858 7.20864L11.8538 3.85336ZM7.20864 8.49859C7.07445 8.80519 7 9.1439 7 9.5V10.293L5.29304 12H4.5C4.26846 12 4.04918 11.9475 3.85339 11.8539L7.20864 8.49859ZM8 9.5C8 8.67157 8.67157 8 9.5 8H15.5C16.3284 8 17 8.67157 17 9.5V15.5C17 16.3284 16.3284 17 15.5 17H9.5C8.67157 17 8 16.3284 8 15.5V9.5Z"
          fill={theme.palette.primary.main}
        />
      </svg>
    </SvgIcon>
  );
};

export default SetExcludeIcon;
