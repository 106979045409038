import { Paper, Tooltip, Typography } from '@mui/material';
import { memo } from 'react';
import { Handle, Position } from 'reactflow';

export interface ConditionDataProps {
  attributeName: string;
  attributeValues: string[];
  relationText: string;
  isFirst?: boolean;
  isLast?: boolean;
  hasPrecondition?: boolean;
}

interface ConditionNodeProps {
  data: ConditionDataProps;
  isConnectable: boolean;
  type: string;
}

const ConditionNode = memo(({ data, type }: ConditionNodeProps) => {
  const textNode: React.ReactNode = (
    <>
      {data.attributeName} <strong>{data.relationText}</strong>{' '}
      {data.attributeValues.join(', ')}
    </>
  );
  return (
    <Paper elevation={3} sx={{ p: 1, maxWidth: 310 }}>
      {type === 'preconditionNode' && (
        <Handle
          type="target"
          position={Position.Left}
          id="leftTarget"
          style={{ background: '#555' }}
          isConnectable={false}
        />
      )}
      {type === 'conditionNode' && (
        <>
          {!data.isFirst && (
            <Handle
              type="target"
              position={Position.Top}
              id="topTarget"
              style={{ background: '#555', marginLeft: 10 }}
              isConnectable={false}
            />
          )}
          {!data.isLast && (
            <Handle
              type="source"
              position={Position.Bottom}
              id="bottomSource"
              style={{ background: '#555', marginLeft: -10 }}
              isConnectable={false}
            />
          )}
          {data.hasPrecondition && (
            <Handle
              type="source"
              position={Position.Right}
              id="rightSource"
              style={{ background: '#555' }}
              isConnectable={false}
            />
          )}
        </>
      )}
      <Tooltip title={textNode}>
        <Typography noWrap style={{ fontSize: 10 }}>
          {textNode}
        </Typography>
      </Tooltip>
    </Paper>
  );
});

export default ConditionNode;
