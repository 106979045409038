import { Add, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  Paper,
  Stack,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import {
  RuleInput,
  RuleSetInput,
  SegmentInput,
} from '../../../../types/segment.types';
import LogicalOperatorTBG from '../components/LogicalOperatorTBG';
import SetOperatorTBG from '../components/SetOperatorTBG';
import Rule from './Rule';

export interface RuleSetFormProps {
  path: string;
  value: RuleSetInput;
  isFirst?: boolean;
  handleDelete: any;
  hideDelete?: boolean;
  errors?: FormikErrors<RuleSetInput> | string;
}

const RuleSetForm: React.FC<RuleSetFormProps> = ({
  value,
  path,
  isFirst,
  handleDelete,
  hideDelete,
  errors,
}) => {
  const isSmallerThanMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const [focused, setFocused] = useState<boolean>(false);

  const { setFieldValue, handleChange, validateForm } =
    useFormikContext<SegmentInput>();

  const handleAddRule = (): void => {
    const rule: RuleInput = {
      key: uuidv4(),
      logic: 'AND',
      conditions: [
        {
          key: uuidv4(),
          attributeId: null,
          relation: null,
          values: null,
          negate: false,
          preconditions: null,
        },
      ],
    };
    validateForm().then((v) => {
      if (!v.ruleSets) {
        if (value.rules.length === 1) {
          setFieldValue(`${path}.logic`, null);
        }
        setFieldValue(`${path}.rules`, [...value.rules, rule]);
      }
    });
  };

  const handleRemoveRule = (key: string): void => {
    if (value.rules.length === 2) {
      setFieldValue(`${path}.logic`, 'AND');
    }
    setFieldValue(`${path}.rules`, [
      ...value.rules.filter((rule: RuleInput) => rule.key !== key),
    ]);
  };

  const formikErrors =
    errors && typeof errors !== 'string'
      ? (errors as FormikErrors<RuleSetInput>)
      : undefined;
  //const errorText = errors && typeof errors === 'string' ? errors : undefined;

  return (
    <Paper elevation={focused ? 10 : 3} sx={{ m: 1, width: '100%' }}>
      <Stack>
        <Box
          sx={{
            mt: -2.5,
            ml: 2.5,
          }}
        >
          <SetOperatorTBG
            hideText={isSmallerThanMd}
            value={value.set}
            onChange={handleChange(`${path}.set`)}
            values={isFirst ? ['UNION'] : undefined}
            error={formikErrors?.set}
          />
        </Box>

        <Stack alignItems={'center'} direction="row">
          <Box
            sx={{
              ml: -2.6,
            }}
          >
            <LogicalOperatorTBG
              tbgProps={{ orientation: 'vertical' }}
              hideText={isSmallerThanMd}
              value={value.logic}
              onChange={handleChange(`${path}.logic`)}
              values={value.rules.length < 2 ? ['AND'] : undefined}
              error={formikErrors?.logic}
            />
          </Box>
          <List sx={{ width: '100%', pt: 3, pl: 1 }}>
            <TransitionGroup>
              {value.rules.map((rule: RuleInput, index: number) => (
                <Collapse key={rule.key} timeout={300}>
                  <ListItem sx={{ pr: 0, pb: 0, mb: 0 }}>
                    <Rule
                      handleDelete={() => {
                        handleRemoveRule(rule.key);
                      }}
                      path={`${path}.rules.${index}`}
                      value={rule}
                      hideDelete={value.rules.length < 2}
                      errors={formikErrors?.rules?.[index]}
                    ></Rule>
                  </ListItem>
                </Collapse>
              ))}
            </TransitionGroup>
            <Box sx={{ mt: 0, ml: isSmallerThanMd ? -2 : -5 }}>
              <Button size="small" onClick={handleAddRule} startIcon={<Add />}>
                Add Rule
              </Button>
            </Box>
          </List>
          <Box
            sx={{
              visibility: hideDelete ? 'hidden' : 'visible',
              mr: -2.3,
              ml: 5,
              mt: -3,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                display: 'inline-flex',
                zIndex: 2,
              }}
            >
              <ToggleButtonGroup color="primary" size="small">
                <ToggleButton
                  onMouseEnter={() => setFocused(true)}
                  onMouseLeave={() => setFocused(false)}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  onClick={handleDelete}
                  value=""
                  color="primary"
                >
                  <Delete fontSize="small" color="error" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default RuleSetForm;
