import { gql } from "@apollo/client";

export const GET_CUSTOMERS = gql`
  query GetCustomers($operatorId: ID!, $nextToken: ID) {
    operator(id: $operatorId) {
      customers(nextToken: $nextToken) {
      items {
          first_name
          last_name
          city
          date_of_birth
          email_address
          mobile_number
          time_zone
          country_code
          post_code
          sign_up_date
          tags
        }
        nextToken
      }
    }
  }
`;
