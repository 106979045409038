import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import React from 'react';
import {
  ExecutionSchedulingTypeMapping,
  MessageTypeMapping,
} from '../../../mappings';
import {
  ExecutionSchedulingType,
  MessageType,
} from '../../../types/campaign.types';

interface CampaignTypeAndTitleProps {
  errors: FormikErrors<{
    title: string;
    schedulingType: string;
    campaignType: string;
  }>;
  values: FormikValues;
  handleChange: any;
}

const CampaignTypeAndTitle: React.FC<CampaignTypeAndTitleProps> = ({
  errors,
  values,
  handleChange,
}) => {
  return (
    <Grid
      container
      spacing={3}
      sx={{
        py: {
          md: 5,
          xs: 2,
        },
      }}
    >
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="campaign-type-label">Type</InputLabel>
          <Select
            disabled={values.campaignTypeDisabled}
            fullWidth
            labelId="campaign-type-label"
            id="campaign-type"
            value={values.campaignType}
            onChange={handleChange('campaignType')}
            error={!!errors.campaignType}
            label="Type"
          >
            {Object.values(MessageType).map((type) => (
              <MenuItem key={type} value={type}>
                {MessageTypeMapping[type]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            label="Title"
            variant="standard"
            error={!!errors.title}
            helperText={errors.title}
            value={values.title}
            onChange={handleChange('title')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="campaign-scheduling-type-label">
            Scheduling Type
          </InputLabel>
          <Select
            disabled={values.schedulingTypeDisabled}
            fullWidth
            labelId="campaign-scheduling-type-label"
            id="type"
            value={values.schedulingType}
            onChange={handleChange('schedulingType')}
            error={!!errors.schedulingType}
            label="Scheduling Type"
            displayEmpty
          >
            {Object.values(ExecutionSchedulingType).map((type) => (
              <MenuItem key={type} value={type}>
                {ExecutionSchedulingTypeMapping[type]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CampaignTypeAndTitle;
