import { gql, useLazyQuery } from '@apollo/client';
import { Download } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';

interface DownloadFilteredCustomersProps {
  variables: FilteredCustomersCSVQueryVariables;
}

interface FilteredCustomersCSVQueryVariables {
  operatorId: string;
  campaignId: string;
  executionId: string;
}

type FilteredCustomersCSVQueryResponse = {
  operator: {
    campaign: {
      execution: {
        filteredCustomersS3RefUrl: string;
      };
    };
  };
};

export const GET_FILTERED_CUSTOMERS_S3REFURL = gql`
  query GetFilteredCustomersS3RefUrl(
    $operatorId: ID!
    $campaignId: ID!
    $executionId: ID!
  ) {
    operator(id: $operatorId) {
      campaign(id: $campaignId) {
        execution(id: $executionId) {
          filteredCustomersS3RefUrl
        }
      }
    }
  }
`;

const DownloadFilteredCustomers: React.FC<DownloadFilteredCustomersProps> = ({
  variables,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [executeQuery, { loading, error }] =
    useLazyQuery<FilteredCustomersCSVQueryResponse>(
      GET_FILTERED_CUSTOMERS_S3REFURL,
      {
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
          if (data?.operator?.campaign?.execution) {
            const { filteredCustomersS3RefUrl } =
              data.operator.campaign.execution;
            if (filteredCustomersS3RefUrl) {
              const link = document.createElement('a');
              link.setAttribute('href', filteredCustomersS3RefUrl);
              link.setAttribute('download', 'filtered_customers.csv');
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setDownloading(false);
            } else {
              console.error('filteredCustomersS3RefUrl is null or undefined');
            }
          }
        },
      },
    );

  const handleDownload = () => {
    if (!downloading) {
      setDownloading(true);
      executeQuery({ variables, fetchPolicy: 'no-cache' });
    }
  };

  return (
    <Button
      fullWidth
      color="info"
      variant="outlined"
      onClick={handleDownload}
      disabled={loading || error !== undefined || downloading}
      startIcon={downloading ? <CircularProgress size={24} /> : <Download />}
    >
      Download Filtered Customers
    </Button>
  );
};

export default DownloadFilteredCustomers;
