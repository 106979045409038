import { useQuery, gql } from '@apollo/client';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import useOperator from '../../../../../../../hooks/useOperator';
import {
  OperatorsQueryResponse,
  OperatorsQueryVariables,
} from '../../../../../../../types/operator.types';

const GET_OPERATORS = gql`
  query GetOperators {
    operators {
      items {
        id
        title
        timezone
      }
    }
  }
`;

const OperatorSelect = () => {
  const { error, data } = useQuery<
    OperatorsQueryResponse,
    OperatorsQueryVariables
  >(GET_OPERATORS);
  const operators = data?.operators.items;

  const [operator, { setOperator }] = useOperator();

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!operators) {
    return null;
  }

  const handleOperatorChange = (e: SelectChangeEvent<string>) => {
    setOperator(e.target.value);
  };

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="select-operator">Operator</InputLabel>
      <Select
        labelId="select-operator"
        id="select-operator"
        label="Operator"
        value={operator!.id}
        defaultValue=""
        onChange={handleOperatorChange}
      >
        {operators ? (
          operators.map((operator) => (
            <MenuItem key={operator.id} value={operator.id}>
              {operator.title}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={operator?.id} value={operator?.id}>
            {operator?.title}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default OperatorSelect;
