import AccountIcon from '@mui/icons-material/AccountCircleRounded';
import { Box, Button, Skeleton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { AuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';

interface Props {
  user: AuthenticatedUser | null;
  isLoading: boolean;
  hasError: boolean;
}

const UserProfileButton = ({ user, isLoading, hasError }: Props) => {
  const [t] = useTranslation();

  let displayUser;

  if (isLoading) {
    displayUser = <Skeleton variant="text" width={100} />;
  } else if (hasError) {
    displayUser = 'Error';
  } else if (user) {
    displayUser = user.name;
  }

  return (
    <Tooltip disableInteractive title={t('My account')}>
      <Button
        component={RouterLink}
        to="/profile"
        color="inherit"
        variant="text"
        startIcon={<AccountIcon />}
        sx={{
          borderRadius: 32,
          padding: '8px 16px',
        }}
      >
        <Box
          component="span"
          sx={{
            textTransform: 'capitalize',
            display: { xs: 'none', lg: 'flex' },
          }}
        >
          {displayUser}
        </Box>
      </Button>
    </Tooltip>
  );
};

export default UserProfileButton;
