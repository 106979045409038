import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        common: require("./locales/en/common.json"),
        signIn: require("./locales/en/signIn.json"),
      },
      tr: {
        common: require("./locales/tr/common.json"),
        signIn: require("./locales/tr/signIn.json"),
      },
    },
    fallbackLng: "en",
    ns: ["common", "signIn"],
    defaultNS: "common",
    nsSeparator: "::",
    keySeparator: "::",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
