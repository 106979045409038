import { gql, useQuery } from '@apollo/client';
import { FileOpen } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import StarterKit from '@tiptap/starter-kit';
import { FormikErrors, FormikValues } from 'formik';
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditor,
  type RichTextEditorRef,
} from 'mui-tiptap';
import React, { useRef, useState } from 'react';
import useOperator from '../../../hooks/useOperator';
import { ContentKindMapping } from '../../../mappings';
import { SenderProfilesQueryResponse } from '../../../types/senderProfiles.types';
import { ContentKind } from '../../../types/template.types';
import { PlainLoading } from '../../Loading';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface UploadTemplateProps {
  errors: FormikErrors<{
    template: string;
    title: string;
    description: string;
    subject: string;
    senderProfileId: string;
  }>;
  values: FormikValues;
  handleChange: any;
}

const UploadTemplate: React.FC<UploadTemplateProps> = (
  props: UploadTemplateProps,
) => {
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = React.useState('');

  const deselectFile = () => {
    setSelectedFileName('');
    props.values.template = '';
  };

  const rteRef = useRef<RichTextEditorRef>(null);
  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setSelectedFileName(file.name);
    setFileUploadLoading(true);
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = (readerEvent) => {
      const content = readerEvent.target?.result;

      props.values.template = content as string;

      setFileUploadLoading(false);
    };
  };

  const [operator] = useOperator();

  const { loading, error, data } = useQuery<SenderProfilesQueryResponse>(
    gql`
      query Connections($operatorId: ID!) {
        operator(id: $operatorId) {
          senderProfiles {
            items {
              id
              title
              kind
              emailProfile {
                fromAddress
                fromName
              }
              smsProfile {
                sender
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        operatorId: operator?.id || 0,
      },
    },
  );

  const senderProfiles =
    data?.operator.senderProfiles.items.filter(
      (sp) => sp.kind === props.values.templateKind,
    ) || [];
  const [isLoading, setIsLoading] = React.useState(loading);

  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  if (isLoading) {
    return <PlainLoading />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Grid
      container
      sx={{
        pr: 2,
        pl: 2,
        py: {
          md: 5,
          xs: 2,
        },
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{
                justifyContent: {
                  xs: 'center',
                  md: 'flex-start',
                },
              }}
            >
              <Typography variant="h5">
                Create {ContentKindMapping[props.values.templateKind]} Template
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{
                justifyContent: {
                  xs: 'center',
                  md: 'flex-end',
                },
              }}
            >
              {selectedFileName === '' ? (
                <LoadingButton
                  component="label"
                  variant="contained"
                  startIcon={<FileOpen />}
                  color="secondary"
                  loading={fileUploadLoading}
                  loadingPosition="start"
                >
                  Select Template File
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileSelect}
                  />
                </LoadingButton>
              ) : (
                <Chip
                  onDelete={deselectFile}
                  label={selectedFileName}
                  size="medium"
                  color="secondary"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Divider></Divider>
      </Grid>
      <Grid item xs={12} mt={3}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            label="Template Title"
            variant="standard"
            error={!!props.errors.title}
            helperText={props.errors.title}
            value={props.values.title}
            onChange={props.handleChange('title')}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} mt={3}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            label="Template Description"
            variant="standard"
            error={!!props.errors.description}
            helperText={props.errors.description}
            value={props.values.description}
            onChange={props.handleChange('description')}
          />
        </FormControl>
      </Grid>
      {props.values.templateKind === ContentKind.EMAIL && (
        <Grid item xs={12} mt={3}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              label="Subject"
              variant="standard"
              error={!!props.errors.subject}
              helperText={props.errors.subject}
              value={props.values.subject}
              onChange={props.handleChange('subject')}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Grid>
      )}
      {props.values.templateKind !== ContentKind.WEB_PUSH && (
        <Grid item xs={12} mt={3}>
          <FormControl fullWidth>
            <InputLabel id="senderProfile-label">Sender Profile</InputLabel>
            <Select
              fullWidth
              labelId="senderProfile-label"
              id="senderProfileId"
              value={props.values.senderProfileId}
              onChange={(e) => {
                props.values.senderProfileId = e.target.value;
                props.handleChange('senderProfileId')(e);
              }}
              error={!!props.errors.senderProfileId}
              label="Sender Profile"
              displayEmpty
            >
              {senderProfiles?.map((sp) => (
                <MenuItem key={sp.id} value={sp.id}>
                  {sp.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} mt={2}>
        <Box>
          <>
            {selectedFileName !== '' ? (
              <Box
                sx={{
                  maxHeight: '30vh',
                  overflow: 'auto',
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: props.values.template }}
                ></div>
              </Box>
            ) : (
              <RichTextEditor
                editorDependencies={[props.values.template]}
                content={props.values.template}
                ref={rteRef}
                extensions={[StarterKit]}
                editable={true}
                renderControls={() => (
                  <MenuControlsContainer>
                    <MenuSelectHeading />
                    <MenuDivider />
                    <MenuButtonBold />
                    <MenuButtonItalic />
                  </MenuControlsContainer>
                )}
              />
            )}
          </>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UploadTemplate;
