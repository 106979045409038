import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
  createFilterOptions,
  darken,
  lighten,
  styled,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { Attribute } from '../../../../types/segment.types';

interface AttributeSelectProps {
  value: string | null;
  onChange: any;
  inputLabel: string;
  attributes: _.Dictionary<
    Attribute & {
      category: string;
      label: string;
    }
  >;
  grouped?: boolean;
  error?: string;
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.secondary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.secondary.light, 0.9)
      : darken(theme.palette.secondary.main, 0.9),
}));

const GroupItems = styled('ul')({
  padding: 1,
});

const AttributeSelect: React.FC<AttributeSelectProps> = ({
  value,
  onChange,
  attributes,
  inputLabel,
  grouped,
  error,
}) => {
  const [inputValue, setInputValue] = useState(attributes[value || '']?.title);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (o: string) => attributes[o].label,
  });

  return (
    <FormControl fullWidth error={error ? true : false}>
      <Autocomplete
        size="small"
        fullWidth
        options={_.keys(attributes)}
        value={value}
        onChange={(__, newValue) => {
          if (newValue !== null) {
            setInputValue(attributes[newValue]?.title);
            onChange(newValue);
          }
        }}
        inputValue={inputValue || ''}
        getOptionLabel={(id) => attributes[id]?.title}
        autoComplete
        onInputChange={(__, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        filterOptions={filterOptions}
        groupBy={
          grouped ? (option) => attributes[option || '']?.category : undefined
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              grouped
                ? attributes[value || '']?.category || inputLabel
                : inputLabel
            }
          />
        )}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default AttributeSelect;
