import { FormControl, FormHelperText, TextField } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ChangeEvent } from 'react';
import { AttributeDataType } from '../../../../../../types/segment.types';

interface SingleInputProps {
  value: string[] | null;
  onChange: any;
  disabled?: boolean;
  dataType: AttributeDataType;
  error?: string;
}

const SingleInput: React.FC<SingleInputProps> = ({
  onChange,
  value,
  disabled,
  dataType,
  error,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      target: {
        value: e.target.value !== null ? [e.target.value] : null,
      },
    });
  };

  return (
    <FormControl fullWidth error={error ? true : false}>
      {dataType === 'DATETIME' ? (
        <DateTimePicker
          timeSteps={{ minutes: 1 }}
          value={value && value.length === 1 ? dayjs(value[0]) : null}
          onChange={(newValue) => {
            onChange({
              target: {
                value:
                  newValue !== null ? [dayjs(newValue).toISOString()] : null,
              },
            });
          }}
          slotProps={{
            textField: {
              size: 'small',
              variant: 'standard',
              fullWidth: true,
              sx: { m: 0, mt: 1, px: 1.5 },
              InputProps: { disableUnderline: true },
            },
          }}
        />
      ) : dataType === 'DATE' ? (
        <DatePicker
          value={value && value.length === 1 ? dayjs(value[0]) : null}
          onChange={(newValue) => {
            onChange({
              target: {
                value:
                  newValue !== null
                    ? [dayjs(newValue).format('YYYY-MM-DD')]
                    : null,
              },
            });
          }}
          slotProps={{
            textField: {
              size: 'small',
              variant: 'standard',
              fullWidth: true,
              sx: { m: 0, mt: 1, px: 1.5 },
              InputProps: { disableUnderline: true },
            },
          }}
        />
      ) : (
        <TextField
          fullWidth
          size="small"
          placeholder="Enter value"
          variant="outlined"
          onChange={handleChange}
          disabled={disabled}
          value={value && value.length === 1 ? value[0] : ''}
          type={
            _.includes(['INTEGER', 'DOUBLE', 'MONEY'], dataType)
              ? 'number'
              : 'text'
          }
        />
      )}
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default SingleInput;
