import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedUser from '../../hooks/useAuthenticatedUser';

interface Props {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { user } = useAuthenticatedUser();

  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => setIsAuthenticated(true))
      .catch(() => navigate('/signin'));
  }, [navigate]);

  return isAuthenticated && user ? <>{children}</> : null;
};

export default PrivateRoute;
