import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

interface CreateSenderProfileProps {
  isOpen: boolean;
  handleClose: () => void;
  operatorId?: string;
}

const CREATE_EMAIL_SENDER_PROFILE = gql`
  mutation CreateEmailSenderProfile($input: CreateEmailSenderProfileInput!) {
    createEmailSenderProfile(input: $input) {
      id
    }
  }
`;

const CREATE_SMS_SENDER_PROFILE = gql`
  mutation CreateSMSSenderProfile($input: CreateSMSSenderProfileInput!) {
    createSMSSenderProfile(input: $input) {
      id
    }
  }
`;

const CreateSenderProfile: React.FC<CreateSenderProfileProps> = ({
  isOpen,
  handleClose,
  operatorId,
}) => {
  const [senderType, setSenderType] = useState<'email' | 'sms' | ''>('');
  const [title, setTitle] = useState('');
  const [fromName, setFromName] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [sender, setSender] = useState('');

  const close = () => {
    handleClose();
    setSenderType('');
    setTitle('');
    setFromName('');
    setFromAddress('');
    setSender('');
  };

  const [createEmailSenderProfileMutation] = useMutation(
    CREATE_EMAIL_SENDER_PROFILE,
  );
  const [createSMSSenderProfileMutation] = useMutation(
    CREATE_SMS_SENDER_PROFILE,
  );

  const handleCreateSenderProfile = async () => {
    try {
      if (senderType === 'email') {
        await createEmailSenderProfileMutation({
          variables: {
            input: {
              title,
              fromName,
              fromAddress,
              operatorId: operatorId || '0',
            },
          },
        });
      } else if (senderType === 'sms') {
        await createSMSSenderProfileMutation({
          variables: {
            input: { title, sender, operatorId: operatorId || '0' },
          },
        });
      }

      handleClose();
    } catch (error) {
      console.error('Error creating sender profile:', error);
    }
  };

  return (
    <Dialog open={isOpen} maxWidth={'md'} fullWidth>
      <DialogTitle>Create Sender Profile</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Select a sender type to create Sender Profile.
        </DialogContentText>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel id="sender-type-label">Type</InputLabel>
            <Select
              labelId="sender-type-label"
              id="senderType"
              value={senderType}
              onChange={(e) =>
                setSenderType(e.target.value as 'email' | 'sms' | '')
              }
              label="Type"
              fullWidth
              sx={{ mb: 2 }}
            >
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="sms">SMS</MenuItem>
            </Select>
          </FormControl>

          {senderType === 'email' && (
            <>
              <TextField
                label="Title"
                variant="standard"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                sx={{ mb: 2 }}
                fullWidth
              />
              <TextField
                label="From Name"
                variant="standard"
                value={fromName}
                onChange={(e) => setFromName(e.target.value)}
                sx={{ mb: 2 }}
                fullWidth
              />
              <TextField
                label="From Address"
                variant="standard"
                value={fromAddress}
                sx={{ mb: 2 }}
                onChange={(e) => setFromAddress(e.target.value)}
                fullWidth
              />
            </>
          )}
          {senderType === 'sms' && (
            <>
              <TextField
                label="Title"
                variant="standard"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Sender"
                variant="standard"
                value={sender}
                onChange={(e) => setSender(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={handleCreateSenderProfile}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSenderProfile;
