import { gql } from '@apollo/client';

export const GET_CONNECTIONS = gql`
  query GetConnections($operatorId: ID!) {
    operator(id: $operatorId) {
      connections {
        id
        label
        connectorKind
      }
    }
  }
`;