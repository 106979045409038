import { gql, useQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Dialog,
  Fab,
  Grid,
  LinearProgress,
  TablePagination,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Navigation from '../../../components/Navigation/Navigation';
import SegmentsBanner from '../../../components/Segment/SegmentBanner';
import SegmentCard from '../../../components/Segment/SegmentCard';
import SegmentEditor from '../../../components/Segment/SegmentEditor';
import useOperator from '../../../hooks/useOperator';
import { Segment } from '../../../types/segment.types';

interface ListSegmentsQueryVariables {
  operatorId: string;
  nextToken?: string;
}

type ListSegmentsQueryResponse = {
  operator: {
    segmentsCount: number;
    customersCount: number;
    segments: {
      items: Segment[];
      nextToken?: string;
    };
  };
};

const LIST_SEGMENTS = gql`
  query ListSegments($operatorId: ID!, $nextToken: ID) {
    operator(id: $operatorId) {
      segmentsCount
      customersCount
      segments(nextToken: $nextToken) {
        items {
          id
          title
          description
          createTime
          customersCount
          customersCountUpdateTime
        }
        nextToken
      }
    }
  }
`;

const SegmentsList: React.FC = () => {
  const [operator] = useOperator();

  const [currentPage, setCurrentPage] = useState(0);
  const [pageTokens, setPageTokens] = useState<Map<number, string | null>>(
    new Map([[0, null]]),
  );

  const [createSegmentDialogOpen, setCreateSegmentDialogOpen] = useState(false);

  const handleCreateSegment = () => {
    setCreateSegmentDialogOpen(true);
  };

  const handleCreateSegmentDialogClose = () => {
    setCreateSegmentDialogOpen(false);
  };

  const { loading, error, data, fetchMore, refetch } = useQuery<
    ListSegmentsQueryResponse,
    ListSegmentsQueryVariables
  >(LIST_SEGMENTS, {
    variables: { operatorId: operator!.id },
    notifyOnNetworkStatusChange: true,
  });

  const segments = data?.operator.segments.items || [];
  const segmentsCount = data?.operator.segmentsCount || 0;

  useEffect(() => {
    refetch();
    setCurrentPage(0);
    setPageTokens(new Map([[0, null]]));
  }, [operator, refetch]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    if (loading || !data || page < 0 || page > pageTokens.size) {
      return;
    }

    var token: string | null = null;

    if (pageTokens.has(page)) {
      token = pageTokens.get(page)!;
    } else {
      token = data?.operator.segments.nextToken || null;
      setPageTokens((prev) => new Map(prev.set(page, token)));
    }

    setCurrentPage(page);

    fetchMore({
      variables: {
        nextToken: token,
      },
    });
  };

  const page = currentPage > segmentsCount / 25 ? 0 : currentPage;

  return (
    <Navigation>
      <SegmentsBanner />
      <Dialog fullWidth maxWidth="lg" open={createSegmentDialogOpen}>
        <SegmentEditor
          onCancel={handleCreateSegmentDialogClose}
          onSuccess={() => {
            refetch().then(() => {
              setCurrentPage(0);
              setPageTokens(new Map([[0, null]]));
            });
            handleCreateSegmentDialogClose();
          }}
        />
      </Dialog>
      <TablePagination
        component="div"
        count={segmentsCount}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={25}
        rowsPerPageOptions={[25]}
        nextIconButtonProps={{
          disabled:
            loading || segmentsCount < 25 || page === segmentsCount / 25 - 1,
        }}
        backIconButtonProps={{ disabled: loading || page === 0 }}
      />
      {loading ? <LinearProgress /> : null}
      {error ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </Alert>
      ) : null}
      {segments ? (
        <Grid container spacing={2}>
          {segments.map((segment) => (
            <Grid item key={segment.id} xs={12} sm={12} md={6} lg={6} xl={4}>
              <SegmentCard segment={segment} />
            </Grid>
          ))}
        </Grid>
      ) : null}
      <Tooltip title={`Create New Segment`}>
        <Fab
          style={{
            margin: '0px',
            top: 'auto',
            right: '20px',
            bottom: '20px',
            left: 'auto',
            position: 'fixed',
          }}
          onClick={handleCreateSegment}
          color="primary"
          aria-label="add"
        >
          <Add />
        </Fab>
      </Tooltip>
    </Navigation>
  );
};

export default SegmentsList;
