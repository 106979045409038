import React from 'react'
import SignInForm from './components/SignInForm/SignInForm';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactComponent as Logo } from '../../assets/branding/logo-text-light.svg';
import { ReactComponent as Welcome1 } from '../../assets/illustrations/welcome-1-light.svg';
import { useTranslation } from 'react-i18next';
import { signIn } from './actions';
import { SignInFormValues } from './types';
import { useNavigate } from 'react-router-dom';


export default function SignIn() {
  const [t] = useTranslation("signIn");
  const [isLoading, setLoading] = React.useState(false);
  const [hasError, setError] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async ({ email, password }: SignInFormValues) => {
    setLoading(true);
    const response = await signIn({ username: email, password: password });
    if (response.hasError) {
      setError(true);
    } else {
      setError(false);
      navigate('/dashboard');
    }
    setLoading(false);
  }

  return (
    <Grid container sx={{
      display: 'flex',
      direction: 'column',
      width: '100vw',
      height: '100vh',
    }} data-testid="SignIn-page">
      {/* Side banner */}
      <Grid md={4} sx={{
        display: { xs: 'none', sm: 'none', md: 'flex' },
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: "var(--action-hover, rgba(0, 0, 0, 0.04))",
        p: 8
      }}
        data-testid="side-banner">
        <Box>
          <Logo />
          <Box sx={{ mt: 10 }}>
            <Typography variant="h4">
              {t("SIDE_BANNER::TITLE")}
            </Typography>
            <Typography variant="h6" color="GrayText" sx={{ mt: 2 }}>
              {t("SIDE_BANNER::SUBTITLE", { appName: "Clicktelligence" })}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Welcome1 width="100%" />
        </Box>
      </Grid>
      {/* Form */}
      <Grid xs={12} sm={12} md={8}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 10, position: "absolute", right: 0 }}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            {t("REQUEST_DEMO::TEXT")}
          </Typography>
          <Button variant="text" sx={{ p: 0, m: 0 }}>{t("REQUEST_DEMO::BUTTON")}</Button>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", height: "100vh" }}>
          <SignInForm onSubmit={handleSubmit} isLoading={isLoading} hasError={hasError} />
        </Box>
      </Grid>
    </ Grid >
  )
}