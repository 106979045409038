import { gql, useQuery } from '@apollo/client';
import { Cancel } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import React from 'react';
import useOperator from '../../../hooks/useOperator';
import { Segment } from '../../../types/segment.types';
import { PlainLoading } from '../../Loading';

interface SelectSegmentsProps {
  errors: FormikErrors<{
    includedSegments: string;
    excludedSegments: string;
  }>;
  values: FormikValues;
  handleChange: any;
}

export type Segments = {
  id: string;
  title: string;
  description: string;
  createTime: string;
};

export interface SegmentsQueryVariables {
  nextToken?: string | null;
}

export type SegmentsQueryResponse = {
  operator: {
    segments: { items: Segments[]; nextToken?: string | null };
  };
};

export type SegmentQueryResponse = {
  operator: {
    segment: Segment;
  };
};

const SelectSegments: React.FC<SelectSegmentsProps> = ({
  errors,
  values,
  handleChange,
}) => {
  const [operator] = useOperator();

  const { loading, error, data } = useQuery<SegmentsQueryResponse>(
    gql`
      query Segments($operatorId: ID!) {
        operator(id: $operatorId) {
          segments {
            items {
              id
              title
              description
            }
          }
        }
      }
    `,
    {
      variables: {
        operatorId: operator?.id || 0,
      },
    },
  );

  const segments = data?.operator.segments.items;

  const [isLoading, setIsLoading] = React.useState(loading);

  React.useEffect(() => {
    values.segments = segments;
    handleChange('segments')({
      target: { value: segments },
    });
  }, [segments, handleChange, values]);

  const getSegmentTitle = (segmentId: string) => {
    const segment = segments?.find((segment) => segment.id === segmentId);
    return segment?.title;
  };

  const handleIncludedSegmentDelete = (value: string) => {
    const includedSegments = values.includedSegments.filter(
      (segmentId: string) => segmentId !== value,
    );
    handleChange('includedSegments')({
      target: { value: includedSegments },
    });
  };

  const handleExcludedSegmentDelete = (value: string) => {
    const excludedSegments = values.excludedSegments.filter(
      (segmentId: string) => segmentId !== value,
    );
    handleChange('excludedSegments')({
      target: { value: excludedSegments },
    });
  };
  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  if (isLoading) {
    return <PlainLoading />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Grid
      container
      spacing={3}
      sx={{
        py: {
          md: 5,
          xs: 2,
        },
      }}
    >
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="included-segments-label">
            Included Segments
          </InputLabel>
          <Select
            labelId="included-segments-label"
            id="includedSegments"
            value={values.includedSegments}
            onChange={handleChange('includedSegments')}
            renderValue={(selected) =>
              segments && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value: string) => (
                    <Chip
                      key={value}
                      label={getSegmentTitle(value)}
                      deleteIcon={
                        <Cancel
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      onDelete={() => handleIncludedSegmentDelete(value)}
                    />
                  ))}
                </Box>
              )
            }
            error={!!errors.includedSegments}
            label="Included Segments"
            fullWidth
            multiple
          >
            {segments ? (
              segments
                .filter((s) => values.excludedSegments.indexOf(s.id) === -1)
                .map((segment) => (
                  <MenuItem key={segment.id} value={segment.id}>
                    {segment.title}
                  </MenuItem>
                ))
            ) : (
              <em>Loading...</em>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="excluded-segments-label">
            Excluded Segments
          </InputLabel>
          <Select
            labelId="excluded-segments-label"
            id="excludedSegments"
            value={values.excludedSegments}
            onChange={handleChange('excludedSegments')}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: string) => (
                  <Chip
                    key={value}
                    label={getSegmentTitle(value)}
                    deleteIcon={
                      <Cancel
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={() => handleExcludedSegmentDelete(value)}
                  />
                ))}
              </Box>
            )}
            error={!!errors.excludedSegments}
            label="Excluded Segments"
            fullWidth
            multiple
          >
            {segments ? (
              segments
                .filter((s) => values.includedSegments.indexOf(s.id) === -1)
                .map((segment) => (
                  <MenuItem key={segment.id} value={segment.id}>
                    {segment.title}
                  </MenuItem>
                ))
            ) : (
              <em>Loading...</em>
            )}
          </Select>
        </FormControl>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" disabled>
            Upload CSV Far-off
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
export default SelectSegments;
