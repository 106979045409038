import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { CurrentOperator } from '../types/operator.types';

type setCurrentOperatorFunction = (id: string) => void;

const GET_OPERATOR = gql`
  query GetOperator($id: ID!) {
    operator(id: $id) {
      id
      title
      timezone
    }
  }
`;

const usePreviousOperator = (operator?: CurrentOperator) => {
  const ref = useRef<CurrentOperator | undefined>(operator);
  useEffect(() => {
    ref.current = operator;
  });
  return ref.current;
};

const useOperator = (): [
  CurrentOperator | undefined,
  {
    setOperator: setCurrentOperatorFunction;
    prevOperator: CurrentOperator | undefined;
  },
] => {
  const [localOperator, setLocalOperator] = useLocalStorage<
    CurrentOperator | undefined
  >('operator', undefined);

  const [sessionOperator, setSessionOperator] = useSessionStorage<
    CurrentOperator | undefined
  >('operator', undefined);

  const [getOperator] = useLazyQuery<
    { operator: CurrentOperator },
    { id: string }
  >(GET_OPERATOR);

  const previousOperator = usePreviousOperator(
    sessionOperator ? sessionOperator : localOperator,
  );

  const setCurrentOperator: setCurrentOperatorFunction = (id: string) => {
    getOperator({ variables: { id } }).then((res) => {
      setLocalOperator(res.data?.operator);
      setSessionOperator(res.data?.operator);

      var utc = require('dayjs/plugin/utc');
      var timezone = require('dayjs/plugin/timezone');
      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.tz.setDefault(res.data?.operator.timezone);
    });
  };

  return [
    sessionOperator ? sessionOperator : localOperator,
    { setOperator: setCurrentOperator, prevOperator: previousOperator },
  ];
};

export default useOperator;
