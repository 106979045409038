import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

const SetUnionIcon: React.FC<SvgIconProps> = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg
        fill="none"
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 2C3.11929 2 2 3.11929 2 4.5V10.5C2 11.3179 2.39278 12.0441 3 12.5002L3.00421 12.5033C3.04244 12.5319 3.08152 12.5595 3.12139 12.5859C3.4646 12.8132 3.86716 12.958 4.30086 12.9922C4.30312 12.9924 4.30538 12.9925 4.30765 12.9927C4.37113 12.9975 4.43528 13 4.5 13H7V15.5C7 15.7412 7.03416 15.9744 7.09791 16.1951C7.10258 16.2113 7.10741 16.2274 7.11239 16.2434C7.21311 16.5672 7.37788 16.8628 7.59189 17.1154C7.83747 17.4052 8.14788 17.6383 8.50073 17.7923C8.50942 17.7961 8.51814 17.7998 8.52689 17.8035C8.82598 17.93 9.15482 18 9.5 18H15.5C15.5729 18 15.6451 17.9969 15.7164 17.9908C16.9245 17.8872 17.8872 16.9245 17.9908 15.7164C17.991 15.7137 17.9912 15.7109 17.9915 15.7082C17.9971 15.6396 18 15.5701 18 15.5V9.5C18 9.14472 17.9259 8.80674 17.7923 8.50069C17.6382 8.14785 17.4051 7.83744 17.1153 7.59187C17.107 7.58482 17.0986 7.57782 17.0902 7.57088C16.8306 7.35664 16.5271 7.19379 16.1951 7.0979C15.9744 7.03416 15.7412 7 15.5 7H13V4.5C13 4.43296 12.9974 4.36653 12.9922 4.30082C12.958 3.86713 12.8131 3.46457 12.5858 3.12136C12.5844 3.11915 12.5829 3.11693 12.5814 3.11472C12.5554 3.07569 12.5283 3.03744 12.5002 3C12.0441 2.39278 11.3179 2 10.5 2H4.5ZM11.1468 3.14622L3.14623 11.1468C3.05249 10.951 3 10.7316 3 10.5V9.55733L9.5573 3H10.5C10.7316 3 10.9509 3.05249 11.1468 3.14622ZM3.85339 11.8539L11.8538 3.85336C11.9475 4.04916 12 4.26845 12 4.5V5.293L5.29304 12H4.5C4.26846 12 4.04918 11.9475 3.85339 11.8539ZM12 6.70722V7.5C12 7.71443 12.135 7.89734 12.3246 7.96838L7.96838 12.3246C7.89735 12.135 7.71444 12 7.5 12H6.70725L12 6.70722ZM13.7072 8H15.293L8 15.293V13.7072L13.7072 8ZM16.4042 8.30303C16.7173 8.53995 16.9346 8.89693 16.9875 9.30547L9.30551 16.9875C8.89696 16.9346 8.53998 16.7173 8.30305 16.4042L16.4042 8.30303ZM17 10.7072V12.293L12.293 17H10.7072L17 10.7072ZM17 13.7072V15.293L15.293 17H13.7072L17 13.7072ZM3 8.14312V6.55732L6.5573 3H8.14309L3 8.14312ZM3 5.1431V4.5C3 3.67157 3.67157 3 4.5 3H5.14309L3 5.1431Z"
          fill={theme.palette.primary.main}
        />
      </svg>
    </SvgIcon>
  );
};

export default SetUnionIcon;
