import { useQuery } from '@apollo/client';
import {
  BubbleChart,
  CheckCircleOutline,
  MonetizationOn,
  Moving,
  TouchApp,
  WebRounded,
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback } from 'react';
import { ReactComponent as ReportsBannerLeftDarkImage } from '../../assets/illustrations/Reports/reportsBannerDarkLeft.svg';
import { ReactComponent as ReportsBannerRightDarkImage } from '../../assets/illustrations/Reports/reportsBannerDarkRight.svg';
import { ReactComponent as ReportsBannerLeftImage } from '../../assets/illustrations/Reports/reportsBannerLeft.svg';
import { ReactComponent as ReportsBannerRightImage } from '../../assets/illustrations/Reports/reportsBannerRight.svg';
import Banner from '../../components/Banner';
import DashboardKpiCard from '../../components/DashboardKpiCard';
import KpiCard from '../../components/KpiCard';
import Navigation from '../../components/Navigation/Navigation';
import { GET_OPERATOR_STATS } from '../../graphql/local';
import useOperator from '../../hooks/useOperator';
import { DashboardQueryResponse } from '../../types/dashboard.types';

export default function Reports() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [operator] = useOperator();

  const {
    loading,
    error,
    data: operatorStats,
    refetch: totalCustomersCountRefetcher,
  } = useQuery<DashboardQueryResponse>(GET_OPERATOR_STATS, {
    variables: {
      operatorId: operator?.id || 0,
    },
    pollInterval: 15000,
  });

  const handleRefresh = useCallback(() => {
    totalCustomersCountRefetcher();
  }, [totalCustomersCountRefetcher]);

  React.useEffect(() => {
    handleRefresh();
  }, [operator, handleRefresh]);

  const [isLoading, setIsLoading] = React.useState(loading);
  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const customersCount = operatorStats?.operator?.customersCount;

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'campaignName', headerName: 'Campaign name', width: 330 },
    { field: 'processed', headerName: 'Processed', width: 130 },
    { field: 'filtered', headerName: 'Filtered', width: 130 },
    { field: 'queued', headerName: 'Queued', width: 130 },
    { field: 'd2p', headerName: 'D2p', width: 130 },
    { field: 'views', headerName: 'Views', width: 130 },
    { field: 'clicks', headerName: 'Clicks', width: 130 },
    { field: 'logins', headerName: 'Logins', width: 130 },
    { field: 'deposits', headerName: 'Deposits', width: 130 },
    { field: 'sportBets', headerName: 'Sport Bets', width: 130 },
    { field: 'Withdraws', headerName: 'Withdraws', width: 130 },
  ];

  const rows = [
    {
      id: 1,
      campaignName: 'Bonus For VIP Customers',
      processed: '23123',
      filtered: '3432',
      queued: '123',
      d2p: '123',
      views: '123',
      clicks: '123',
      logins: '123',
      deposits: '123',
      sportBets: '123',
      Withdraws: '123',
    },
    {
      id: 2,
      campaignName: 'Bonus For VIP Customers',
      processed: '23123',
      filtered: '3432',
      queued: '123',
      d2p: '123',
      views: '123',
      clicks: '123',
      logins: '123',
      deposits: '123',
      sportBets: '123',
      Withdraws: '123',
    },
    {
      id: 3,
      campaignName: 'Bonus For VIP Customers',
      processed: '23123',
      filtered: '3432',
      queued: '123',
      d2p: '123',
      views: '123',
      clicks: '123',
      logins: '123',
      deposits: '123',
      sportBets: '123',
      Withdraws: '123',
    },
    {
      id: 4,
      campaignName: 'Bonus For VIP Customers',
      processed: '23123',
      filtered: '3432',
      queued: '123',
      d2p: '123',
      views: '123',
      clicks: '123',
      logins: '123',
      deposits: '123',
      sportBets: '123',
      Withdraws: '123',
    },
    {
      id: 5,
      campaignName: 'Bonus For VIP Customers',
      processed: '23123',
      filtered: '3432',
      queued: '123',
      d2p: '123',
      views: '123',
      clicks: '123',
      logins: '123',
      deposits: '123',
      sportBets: '123',
      Withdraws: '123',
    },
  ];

  return (
    <Navigation>
      <Banner
        leftImage={
          isDarkMode ? (
            <ReportsBannerLeftDarkImage />
          ) : (
            <ReportsBannerLeftImage />
          )
        }
        rightImage={
          isDarkMode ? (
            <ReportsBannerRightDarkImage />
          ) : (
            <ReportsBannerRightImage />
          )
        }
        kpiCards={[
          <KpiCard
            icon={<Moving />}
            title="Total Customers"
            value={customersCount?.toLocaleString() || '0'}
          ></KpiCard>,
          <KpiCard
            icon={<MonetizationOn />}
            title="Total Income"
            value="0"
          ></KpiCard>,
          <KpiCard
            icon={<TouchApp />}
            title="Total Clicks"
            value="0"
          ></KpiCard>,
        ]}
      />
      {isLoading && <LinearProgress />}
      {error && (
        <Alert severity="error">
          <AlertTitle>Error fetching reports.</AlertTitle>
          {error.message}
        </Alert>
      )}
      {!isLoading && !error && (
        <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
          <Grid item xs={12}>
            <Box
              sx={{
                p: 2,
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h4" sx={{ mb: 2 }}>
                Reports Coming Soon
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ height: '100%' }}
            className="blur-content"
          >
            <DashboardKpiCard
              icon={<BubbleChart />}
              title="Total Customers"
              value={0}
              expectedValue={0}
              icon1={<CheckCircleOutline />}
              title1="Active Customers"
              value1={0}
            ></DashboardKpiCard>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sx={{ height: '100%' }}
            className="blur-content"
          >
            <DashboardKpiCard
              icon={<BubbleChart />}
              title="Total Segments"
              value={0}
              expectedValue={0}
              icon1={<CheckCircleOutline />}
              title1="Active Segments"
              value1={0}
            ></DashboardKpiCard>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sx={{ height: '100%' }}
            className="blur-content"
          >
            <DashboardKpiCard
              icon={<WebRounded />}
              title="Total Templates"
              value={0}
              expectedValue={0}
              icon1={<CheckCircleOutline />}
              title1="Active Templates"
              value1={0}
            ></DashboardKpiCard>
          </Grid>
          <Grid item xs={12} sx={{ height: '100%' }} className="blur-content">
            <DataGrid
              sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                  pointerEvents: 'none',
                },
              }}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
          </Grid>
        </Grid>
      )}
    </Navigation>
  );
}
