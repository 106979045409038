import { gql, useMutation } from '@apollo/client';
import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import { Connector } from '../../../types/connection.types';

interface Props {
  connectors: Connector[];
  isOpen: boolean;
  handleClose: () => void;
  operatorId?: string;
}

const CreateConnection = ({
  connectors,
  isOpen,
  handleClose,
  operatorId,
}: Props) => {
  const [selectedConnectorId, setSelectedConnectorId] =
    React.useState<string>('');

  const [connectorConfigJSON, setConnectorConfigJSON] = React.useState<{}>({});
  const [creating, setCreating] = React.useState(false);
  const [label, setLabel] = React.useState<string>('');

  const close = () => {
    handleClose();
    setSelectedConnectorId('');
    setConnectorConfigJSON({});
    setCreating(false);
    setLabel('');
  };
  const selectedConnector = connectors.find(
    (connector) => connector.id === selectedConnectorId,
  );

  const CREATE_CONNECTION = gql`
    mutation CreateConnection($input: CreateConnectionInput!) {
      createConnection(input: $input) {
        id
      }
    }
  `;
  const [createConnectionMutation] = useMutation(CREATE_CONNECTION);

  const createConnection = async () => {
    setCreating(true);
    try {
      const data = await createConnectionMutation({
        variables: {
          input: {
            connectorId: selectedConnectorId,
            connectorConfigJSON: JSON.stringify(connectorConfigJSON),
            label: label,
            operatorId: operatorId || 0,
          },
        },
      });

      if (data.data?.createConnection?.id) {
        close();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCreating(false);
    }
  };

  return (
    <Dialog open={isOpen} maxWidth={'md'} fullWidth>
      <DialogTitle> Create Connection </DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Select a connector to create a connection.
        </DialogContentText>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel id="select-connector-label">Connector</InputLabel>
            <Select
              labelId="select-connector-label"
              id="templateId"
              onChange={(e) => {
                setSelectedConnectorId(e.target.value as string);
              }}
              label="Connector"
              fullWidth
              sx={{ mb: 2 }}
            >
              <MenuItem value="">None</MenuItem>
              {connectors.map((connector) => {
                return (
                  <MenuItem key={connector.id} value={connector.id}>
                    {connector.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {selectedConnector && (
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Title"
                placeholder="Title"
                variant="standard"
                value={label}
                required={true}
                sx={{ mb: 2 }}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
            </FormControl>
          )}
          {selectedConnector &&
            selectedConnector.configFields.map((configField) => {
              return (
                configField.kind === 'STRING' && (
                  <FormControl key={configField.name} fullWidth>
                    <TextField
                      fullWidth
                      label={configField.name}
                      placeholder={configField.description}
                      variant="standard"
                      required={configField.required}
                      sx={{ mb: 2 }}
                      onChange={(e) => {
                        setConnectorConfigJSON({
                          ...connectorConfigJSON,
                          [configField.name]: e.target.value,
                        });
                      }}
                    />
                  </FormControl>
                )
              );
            })}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <LoadingButton
          loading={creating}
          loadingPosition="start"
          startIcon={<Add></Add>}
          disabled={creating}
          onClick={createConnection}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateConnection;
