import { useQuery } from '@apollo/client';
import {
  Add,
  AddCircle,
  BubbleChart,
  Event as TotalClicksIcon,
} from '@mui/icons-material';

import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  Fab,
  Grid,
  LinearProgress,
  TablePagination,
  Tooltip,
  useTheme,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { ReactComponent as TemplateBannerLeftDarkImage } from '../../assets/illustrations/Templates/templatesBannerDarkLeft.svg';
import { ReactComponent as TemplateBannerRightDarkImage } from '../../assets/illustrations/Templates/templatesBannerDarkRight.svg';
import { ReactComponent as TemplateBannerLeftImage } from '../../assets/illustrations/Templates/templatesBannerLeft.svg';
import { ReactComponent as TemplateBannerRightImage } from '../../assets/illustrations/Templates/templatesBannerRight.svg';
import Banner from '../../components/Banner';
import KpiCard from '../../components/KpiCard';
import Navigation from '../../components/Navigation/Navigation';

import { useNavigate } from 'react-router-dom';
import TemplateCard from '../../components/Template/TemplateCard/TemplateCard';
import {
  GET_LIBRARY_TEMPLATES,
  GET_TEMPLATES_STATS,
} from '../../graphql/local/queries/templates';
import useOperator from '../../hooks/useOperator';
import {
  LibraryTemplatesQueryResponse,
  TemplatesStatsQueryResponse,
} from '../../types/template.types';
import CreateTemplate from './CreateTemplate';

export default function Templates() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [createTemplateOpen, setCreateTemplateOpen] = React.useState(false);

  const navigate = useNavigate();

  const createTemplateButtonClicked = () => {
    setCreateTemplateOpen(true);
  };

  const handleClose = () => {
    setCreateTemplateOpen(false);
  };

  const itemPerPage = 25;
  const [operator] = useOperator();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageTokens, setPageTokens] = useState<Map<number, string | null>>(
    new Map([[0, null]]),
  );

  const {
    loading,
    error,
    data,
    fetchMore,
    refetch: templatesRefetcher,
  } = useQuery<LibraryTemplatesQueryResponse>(GET_LIBRARY_TEMPLATES, {
    variables: {
      operatorId: operator?.id || 0,
      nextToken: pageTokens.get(currentPage) || null,
    },
    pollInterval: 15000,
  });

  const { data: statsData } = useQuery<TemplatesStatsQueryResponse>(
    GET_TEMPLATES_STATS,
    {
      variables: {
        operatorId: operator?.id || 0,
      },
      pollInterval: 15000,
    },
  );
  const libraryTemplatesCount = statsData?.operator.libraryTemplatesCount || 0;
  const totalTemplatesCount = statsData?.operator.libraryTemplatesCount || 0;

  const handleRefresh = useCallback(() => {
    templatesRefetcher();
  }, [templatesRefetcher]);

  const templates = data?.operator.libraryTemplates.items;
  const [isLoading, setIsLoading] = React.useState(loading);

  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  React.useEffect(() => {
    handleRefresh();
    setCurrentPage(0);
    setPageTokens(new Map([[0, null]]));
  }, [operator, handleRefresh]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    if (loading || !data || page < 0 || page > pageTokens.size) {
      return;
    }

    var token: string | null = null;

    if (pageTokens.has(page)) {
      token = pageTokens.get(page)!;
    } else {
      token = data?.operator.libraryTemplates.nextToken || null;
      setPageTokens((prev) => new Map(prev.set(page, token)));
    }

    setCurrentPage(page);

    fetchMore({
      variables: {
        nextToken: token,
      },
    });
  };

  const page =
    currentPage > libraryTemplatesCount / itemPerPage ? 0 : currentPage;

  return (
    <Navigation>
      <Banner
        leftImage={
          isDarkMode ? (
            <TemplateBannerLeftDarkImage />
          ) : (
            <TemplateBannerLeftImage />
          )
        }
        rightImage={
          isDarkMode ? (
            <TemplateBannerRightDarkImage />
          ) : (
            <TemplateBannerRightImage />
          )
        }
        kpiCards={[
          <KpiCard
            icon={<BubbleChart />}
            title="Total Templates"
            value={totalTemplatesCount.toLocaleString()}
          ></KpiCard>,
          <KpiCard
            icon={<AddCircle />}
            title="Maximum Template Count"
            value="25"
          ></KpiCard>,
          <KpiCard
            icon={<TotalClicksIcon />}
            title="Templates In Use"
            value={'5'}
          ></KpiCard>,
        ]}
      />
      <Dialog fullWidth maxWidth="md" open={createTemplateOpen}>
        <Box
          style={{
            maxHeight: '90%',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <CreateTemplate
            closeHandler={handleClose}
            refreshHandler={handleRefresh}
          />
        </Box>
      </Dialog>
      <TablePagination
        component="div"
        count={libraryTemplatesCount}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={itemPerPage}
        rowsPerPageOptions={[itemPerPage]}
        nextIconButtonProps={{
          disabled:
            loading ||
            libraryTemplatesCount < itemPerPage ||
            page === libraryTemplatesCount / itemPerPage - 1,
        }}
        backIconButtonProps={{ disabled: loading || page === 0 }}
      />
      {isLoading ? <LinearProgress /> : null}
      {error ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </Alert>
      ) : null}
      {templates && (
        <>
          <Grid container spacing={2}>
            {templates.map((template) => (
              <Grid item key={template.id} xs={12} md={6} lg={6} xl={4}>
                <TemplateCard
                  templateStatus={template.status}
                  templateUrls={template.templateUrls}
                  templateTitle={template.title}
                  templateDescription={template.description}
                  createTime={template.createTime}
                  contentKind={template.contentKind}
                  onDetailsClick={() => {
                    navigate(`/templates/${template.id}`);
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <Tooltip title={`Create New Template`}>
            <Fab
              style={{
                margin: '0px',
                top: 'auto',
                right: '20px',
                bottom: '20px',
                left: 'auto',
                position: 'fixed',
              }}
              onClick={createTemplateButtonClicked}
              color="primary"
              aria-label="add"
            >
              <Add />
            </Fab>
          </Tooltip>
        </>
      )}
    </Navigation>
  );
}
