import Box from '@mui/material/Box';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

interface LinearProgressWithPercentageProps extends LinearProgressProps {
  title?: string;
  value: number;
  expectedCount: number;
  succeededCount: number;
}

function LinearProgressWithPercentage({
  title,
  value,
  expectedCount,
  succeededCount,
  ...props
}: LinearProgressWithPercentageProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {title && (
        <Typography
          variant="caption"
          color="text.primary"
          sx={{ marginRight: 1 }}
        >
          {title}
        </Typography>
      )}
      <Box sx={{ width: '100%', marginRight: 0 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          value={(succeededCount / expectedCount) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default LinearProgressWithPercentage;
