import { Box, Divider, Grid, Paper } from '@mui/material';
import React from 'react';
import KpiCard from '../KpiCard';

interface BannerProps {
  leftImage?: React.ReactElement;
  rightImage?: React.ReactElement;
  kpiCards?: React.ReactElement<typeof KpiCard>[];
}

const Banner: React.FC<BannerProps> = ({ leftImage, rightImage, kpiCards }) => {
  return (
    <Paper elevation={3}>
      <Grid
        container
        spacing={2}
        marginBottom={2}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Box
          component={Grid}
          item
          sm={2}
          sx={{
            display: { xs: 'none', lg: 'flex' },
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          {leftImage}
        </Box>

        <Box component={Grid} item xs={12} lg={8}>
          <Grid
            container
            spacing={3}
            marginBottom={2}
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            {kpiCards &&
              kpiCards.map((card, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: '100%',
                    }}
                  >
                    {index > 0 && <Divider orientation="vertical" flexItem />}
                    {card}
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>

        <Box
          component={Grid}
          item
          sm={2}
          sx={{
            display: { xs: 'none', lg: 'flex' },
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          {rightImage}
        </Box>
      </Grid>
    </Paper>
  );
};

export default Banner;
