import { Button, ButtonProps } from '@mui/material';

interface NegateToggleProps {
  btnProps?: ButtonProps;
  value: boolean;
  onChange: any;
  error?: string;
}

const NegateToggle: React.FC<NegateToggleProps> = ({
  btnProps,
  value,
  onChange,
}) => {
  return (
    <Button
      size="small"
      variant="outlined"
      sx={{ p: 0.2 }}
      {...btnProps}
      onClick={() => onChange({ target: { value: !value } })}
      color={value ? 'error' : 'success'}
    >
      {value ? 'IS NOT' : 'IS'}
    </Button>
  );
};

export default NegateToggle;
