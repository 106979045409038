import { gql, useMutation, useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import React, { useState } from 'react';
import { Img } from 'react-image';
import useOperator from '../../../hooks/useOperator';
import { MessageType } from '../../../types/campaign.types';
import { SenderProfilesQueryResponse } from '../../../types/senderProfiles.types';
import {
  LibraryTemplateStatus,
  LibraryTemplatesQueryResponse,
  TemplateUploadType,
} from '../../../types/template.types';
import { PlainLoading } from '../../Loading';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface SelectTemplateProps {
  errors: FormikErrors<{
    templateId: string;
    subject: string;
    senderProfileId: string;
  }>;
  values: FormikValues;
  handleChange: any;
}

const SelectTemplate: React.FC<SelectTemplateProps> = ({
  errors,
  values,
  handleChange,
}) => {
  const [operator] = useOperator();
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [subjectError, setSubjectError] = useState('');

  const [isConfirmWindowOpen, setIsConfirmWindowOpen] = React.useState(false);

  const openConfirmWindow = () => {
    setIsConfirmWindowOpen(true);
  };

  const closeConfirmWindow = () => {
    setIsConfirmWindowOpen(false);
  };

  const deleteUploadedFile = () => {
    values.templateUploadId = '';
    values.templateUploadFileName = '';
    values.templateName = '';
    values.templateId = '';
    values.subject = '';
    values.senderProfileId = '';
    handleChange('senderProfileId')({ target: { value: '' } });
    handleChange('subject')({ target: { value: '' } });
    handleChange('templateId')({ target: { value: '' } });
    handleChange('templateName')({ target: { value: '' } });
    handleChange('templateUploadId')({ target: { value: '' } });
    handleChange('templateUploadFileName')({ target: { value: '' } });
  };

  const { loading, error, data } = useQuery<LibraryTemplatesQueryResponse>(
    gql`
      query Templates($operatorId: ID!) {
        operator(id: $operatorId) {
          libraryTemplates {
            items {
              id
              title
              status
              contentKind
            }
          }
        }
      }
    `,
    {
      variables: {
        operatorId: operator?.id || 0,
      },
    },
  );

  const INITIATE_UPLOAD = gql`
    mutation InitiateUpload(
      $operatorId: ID!
      $sizeBytes: Int!
      $type: UploadType!
    ) {
      initiateUpload(
        input: { operatorId: $operatorId, sizeBytes: $sizeBytes, type: $type }
      ) {
        id
        operatorId
        type
        url
      }
    }
  `;
  const [initiateUpload] = useMutation(INITIATE_UPLOAD);

  const templates = data?.operator.libraryTemplates.items?.filter(
    (template) =>
      template.status === LibraryTemplateStatus.READY &&
      template.contentKind === values.campaignType,
  );

  const [isLoading, setIsLoading] = React.useState(loading);

  const fileSelectClicked = (e: any) => {
    if (values.subject === '') {
      setSubjectError('Subject is required');
      e.preventDefault();
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    if (selectedFile) {
      setFileUploadLoading(true);
      const jsonData = {
        subject: values.subject,
        body: '',
      };
      const reader = new FileReader();
      reader.readAsText(selectedFile, 'UTF-8');

      reader.onload = (readerEvent) => {
        const content = readerEvent.target?.result;
        jsonData['body'] = content as string;
        const jsonString = JSON.stringify(jsonData);
        const selectedFileBlob = new Blob([jsonString], {
          type: 'application/json',
        });

        const templateType =
          values.messageType === MessageType.EMAIL
            ? TemplateUploadType.EMAIL_TEMPLATE
            : values.templateKind === MessageType.SMS
            ? TemplateUploadType.SMS_TEMPLATE
            : TemplateUploadType.WEB_PUSH_TEMPLATE;
        const handeUpload = async () => {
          try {
            const { data } = await initiateUpload({
              variables: {
                operatorId: operator?.id || '',
                sizeBytes: selectedFileBlob?.size,
                type: templateType,
              },
            });

            const presignedUrl = data.initiateUpload.url;

            const headers = {
              'x-amz-meta-uploadtype': templateType,
              'x-amz-meta-operatorid': operator?.id || '',
              'Content-Type': 'application/json',
              'Content-Length': selectedFileBlob?.size?.toString() || '',
            };

            await fetch(presignedUrl, {
              method: 'PUT',
              headers,
              body: selectedFileBlob,
              mode: 'cors',
            }).then((response) => {
              if (response.ok) {
                setFileUploadLoading(false);

                values.templateId = '';
                handleChange('templateId')({ target: { value: '' } });

                values.templateUploadId = data.initiateUpload.id;
                handleChange('templateUploadId')({
                  target: { value: data.initiateUpload.id },
                });

                values.templateUploadFileName = selectedFile.name;
                handleChange('templateUploadFileName')({
                  target: { value: selectedFile.name },
                });
              } else {
                throw new Error(
                  `File upload failed with status: ${response.status}`,
                );
              }
            });
          } catch (error) {
            setFileUploadLoading(false);
          }
        };
        handeUpload();
      };
    }
  };

  const {
    loading: spLoading,
    error: spError,
    data: spData,
  } = useQuery<SenderProfilesQueryResponse>(
    gql`
      query SenderProfiles($operatorId: ID!) {
        operator(id: $operatorId) {
          senderProfiles {
            items {
              id
              title
              kind
              emailProfile {
                fromAddress
                fromName
              }
              smsProfile {
                sender
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        operatorId: operator?.id || 0,
      },
    },
  );

  const senderProfiles =
    spData?.operator.senderProfiles.items.filter(
      (sp) => sp.kind === values.campaignType,
    ) || [];

  React.useEffect(() => {
    setIsLoading(loading || spLoading);
  }, [loading, spLoading]);

  if (isLoading) {
    return <PlainLoading />;
  }

  if (error || spError) {
    return <p>Error: {error?.message || spError?.message}</p>;
  }

  return (
    <Grid
      container
      spacing={3}
      sx={{
        py: {
          md: 5,
          xs: 2,
        },
      }}
    >
      {values.templatePreviewUrl ? (
        <>
          <Grid item xs={12}>
            <Dialog
              open={isConfirmWindowOpen}
              onClose={closeConfirmWindow}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Delete Template'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this template?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeConfirmWindow}>Cancel</Button>
                <Button
                  onClick={() => {
                    values.templatePreviewUrl = '';
                    handleChange('templatePreviewUrl')({
                      target: { value: '' },
                    });
                    closeConfirmWindow();
                  }}
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <Paper
              elevation={3}
              sx={{
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: 'relative',
              }}
            >
              <IconButton
                sx={{ position: 'absolute', top: 0, right: 0 }}
                onClick={() => {
                  openConfirmWindow();
                }}
              >
                <Close />
              </IconButton>
              <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ pt: 2 }}>
                    Template
                  </Typography>
                </Box>
                <Img
                  src={values.templatePreviewUrl}
                  style={{ width: '100%' }}
                />
              </Box>
            </Paper>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-template-label">Template</InputLabel>
              <Select
                labelId="select-template-label"
                id="templateId"
                value={values.templateId}
                onChange={(e) => {
                  values.templateName = templates?.find(
                    (template) => template.id === e.target.value,
                  )?.title;
                  handleChange('templateId')(e);
                }}
                error={!!errors.templateId}
                label="Template"
                fullWidth
                disabled={values.templateUploadFileName !== ''}
              >
                <MenuItem value="">None</MenuItem>
                {templates?.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {values.templateId === '' && (
            <>
              <Grid item xs={12}>
                <Divider>OR</Divider>
              </Grid>
              {values.campaignType === MessageType.EMAIL && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      label="Subject"
                      variant="standard"
                      error={!!errors.subject || !!subjectError}
                      helperText={errors.subject || subjectError}
                      value={values.subject}
                      onChange={(e) => {
                        values.subject = e.target.value;
                        handleChange('subject')(e);
                        setSubjectError('');
                      }}
                      InputLabelProps={{ shrink: true }}
                      disabled={values.templateUploadFileName !== ''}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {values.templateUploadFileName !== '' ? (
                  <Chip
                    onDelete={deleteUploadedFile}
                    label={values.templateUploadFileName}
                    size="medium"
                    color="secondary"
                  />
                ) : (
                  <LoadingButton
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    color="secondary"
                    loading={fileUploadLoading}
                    loadingPosition="start"
                  >
                    Upload template
                    <VisuallyHiddenInput
                      type="file"
                      onClick={fileSelectClicked}
                      onChange={handleFileSelect}
                    />
                  </LoadingButton>
                )}
              </Grid>
              {values.campaignType !== MessageType.WEB_PUSH && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="senderProfile-label">
                      Sender Profile
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="senderProfile-label"
                      id="senderProfileId"
                      value={values.senderProfileId}
                      onChange={(e) => {
                        values.senderProfileId = e.target.value;
                        handleChange('senderProfileId')(e);
                      }}
                      error={!!errors.senderProfileId}
                      label="Sender Profile"
                      displayEmpty
                    >
                      {senderProfiles?.map((sp) => (
                        <MenuItem key={sp.id} value={sp.id}>
                          {sp.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
};
export default SelectTemplate;
