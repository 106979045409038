import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Icon,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import CustomCircularProgress from '../CustomCircularProgress/CustomCircularProgress';

interface DashboardKpiCardProps {
  dashboardKpiCard?: React.ReactElement<typeof DashboardKpiCard>[];
  icon: ReactElement;
  title: string;
  value: number;
  expectedValue: number;
  icon1: ReactElement;
  title1: string;
  value1: number;
}

const DashboardKpiCard: React.FC<DashboardKpiCardProps> = ({
  dashboardKpiCard,
  icon,
  title,
  value,
  expectedValue,
  icon1,
  title1,
  value1,
}) => {
  const theme = useTheme();
  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          p: 2,
        }}
      >
        <CustomCircularProgress value={value} expectedValue={expectedValue} />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }} px={2}>
            <Icon sx={{ color: theme.palette.primary.main, mr: 0.5 }}>
              {icon}
            </Icon>
            <Typography variant="h6" sx={{ fontWeight: 'light' }}>
              {title}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} mb={2}>
          <Box display="flex" justifyContent="space-between" px={2}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Icon sx={{ color: theme.palette.primary.main, mr: 0.5 }}>
                {icon1}
              </Icon>
              <Typography variant="h6" sx={{ fontWeight: 'light' }}>
                {title1}
              </Typography>
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 'light' }}>
              {value1 !== undefined ? value1 : <CircularProgress />}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DashboardKpiCard;
