import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface RadialChartProps {
  labels: string[];
  series: number[];
}

export const RadialChart: React.FC<RadialChartProps> = (
  props: RadialChartProps,
) => {
  const [chartSeries, setChartSeries] = useState([0, 0, 0, 0, 0]);

  useEffect(() => {
    setChartSeries(props.series);
  }, [props.series]);
  const chart = {
    series: chartSeries,
    options: {
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,

          hollow: {
            margin: 5,
            size: '20%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },

      colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5', '#1d3557'],
      labels: props.labels,

      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left' as 'left',
        offsetX: -30,
        offsetY: 5,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          width: 10,
          height: 10,
        },
        formatter: function (seriesName: string, opts: any) {
          return (
            seriesName +
            ':  ' +
            opts.w.globals.series[opts.seriesIndex].toLocaleString()
          );
        },
        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: true,
              offsetX: -30,
              offsetY: 5,
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <ReactApexChart
        options={chart.options}
        series={chart.series}
        type="radialBar"
        height={390}
        width={310}
      />
    </div>
  );
};

export default RadialChart;
