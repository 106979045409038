import { gql, useQuery } from '@apollo/client';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { FormikValues } from 'formik';
import React from 'react';
import { Img } from 'react-image';
import useOperator from '../../../hooks/useOperator';
import {
  ExecutionSchedulingTypeMapping,
  MessageTypeMapping,
  ReccuringPeriodMapping,
} from '../../../mappings';
import {
  ExecutionSchedulingType,
  MessageType,
  PeriodType,
} from '../../../types/campaign.types';
import SegmentChip from '../SegmentChip/SegmentChip';

interface CreateCampaignPreviewProps {
  values: FormikValues;
  handleChange: any;
}

export const TitleWithStepLabel: React.FC<{ title: string; step: number }> = ({
  title,
  step,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 24,
          height: 24,
          mr: 1,
          borderRadius: '50%',
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Typography variant="body1" color="white">
          {step}
        </Typography>
      </Paper>
      <Typography variant="subtitle2">{title}</Typography>
    </Box>
  );
};

const CreateCampaignPreview: React.FC<CreateCampaignPreviewProps> = ({
  values,
  handleChange,
}) => {
  const [operator] = useOperator();
  const [templatePreviewUrl, setTemplatePreviewUrl] = React.useState('');

  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const GET_LIBRARY_TEMPLATE_PREVIEW_URL = gql`
    query GetLibraryTemplatePreviewUrl($operatorId: ID!, $campaignId: ID!) {
      operator(id: $operatorId) {
        campaign(id: $campaignId) {
          templateUrls {
            contentUrl
            previewUrl
            thumbnailUrl
          }
        }
      }
    }
  `;

  const {
    data: libraryTemplatePreviewUrlData,
    loading: templatePreviewLoading,
  } = useQuery(GET_LIBRARY_TEMPLATE_PREVIEW_URL, {
    variables: {
      operatorId: operator?.id || 0,
      campaignId: values.campaignId,
    },
    skip: values.campaignId === '',
    onCompleted: (data) => {
      if (!templatePreviewUrl) {
        setTemplatePreviewUrl(
          data?.operator.campaign.templateUrls.previewUrl || '',
        );
      }
    },
  });

  return (
    <Grid
      container
      spacing={2}
      sx={{
        py: {
          md: 5,
          xs: 2,
        },
        ml: {
          md: 2,
          xs: 0,
        },
      }}
    >
      {/* Sol Taraf */}
      <Grid item xs={12} md={6}>
        <Grid container spacing={2} key={values.id}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TitleWithStepLabel title="Basic Information" step={1} />
              </Grid>
              <Grid item xs={12}>
                <Typography width={'100%'} variant="caption">
                  Operator
                </Typography>
                <Typography width={'100%'} variant="body1">
                  {operator?.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography width={'100%'} variant="caption">
                  Message Type
                </Typography>
                <Typography width={'100%'} variant="body1">
                  {MessageTypeMapping[values.campaignType as MessageType]}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography width={'100%'} variant="caption">
                  Schedule Type
                </Typography>
                <Typography width={'100%'} variant="body1">
                  {
                    ExecutionSchedulingTypeMapping[
                      values.schedulingType as ExecutionSchedulingType
                    ]
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography width={'100%'} variant="caption">
                  Title
                </Typography>
                <Typography width={'100%'} variant="body1">
                  {values.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TitleWithStepLabel title="Targeting" step={2} />
              </Grid>
              <Grid item xs={12}>
                <Typography width={'100%'} variant="caption">
                  Included Segments
                </Typography>
                <Box width={'100%'}>
                  {values.includedSegments.map((segmentId: string) => (
                    <Box mb={0.5}>
                      <SegmentChip key={segmentId} segmentId={segmentId} />
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display:
                    values.excludedSegments.length > 0 ? 'block' : 'none',
                }}
              >
                <Typography width={'100%'} variant="caption">
                  Excluded Segments
                </Typography>

                <Box width={'100%'}>
                  {values.excludedSegments.map((segmentId: string) => (
                    <Box mb={0.5}>
                      <SegmentChip key={segmentId} segmentId={segmentId} />
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TitleWithStepLabel title="Template" step={3} />
              </Grid>
              <Grid item xs={12}>
                {values.templatePreviewUrl || libraryTemplatePreviewUrlData ? (
                  <>
                    <Box
                      sx={{
                        width: {
                          xs: '90%',
                          md: '70%',
                        },
                      }}
                    >
                      {templatePreviewLoading ? (
                        <CircularProgress sx={{ p: 2, m: 2 }} size={100} />
                      ) : (
                        <Img
                          src={values.templatePreviewUrl || templatePreviewUrl}
                          style={{ width: '100%' }}
                        />
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography width={'100%'} variant="caption">
                      {values.templateId
                        ? 'Template Name'
                        : 'Uploaded File Name'}
                    </Typography>
                    <Box width={'100%'}>
                      {values.templateId ? (
                        <Typography variant="body1">
                          {values.templateName}
                        </Typography>
                      ) : (
                        <Chip
                          key={values.templateUploadFileName}
                          sx={{ mr: 1, mb: 1 }}
                          label={values.templateUploadFileName}
                        />
                      )}
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Sağ Taraf */}
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TitleWithStepLabel title="Connection" step={4} />
              </Grid>
              <Grid item xs={12}>
                <Typography width={'100%'} variant="caption">
                  Name
                </Typography>
                <Typography width={'100%'} variant="body1">
                  {values.connectionName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TitleWithStepLabel title="Schedule" step={5} />
              </Grid>
              {values.schedulingType ===
                ExecutionSchedulingType.RECURRING_EXECUTION && (
                <>
                  <Grid item xs={12}>
                    <Typography width={'100%'} variant="caption">
                      Time Period
                    </Typography>
                    <Typography width={'100%'} variant="body1">
                      {ReccuringPeriodMapping[values.timePeriod as PeriodType]}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display:
                        values.timePeriod !== PeriodType.DAILY
                          ? 'flex'
                          : 'none',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography width={'100%'} variant="caption">
                      Day
                    </Typography>
                    <Typography width={'100%'} variant="body1">
                      {values.timePeriod === PeriodType.WEEKLY
                        ? days[values.day - 1]
                        : values.day}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={values.timePeriod === PeriodType.DAILY ? 12 : 6}
                  >
                    <Typography width={'100%'} variant="caption">
                      Time
                    </Typography>
                    <Typography width={'100%'} variant="body1">
                      {dayjs(values.time)
                        .tz(operator?.timezone)
                        .format('hh:mm A')}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display:
                    values.schedulingType ===
                    ExecutionSchedulingType.RECURRING_EXECUTION
                      ? 'block'
                      : 'none',
                }}
              >
                <Typography width={'100%'} variant="caption">
                  Start Time
                </Typography>
                <Typography width={'100%'} variant="body1">
                  {dayjs(values.startTime)
                    .tz(operator?.timezone)
                    .format('DD/MM/YYYY hh:mm A')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display:
                    values.schedulingType ===
                    ExecutionSchedulingType.RECURRING_EXECUTION
                      ? 'block'
                      : 'none',
                }}
              >
                <Typography width={'100%'} variant="caption">
                  End Time
                </Typography>
                <Typography width={'100%'} variant="body1">
                  {values.endTime
                    ? dayjs(values.endTime)
                        .tz(operator?.timezone)
                        .format('DD/MM/YYYY hh:mm A')
                    : 'None'}
                </Typography>
              </Grid>

              {values.schedulingType ===
                ExecutionSchedulingType.CUSTOM_SCHEDULED_EXECUTION && (
                <Grid item xs={12} sx={{ display: 'flex' }}>
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    {values.startTimes.map(
                      (startTime: string, index: number) => (
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot />
                            {index === values.startTimes.length - 1 ? null : (
                              <TimelineConnector />
                            )}
                          </TimelineSeparator>
                          <TimelineContent>
                            {dayjs(startTime)
                              .tz(operator?.timezone)
                              .format('DD/MM/YYYY hh:mm A')}
                          </TimelineContent>
                        </TimelineItem>
                      ),
                    )}
                  </Timeline>
                </Grid>
              )}

              {values.schedulingType ===
                ExecutionSchedulingType.IMMEDIATE_EXECUTION && (
                <Grid item xs={12}>
                  <Box sx={{ mr: 5 }}>
                    <Alert variant="outlined" severity="info">
                      This campaign will be executed immediately after creation.
                    </Alert>
                  </Box>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={values.ignoreMarketingLimit}
                      disabled
                      checkedIcon={<RadioButtonChecked />}
                      icon={<RadioButtonUnchecked />}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      Ignore Global Marketing Frequency Limit
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateCampaignPreview;
