import { CampaignState, ExecutionSchedulingType, MessageType, PeriodType } from "./types/campaign.types";
import { ContentKind, LibraryTemplateStatus } from "./types/template.types";

export const CampaignStateMapping: Record<CampaignState, string> = {
    [CampaignState.ACTIVE_RUNNING]: 'Active (Running)',
    [CampaignState.ACTIVE_STOPPED]: 'Active (Stopped)',
    [CampaignState.CREATING]: 'Creating',
    [CampaignState.UPDATING]: 'Updating',
    [CampaignState.ARCHIVING]: 'Archiving',
    [CampaignState.DELETING]: 'Deleting',
    [CampaignState.ARCHIVED]: 'Archived',
};

export const ExecutionSchedulingTypeMapping: Record<ExecutionSchedulingType, string> =
{
    [ExecutionSchedulingType.RECURRING_EXECUTION]: 'Recurring Execution',
    [ExecutionSchedulingType.CUSTOM_SCHEDULED_EXECUTION]:
        'Custom Scheduled Execution',
    [ExecutionSchedulingType.IMMEDIATE_EXECUTION]: 'Immediate Execution',
};


export const ReccuringPeriodMapping: Record<PeriodType, string> = {
    [PeriodType.DAILY]: 'Daily',
    [PeriodType.WEEKLY]: 'Weekly',
    [PeriodType.MONTHLY]: 'Monthly',
};

export const MessageTypeMapping: Record<string, string> = {
    [MessageType.EMAIL]: 'E-Mail',
    [MessageType.SMS]: 'SMS',
    [MessageType.WEB_PUSH]: 'Web Push',
};

export const ContentKindMapping: Record<string, string> = {
    [ContentKind.EMAIL]: 'E-Mail',
    [ContentKind.SMS]: 'SMS',
    [ContentKind.WEB_PUSH]: 'Web Push',
};
export const TemplateStatusMapping: Record<LibraryTemplateStatus, string> = {
    [LibraryTemplateStatus.READY]: 'Ready',
    [LibraryTemplateStatus.WAITING_UPLOAD]: 'Waiting Upload',
};

export const CampaignStateColorMapping: Record<CampaignState, "success" | "warning" | "primary" | "error" | "default"> = {
    [CampaignState.ACTIVE_RUNNING]: 'success',
    [CampaignState.ACTIVE_STOPPED]: 'warning',
    [CampaignState.CREATING]: 'primary',
    [CampaignState.UPDATING]: 'primary',
    [CampaignState.ARCHIVING]: 'primary',
    [CampaignState.DELETING]: 'error',
    [CampaignState.ARCHIVED]: 'default',
};