import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

interface TabViewProps {
  tabValues: string[];
  defaultValue: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export default function TabView({
  tabValues,
  defaultValue,
  onChange,
}: TabViewProps) {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    onChange(event, newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        {tabValues.map((tabValue) => (
          <Tab key={tabValue} value={tabValue} label={tabValue} />
        ))}
      </Tabs>
    </Box>
  );
}
