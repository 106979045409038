import ReportsIcon from '@mui/icons-material/AssessmentRounded';
import SegmentsIcon from '@mui/icons-material/BubbleChartRounded';
import DashboardIcon from '@mui/icons-material/DashboardRounded';
import PeopleIcon from '@mui/icons-material/People';
import TemplatesIcon from '@mui/icons-material/WebRounded';
import { Box, List, ListItem, useTheme } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CampaignsIcon } from '../../assets/campaign.svg';
import { NavItem } from './components';
import OperatorSelect from './components/OperatorSelect';

interface NavLink {
  to: string;
  label: string;
  icon: React.ReactElement;
}

const DrawerContent: React.FC = () => {
  const [t] = useTranslation();
  const theme = useTheme();

  const navLinks: NavLink[] = [
    {
      to: '/dashboard',
      label: t('Dashboard'),
      icon: (
        <DashboardIcon
          style={{
            color: theme.palette.text.secondary,
            fill: theme.palette.text.secondary,
          }}
        />
      ),
    },
    {
      to: '/segments',
      label: t('Segments'),
      icon: (
        <SegmentsIcon
          style={{
            color: theme.palette.text.secondary,
            fill: theme.palette.text.secondary,
          }}
        />
      ),
    },
    {
      to: '/templates',
      label: t('Templates'),
      icon: (
        <TemplatesIcon
          style={{
            color: theme.palette.text.secondary,
            fill: theme.palette.text.secondary,
          }}
        />
      ),
    },
    {
      to: '/campaigns',
      label: t('Campaigns'),
      icon: (
        <CampaignsIcon
          style={{
            color: theme.palette.text.secondary,
            fill: theme.palette.text.secondary,
          }}
        />
      ),
    },
    {
      to: '/reports',
      label: t('Reports'),
      icon: (
        <ReportsIcon
          style={{
            color: theme.palette.text.secondary,
            fill: theme.palette.text.secondary,
          }}
        />
      ),
    },
    {
      to: '/customers',
      label: t('Customers'),
      icon: (
        <PeopleIcon
          style={{
            color: theme.palette.text.secondary,
            fill: theme.palette.text.secondary,
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Box sx={{ minHeight: '60px' }} />
      <List component="nav">
        <ListItem sx={{ pt: 3, pr: 0 }} key="OPERATOR_SELECT">
          <OperatorSelect />
        </ListItem>
        {navLinks.map((link) => {
          return (
            <NavItem
              key={link.label}
              to={link.to}
              label={link.label}
              iconComponent={link.icon}
            />
          );
        })}
      </List>
    </>
  );
};

export default DrawerContent;
