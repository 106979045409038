import React, { PropsWithChildren, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { AppBar, SideBar } from './components';

const Navigation: React.FC<PropsWithChildren> = ({ children }) => {
  const [desktopNavOpen, setDesktopNav] = useLocalStorage<boolean>(
    'desktopNavOpen',
    true,
  );
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const toggleDesktopNav = () => {
    setDesktopNav(!desktopNavOpen);
  };

  return (
    <>
      <AppBar
        toggleDesktopNav={toggleDesktopNav}
        toggleMobileNav={toggleMobileNav}
      />
      <SideBar
        mobileNavOpen={mobileNavOpen}
        toggleMobileNav={toggleMobileNav}
        desktopNavOpen={desktopNavOpen}
      >
        {children}
      </SideBar>
    </>
  );
};

export default Navigation;
