import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { concat } from '@apollo/client/link/core';
import { Auth } from 'aws-amplify';
import { awsConfig } from '../../aws';
import { initialCache, resolvers } from '../../graphql/local';

const getAuthToken = async () => {
  try {
    const awsUser = await Auth.currentAuthenticatedUser();
    return awsUser.signInUserSession.idToken.jwtToken;
  } catch (error) {
    console.error('Error fetching token:', error);
    return null;
  }
};

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Initialize the cache with the initialCache values
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        userSettings: {
          read(existingData = initialCache.userSettings) {
            return existingData;
          },
        },
      },
    },
    Operator: {
      merge: true,
      fields: {
        segments: {
          merge: true,
          read(existing) {
            return existing;
          },
        },
        customers: {
          merge: true,
          read(existing) {
            return existing;
          },
        },
      },
    },
  },
});

const httpLink = new HttpLink({ uri: awsConfig.aws_appsync_graphqlEndpoint });

const client = new ApolloClient({
  link: concat(authLink, httpLink),
  cache,
  resolvers,
});

type Props = {
  children: React.ReactNode;
};

/**
 * `ApolloLayer` is a React component that wraps the `ApolloProvider` from the `@apollo/client` library.
 * It provides an Apollo Client to the component tree and makes it available to all the child components
 * for executing GraphQL operations.
 *
 * The Apollo Client is configured with a URI for the GraphQL server, an `InMemoryCache` for caching GraphQL data,
 * and local resolvers for local state management.
 *
 * This component wraps the root/top level component.
 *
 * @component
 * @param {object} props - The properties that define the component.
 * @param {React.ReactNode} props.children - The child components to be rendered within the `ApolloProvider`.
 * @example
 * return (
 *   <ApolloLayer>
 *     <RootLevelComponent />
 *   </ApolloLayer>
 * )
 */
export default function ApolloLayer({ children }: Props) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
