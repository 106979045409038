import { gql, useMutation, useQuery } from '@apollo/client';
import { ArrowBackIos, Delete, Edit } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Img } from 'react-image';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from '../../../components/Navigation/Navigation';
import { DELETE_TEMPLATE } from '../../../graphql/local/mutations/templates';
import { GET_TEMPLATE_BY_ID } from '../../../graphql/local/queries/templates';
import useOperator from '../../../hooks/useOperator';
import { ContentKindMapping, TemplateStatusMapping } from '../../../mappings';
import { ConnectionsQueryResponse } from '../../../types/connection.types';
import { DomainsQueryResponse } from '../../../types/domain.types';
import { SenderProfilesQueryResponse } from '../../../types/senderProfiles.types';
import {
  ContentKind,
  LibraryTemplateQueryResponse,
  LibraryTemplateStatus,
} from '../../../types/template.types';
import { timeAgo } from '../../../utils/timeUtils';

const TemplateDetails = () => {
  const navigate = useNavigate();
  const params = useParams<{ templateId: string }>();
  const backToTemplatesButtonClicked = () => {
    navigate('/templates');
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [testRecipient, setTestRecipient] = React.useState('');
  const [connectionId, setConnectionId] = React.useState('');
  const [senderProfileId, setSenderProfileId] = React.useState('');
  const [domainId, setDomainId] = React.useState('');
  const [operator, { prevOperator }] = useOperator();

  useEffect(() => {
    if (operator!.id !== prevOperator!.id) {
      navigate('/templates');
    }
  }, [navigate, operator, prevOperator]);

  const SEND_TEST_MESSAGE = gql`
    mutation SendTestMessage($input: SendTestMessageInput!) {
      sendTestMessage(input: $input)
    }
  `;

  const [
    sendTestMessage,
    { loading: testMessageLoading, error: testMessageError },
  ] = useMutation(SEND_TEST_MESSAGE);

  const handleSentTestMessage = () => {
    sendTestMessage({
      variables: {
        input: {
          recipient: {
            emailAddress:
              template?.contentKind === ContentKind.EMAIL ? testRecipient : '',
            messageType: ContentKind.EMAIL,
            mobileNumber:
              template?.contentKind === ContentKind.SMS ? testRecipient : '',
          },
          connectionId,
          libraryTemplateId: params.templateId,
          operatorId: operator?.id || 0,
          trackingDomainId: domainId,
          senderProfileId,
        },
      },
    }).then((res) => {
      if (res.data.sendTestMessage) {
        handleClickOpen();
        setTestRecipient('');
        setConnectionId('');
        setSenderProfileId('');
        setDomainId('');
      }
    });
  };

  const { loading, error, data } = useQuery<LibraryTemplateQueryResponse>(
    GET_TEMPLATE_BY_ID,
    {
      variables: {
        operatorId: operator?.id || 0,
        id: params.templateId,
      },
    },
  );

  const {
    loading: domainsLoading,
    //error: cError,
    data: domainsData,
  } = useQuery<DomainsQueryResponse>(
    gql`
      query Domains($operatorId: ID!) {
        operator(id: $operatorId) {
          domains {
            id
            dnsName
          }
        }
      }
    `,
    {
      variables: {
        operatorId: operator?.id || 0,
      },
    },
  );

  const domains = domainsData?.operator?.domains;

  const {
    loading: cLoading,
    //error: cError,
    data: cData,
  } = useQuery<ConnectionsQueryResponse>(
    gql`
      query Connections($operatorId: ID!) {
        operator(id: $operatorId) {
          connections {
            id
            label
            connectorKind
          }
        }
      }
    `,
    {
      variables: {
        operatorId: operator?.id || 0,
      },
    },
  );

  const {
    loading: spLoading,
    //error: spError,
    data: spData,
  } = useQuery<SenderProfilesQueryResponse>(
    gql`
      query SenderProfile($operatorId: ID!) {
        operator(id: $operatorId) {
          senderProfiles {
            items {
              id
              title
              kind
              emailProfile {
                fromAddress
                fromName
              }
              smsProfile {
                sender
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        operatorId: operator?.id || 0,
      },
    },
  );

  const [deleteTemplate] = useMutation(DELETE_TEMPLATE);

  const deleteTemplateButtonClicked = () => {
    try {
      deleteTemplate({
        variables: {
          input: {
            operatorId: operator?.id || 0,
            id: params.templateId,
          },
        },
      });
      navigate('/templates');
    } catch (e) {
      console.log(e);
    }
  };
  const template = data?.operator.libraryTemplate;
  const [isLoading, setIsLoading] = React.useState(loading);

  const connections = cData?.operator?.connections.filter(
    (connection) => connection.connectorKind === template?.contentKind,
  );

  const senderProfiles = spData?.operator.senderProfiles?.items.filter(
    (senderProfile) => senderProfile.kind === template?.contentKind,
  );

  React.useEffect(() => {
    setIsLoading(loading || cLoading || spLoading || domainsLoading);
  }, [loading, cLoading, spLoading, domainsLoading]);

  return (
    <Navigation>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Button
          onClick={backToTemplatesButtonClicked}
          startIcon={<ArrowBackIos />}
          sx={{ color: 'gray' }}
        >
          <Typography
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'inline',
                xl: 'inline',
              },
            }}
          >
            BACK TO TEMPLATES
          </Typography>
        </Button>

        <Box>
          <Button
            onClick={deleteTemplateButtonClicked}
            startIcon={<Delete />}
            sx={{ color: 'gray' }}
            disabled={loading}
          >
            <Typography
              sx={{
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'none',
                  lg: 'inline',
                  xl: 'inline',
                },
              }}
            >
              Delete
            </Typography>
          </Button>
        </Box>
      </Box>

      {/* Navigation End */}
      {isLoading && <LinearProgress />}
      {(error || testMessageError) && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error?.message || testMessageError?.message}
        </Alert>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Test Message Sent Successfully'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The test message was sent successfully. Please check your inbox.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {template && (
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  p: 2,
                }}
              >
                <Chip
                  label={TemplateStatusMapping[template.status]}
                  color={
                    template.status === LibraryTemplateStatus.READY
                      ? 'success'
                      : 'warning'
                  }
                />
                <Chip
                  label={ContentKindMapping[template.contentKind]}
                  color={
                    template.contentKind === ContentKind.EMAIL
                      ? 'success'
                      : ContentKind.SMS
                      ? 'warning'
                      : 'info'
                  }
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  p: 2,
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">{template.title}</Typography>
                <Tooltip title="Edit Template Title">
                  <IconButton size="large" sx={{ color: 'black' }}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box
                sx={{
                  gap: 1,
                  p: 2,
                }}
              >
                <Tooltip title={`Created at ${template.createTime}`}>
                  <Typography
                    component="div"
                    variant="body2"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.secondary"
                  >
                    <EventIcon sx={{ mr: 0.5 }} />
                    {timeAgo(template.createTime)}
                  </Typography>
                </Tooltip>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', width: '100%' }}>
            <Paper elevation={3} sx={{ display: 'flex', width: '100%' }}>
              <Box
                sx={{
                  p: 2,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="h6">Test Template</Typography>
                <Typography variant="subtitle1">
                  The test is a convenient way to test your template.
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      label={
                        template.contentKind === ContentKind.EMAIL
                          ? 'Email Address'
                          : 'Mobile Number'
                      }
                      variant="standard"
                      sx={{ pr: 3 }}
                      value={testRecipient}
                      onChange={(e) => setTestRecipient(e.target.value)}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSentTestMessage}
                    disabled={
                      testMessageLoading ||
                      testRecipient === '' ||
                      connectionId === '' ||
                      senderProfileId === '' ||
                      domainId === ''
                    }
                  >
                    Send
                  </Button>
                </Box>

                <Box sx={{ display: 'flex', mt: 2 }} gap={2}>
                  <FormControl fullWidth>
                    <InputLabel id="connection-label">Connection</InputLabel>
                    <Select
                      fullWidth
                      labelId="connection-label"
                      id="connectionId"
                      value={connectionId}
                      onChange={(e) => setConnectionId(e.target.value)}
                      label="Connection"
                      displayEmpty
                    >
                      {connections?.map((connection) => (
                        <MenuItem key={connection.label} value={connection.id}>
                          {connection.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="senderProfile-label">Sender</InputLabel>
                    <Select
                      fullWidth
                      labelId="senderProfile-label"
                      id="senderProfileId"
                      value={senderProfileId}
                      onChange={(e) => setSenderProfileId(e.target.value)}
                      label="Sender"
                      displayEmpty
                    >
                      {senderProfiles?.map((senderProfile) => (
                        <MenuItem
                          key={senderProfile.title}
                          value={senderProfile.id}
                        >
                          {senderProfile.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="domain-label">Domain</InputLabel>
                    <Select
                      fullWidth
                      labelId="domain-label"
                      id="domainId"
                      value={domainId}
                      onChange={(e) => setDomainId(e.target.value)}
                      label="Domain"
                      displayEmpty
                    >
                      {domains?.map((domain) => (
                        <MenuItem key={domain.id} value={domain.id}>
                          {domain.dnsName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  p: 2,

                  width: {
                    xs: '100%',
                    md: '50%',
                  },
                }}
              >
                <Img
                  src={template.templateUrls.previewUrl}
                  style={{
                    width: '100%',
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Navigation>
  );
};

export default TemplateDetails;
