// helpers/timeUtils.ts
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

/**
 * Convert a timestamp to a relative time string (e.g. "3 days ago")
 * @param {string} timestamp - ISO 8601 formatted timestamp
 * @return {string} - human-readable relative time string
 */

dayjs.extend(relativeTime);

export const timeAgo = (timestamp: string): string => {
    return dayjs().to(dayjs(timestamp));
};

/**
 * Convert a timestamp to a time string
 * @param {string} timestamp - ISO 8601 formatted timestamp
 * @return {string} - human-readable time string
 */
export const time = (timestamp: string): string => {
    return dayjs(timestamp).format('MMMM D, YYYY - h:mm A');
};