import { Grid } from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import React from 'react';
import { ReactComponent as EmailKind } from '../../../assets/illustrations/Templates/emailKind.svg';
import { ReactComponent as SmsKind } from '../../../assets/illustrations/Templates/smsKind.svg';
import { ReactComponent as WebPushKind } from '../../../assets/illustrations/Templates/webPushKind.svg';
import { ContentKind } from '../../../types/template.types';
import SelectKindButton from './SelectKindButton';

interface SelectTemplateKindProps {
  errors: FormikErrors<{
    templateKind: string;
  }>;
  values: FormikValues;
  handleChange: any;
}
const SelectTemplateKind: React.FC<SelectTemplateKindProps> = (
  props: SelectTemplateKindProps,
) => {
  const [selectedKind, setSelectedKind] = React.useState('');
  const selectKindbuttonClicked = (kind: string) => {
    setSelectedKind(kind);
    props.values.templateKind = kind;
    props.handleChange('templateKind')({
      target: {
        value: kind,
      },
    });
  };
  return (
    <Grid
      container
      spacing={3}
      sx={{
        pr: 2,
        pl: 2,
        py: {
          md: 5,
          xs: 2,
        },
      }}
    >
      <Grid item xs={12} md={4}>
        <SelectKindButton
          title="Email"
          description=""
          buttonImage={<EmailKind />}
          selected={selectedKind === ContentKind.EMAIL}
          onClick={() => selectKindbuttonClicked(ContentKind.EMAIL)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectKindButton
          title="SMS"
          description=""
          buttonImage={<SmsKind />}
          selected={selectedKind === ContentKind.SMS}
          onClick={() => selectKindbuttonClicked(ContentKind.SMS)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectKindButton
          title="Webpush"
          description=""
          buttonImage={<WebPushKind />}
          disabled
          selected={selectedKind === ContentKind.WEB_PUSH}
          onClick={() => selectKindbuttonClicked(ContentKind.WEB_PUSH)}
        />
      </Grid>
    </Grid>
  );
};

export default SelectTemplateKind;
