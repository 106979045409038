import { gql } from '@apollo/client';

export const GET_USER_SETTINGS = gql`
  query GetUserSettings {
    userSettings @client {
      language
      theme
    }
  }
`;
