import { Email, Sms } from '@mui/icons-material';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Card, Chip, Tooltip, Typography } from '@mui/material';
import { ResponsiveFunnel } from '@nivo/funnel';
import React from 'react';
import {
  CampaignStateColorMapping,
  CampaignStateMapping,
  ExecutionSchedulingTypeMapping,
  MessageTypeMapping,
} from '../../../mappings';
import {
  CampaignState,
  CampaignStats,
  ExecutionProgress,
  ExecutionSchedulingType,
  MessageType,
} from '../../../types/campaign.types';
import { timeAgo } from '../../../utils/timeUtils';

interface CampaignCardProps {
  campaignId: string;
  schedulingType: ExecutionSchedulingType;
  campaignTitle: string;
  campaignState: CampaignState;
  stats: CampaignStats;
  messageType: MessageType;
  createTime: string;
  progress: ExecutionProgress;
  onDetailsClick: () => void;
}

const CampaignCard: React.FC<CampaignCardProps> = ({
  campaignState,
  campaignId,
  schedulingType,
  campaignTitle,
  stats,
  messageType,
  createTime,
  progress,
  onDetailsClick,
}) => {
  // const theme = useTheme();
  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Chip
          label={CampaignStateMapping[campaignState]}
          color={CampaignStateColorMapping[campaignState]}
        />
        <Chip label={`ID ${campaignId}`} variant="outlined" />
      </Box>
      <Box
        sx={{
          p: 2,
          whiteSpace: {
            xs: 'initial', // For xs and smaller screens
            md: 'nowrap', // For screens larger than xs
          },
        }}
      >
        <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary">
          {ExecutionSchedulingTypeMapping[schedulingType]}
        </Typography>
        <Typography variant="h6">{campaignTitle}</Typography>
      </Box>

      {progress && (
        <Box width={'100%'} height={150}>
          <ResponsiveFunnel
            data={[
              {
                id: 'Targeted',
                label: 'Targeted',
                value: progress.targetingSucceededCount,
              },
              {
                id: 'Rendered',
                label: 'Rendered',
                value: progress.renderingSucceededCount,
              },
              {
                id: 'Delivered',
                label: 'Delivered',
                value: progress.deliverySucceededCount,
              },
            ]}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            direction="horizontal"
            colors={{
              scheme: 'greens',
              // theme.palette.info.light,
              // theme.palette.info.main,
              // theme.palette.info.dark,
            }}
            borderWidth={20}
            labelColor={{
              from: 'color',
              modifiers: [['darker', 3]],
            }}
            beforeSeparatorLength={100}
            beforeSeparatorOffset={20}
            afterSeparatorLength={100}
            afterSeparatorOffset={20}
            currentPartSizeExtension={10}
            currentBorderWidth={40}
            motionConfig="wobbly"
          />
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Tooltip title={`Created at ${createTime}`}>
            <Typography
              component="div"
              variant="body2"
              sx={{
                alignItems: 'center',
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'none',
                  lg: 'flex',
                  xl: 'flex',
                },
              }}
              color="text.secondary"
            >
              <EventIcon sx={{ mr: 0.5 }} />
              {timeAgo(createTime)}
            </Typography>
          </Tooltip>
          <Tooltip title={`Delivered People Count: ${stats.deliveredCount}`}>
            <Typography
              component="div"
              variant="body2"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="text.secondary"
            >
              <PeopleIcon sx={{ mr: 0.5 }} />
              {stats.deliveredCount}
            </Typography>
          </Tooltip>

          <Tooltip title={`Ads Click Count: ${stats.clickCount}`}>
            <Typography
              component="div"
              variant="body2"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="text.secondary"
            >
              <AdsClickIcon sx={{ mr: 0.5 }} />
              {stats.clickCount}
            </Typography>
          </Tooltip>

          <Tooltip title={`Visibility Count: ${stats.viewCount}`}>
            <Typography
              component="div"
              variant="body2"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="text.secondary"
            >
              <VisibilityIcon sx={{ mr: 0.5 }} />
              {stats.viewCount}
            </Typography>
          </Tooltip>
          <Tooltip title={`${MessageTypeMapping[messageType]}`}>
            <Typography
              component="div"
              variant="body2"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="text.secondary"
            >
              {messageType === MessageType.EMAIL ? (
                <Email sx={{ mr: 0.5 }} />
              ) : (
                <Sms sx={{ mr: 0.5 }} />
              )}
            </Typography>
          </Tooltip>
        </Box>

        <Button variant="text" color="secondary" onClick={onDetailsClick}>
          Details
        </Button>
      </Box>
    </Card>
  );
};

export default CampaignCard;
