import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import React from 'react';
import { ExecutionSchedulingType } from '../../../types/campaign.types';
import CampaignScheduleCustom from './CampaignScheduleCustom';
import CampaignScheduleRecurring from './CampaignScheduleRecurring';

interface CampaignScheduleProps {
  errors: FormikErrors<{}>;
  values: FormikValues;
  handleChange: any;
}

const CampaignSchedule: React.FC<CampaignScheduleProps> = ({
  errors,
  values,
  handleChange,
}) => {
  return (
    <>
      {values.schedulingType ===
        ExecutionSchedulingType.RECURRING_EXECUTION && (
        <CampaignScheduleRecurring
          errors={errors}
          values={values}
          handleChange={handleChange}
        />
      )}
      {values.schedulingType ===
        ExecutionSchedulingType.CUSTOM_SCHEDULED_EXECUTION && (
        <CampaignScheduleCustom
          errors={errors}
          values={values}
          handleChange={handleChange}
        />
      )}
      {values.schedulingType ===
        ExecutionSchedulingType.IMMEDIATE_EXECUTION && (
        <>
          <Box sx={{ my: 5, mx: 2 }}>
            <Alert variant="outlined" severity="info">
              This campaign will be executed immediately after creation.
            </Alert>
          </Box>
          <Box sx={{ my: 5, mx: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  defaultChecked={values.ignoreMarketingLimit}
                  onChange={handleChange('ignoreMarketingLimit')}
                />
              }
              label={
                <Typography variant="body2">
                  Ignore Global Marketing Frequency Limit
                </Typography>
              }
            />
          </Box>
        </>
      )}
    </>
  );
};

export default CampaignSchedule;
