import { gql, useMutation } from '@apollo/client';
import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FormikWizard } from 'formik-wizard-form';
import React from 'react';
import * as Yup from 'yup';
import SelectTemplateKind from '../../../components/Template/CreateTemplateForm/SelectTemplateKind';
import UploadTemplateForm from '../../../components/Template/CreateTemplateForm/UploadTemplate';
import useOperator from '../../../hooks/useOperator';
import {
  ContentKind,
  CreateLibraryTemplateInput,
  TemplateUploadType,
} from '../../../types/template.types';

interface CreateTemplateProps {
  closeHandler: () => void;
  refreshHandler: () => void;
}

const CreateTemplate: React.FC<CreateTemplateProps> = ({
  closeHandler,
  refreshHandler,
}) => {
  const [, setFinished] = React.useState(false);
  const [creating, setCreating] = React.useState(false);
  const stepLabels = ['Template Kind', 'Upload'];
  const [isErrorWindowOpen, setIsErrorWindowOpen] = React.useState(false);

  const handleErrorOpen = () => {
    setIsErrorWindowOpen(true);
  };

  const handleErrorClose = () => {
    setIsErrorWindowOpen(false);
  };
  const isSmallerThanMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const INITIATE_UPLOAD = gql`
    mutation InitiateUpload(
      $operatorId: ID!
      $sizeBytes: Int!
      $type: UploadType!
    ) {
      initiateUpload(
        input: { operatorId: $operatorId, sizeBytes: $sizeBytes, type: $type }
      ) {
        id
        operatorId
        type
        url
      }
    }
  `;
  const [operator] = useOperator();
  const [initiateUpload] = useMutation(INITIATE_UPLOAD);

  const CREATE_TEMPLATE = gql`
    mutation CreateTemplate($input: CreateLibraryTemplateInput!) {
      createLibraryTemplate(input: $input) {
        id
      }
    }
  `;
  const [createTemplate] = useMutation(CREATE_TEMPLATE);

  const createTemplateButtonClicked = async (values: any) => {
    setCreating(true);

    const jsonData = {
      subject: values.subject,
      body: values.template,
    };
    const jsonString = JSON.stringify(jsonData);
    const selectedFile = new Blob([jsonString], {
      type: 'application/json',
    });
    const type =
      values.templateKind === ContentKind.EMAIL
        ? TemplateUploadType.EMAIL_TEMPLATE
        : values.templateKind === ContentKind.SMS
        ? TemplateUploadType.SMS_TEMPLATE
        : TemplateUploadType.WEB_PUSH_TEMPLATE;
    try {
      const { data } = await initiateUpload({
        variables: {
          operatorId: operator?.id || '',
          sizeBytes: selectedFile?.size,
          type: type,
        },
      });

      const presignedUrl = data.initiateUpload.url;
      const file = selectedFile;

      const headers = {
        'x-amz-meta-uploadtype': type,
        'x-amz-meta-operatorid': operator?.id || '',
        'Content-Type': 'application/json',
        'Content-Length': selectedFile?.size?.toString() || '',
      };

      await fetch(presignedUrl, {
        method: 'PUT',
        headers,
        body: file,
        mode: 'cors',
      }).then((response) => {
        if (response.ok) {
          console.log('File uploaded successfully');

          const createLibraryTemplateInput: CreateLibraryTemplateInput = {
            operatorId: operator?.id || '',
            title: values.title,
            description: values.description,
            contentKind: values.templateKind,
            uploadId: data.initiateUpload.id,
            senderProfileId: values.senderProfileId,
          };
          const createTemplateFunction = async () => {
            try {
              await createTemplate({
                variables: {
                  input: createLibraryTemplateInput,
                },
              });
              closeHandler();
              refreshHandler();
              setCreating(false);
            } catch (error: any) {
              setCreating(false);
              handleErrorOpen();
              console.error('Error creating template:', error);
            }
          };
          createTemplateFunction();
        } else {
          throw new Error(`File upload failed with status: ${response.status}`);
        }
      });
    } catch (error) {
      console.log('Error uploading file', error);
      setCreating(false);
    }
  };

  return (
    <Card>
      <Box p={2}>
        <Typography variant="h6">Create Template</Typography>
        <Dialog
          open={isErrorWindowOpen}
          onClose={handleErrorClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">ERROR</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Template couldn't be created. Please try again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleErrorClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Box mt={2} sx={{}}>
          <FormikWizard
            initialValues={{
              templateKind: '',
              template: '',
              title: '',
              description: '',
              templateUploadId: '',
            }}
            onSubmit={(values) => {
              setFinished(true);
              createTemplateButtonClicked(values);
            }}
            validateOnNext
            activeStepIndex={0}
            steps={[
              {
                component: SelectTemplateKind,
                validationSchema: Yup.object().shape({
                  templateKind: Yup.string().required('Required'),
                }),
              },
              {
                component: UploadTemplateForm,
                validationSchema: Yup.object().shape({
                  title: Yup.string().required('Required'),
                  senderProfileId: Yup.string().required('Required'),
                }),
              },
            ]}
          >
            {({
              currentStepIndex = 0,
              renderComponent,
              handlePrev,
              handleNext,

              isPrevDisabled,
              isLastStep,
            }) => {
              return (
                <>
                  <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={currentStepIndex}>
                      <Step completed={currentStepIndex > 0}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[0]}
                        </StepLabel>
                      </Step>
                      <Step completed={currentStepIndex > 1}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[1]}
                        </StepLabel>
                      </Step>
                    </Stepper>
                  </Box>
                  <Box mt={2} display={{ md: 'none' }}>
                    <Typography variant="button">
                      {stepLabels[currentStepIndex]}
                    </Typography>
                  </Box>
                  <Box>{renderComponent()}</Box>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                      <Button variant="contained" onClick={closeHandler}>
                        Cancel
                      </Button>
                    </Box>
                    <Box display="flex">
                      <Button
                        sx={{ mr: '1rem' }}
                        variant="contained"
                        disabled={isPrevDisabled || creating}
                        onClick={handlePrev}
                      >
                        Previous
                      </Button>
                      {isLastStep ? (
                        <LoadingButton
                          loading={creating}
                          loadingPosition="start"
                          startIcon={<Add />}
                          variant="contained"
                          onClick={handleNext}
                        >
                          Create
                        </LoadingButton>
                      ) : (
                        <Button variant="contained" onClick={handleNext}>
                          Next
                        </Button>
                      )}
                    </Box>
                  </Box>
                </>
              );
            }}
          </FormikWizard>
        </Box>
      </Box>
    </Card>
  );
};

export default CreateTemplate;
