import { gql } from '@apollo/client';

export const GET_CAMPAIGN_BY_ID = gql`
  query GetCampaignById($operatorId: ID!, $id: ID!) {
    operator(id: $operatorId) {
      campaign(id: $id) {
        id
        title
        state
        description
        createTime
        messageType
        templateUrls {
          previewUrl
        }
        executionSpec {
          customScheduling {
            startTimes
          }
          schedulingType
            recurringScheduling {
            period {
              monthly {
                day {
                  hour {
                    minute {
                      value
                    }
                    value
                  }
                  value
                }
              }
              daily {
                hour {
                  minute {
                    value
                  }
                  value
                }
              }
              weekly {
                day {
                  hour {
                    minute {
                      value
                    }
                    value
                  }
                  value
                }
              }
            }
            startTime
            endTime
          }
        }
        targetingSpec {
          excludedSegments{
            id
            description
            title
          }
          includedSegments{
            id
            description
            title
          }
          ignoreMarketingLimits
        }
        stats {
          clickCount
          deliveredCount
          filteredCount
          targetedCount
          viewCount
        }
        deliverySpec {
          connectionId
        }
        templatingSpec {
          libraryTemplateId
          templateContent{
            senderProfileId
          }
        }

        executions {
          items {
            deliveryCompleteTime
            error
            errorTime
            startTime
            targetingCompleteTime
            messageProcessingCompleteTime
            isAborted
            scheduleTime
            id
            isDeadlineMissed
            messageType
            state
            stats {
              clickCount
              deliveredCount
              filteredCount
              targetedCount
              viewCount
            }
            progress {
              deliveryExpectedCount
              deliveryFailedCount
              deliverySucceededCount
              deliveryRejectedCount
              renderingExpectedCount
              renderingFailedCount
              renderingSucceededCount
              targetingExpectedCount
              targetingFailedCount
              targetingSucceededCount
            }
          }
        }
        progress {
              deliveryExpectedCount
              deliveryFailedCount
              deliverySucceededCount
              deliveryRejectedCount
              renderingExpectedCount
              renderingFailedCount
              renderingSucceededCount
              targetingExpectedCount
              targetingFailedCount
              targetingSucceededCount
            }
      }
    }
  }
`;

export const GET_CURRENT_CAMPAIGNS = gql`
  query GetCurrentCampaigns($operatorId: ID!, $status: CurrentCampaignStatus, $nextToken: ID) {
    operator(id: $operatorId) {
      currentCampaigns(status: $status, nextToken: $nextToken ) {
        items {
          id
          title
          state
          description
          createTime
          messageType
          executionSpec {
            schedulingType
          }
          stats {
            clickCount
            deliveredCount
            filteredCount
            targetedCount
            viewCount
          }
          progress {
            deliveryExpectedCount
            deliveryFailedCount
            deliverySucceededCount
            deliveryRejectedCount
            renderingExpectedCount
            renderingFailedCount
            renderingSucceededCount
            targetingExpectedCount
            targetingFailedCount
            targetingSucceededCount
          }

        }
        nextToken

        
      }
    }
  }
`;

export const GET_ARCHIVED_CAMPAIGNS = gql`
  query GetArchivedCampaigns($operatorId: ID!, $nextToken: ID) {
    operator(id: $operatorId) {
      archivedCampaigns(nextToken: $nextToken) {
        items {
          id
          title
          state
          description
          createTime
          messageType
          executionSpec {
            schedulingType
          }
          stats {
            clickCount
            deliveredCount
            filteredCount
            targetedCount
            viewCount
          }
          progress {
            deliveryExpectedCount
            deliveryFailedCount
            deliverySucceededCount
            deliveryRejectedCount
            renderingExpectedCount
            renderingFailedCount
            renderingSucceededCount
            targetingExpectedCount
            targetingFailedCount
            targetingSucceededCount
          }
        }
        
        nextToken
      }
    }
  }
`;

export const GET_CAMPAIGNS_COUNT = gql`
  query GetCampaignsCount($operatorId: ID!) {
    operator(id: $operatorId) {
      campaignsCount
      activeCampaignsCount
    }
  }
`;

export const GET_CUSTOMER_COUNT = gql`
query GetCustomerCount($operatorId: ID!) {
    operator(id: $operatorId) {
      customersCount,
     }
  }
`;