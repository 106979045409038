import { gql } from "@apollo/client";

export const GET_TEMPLATE_BY_ID = gql`
  query GetTemplateById($operatorId: ID!, $id: ID!) {
    operator(id: $operatorId) {
      libraryTemplate(id: $id) {
        id 
        title
        description
        contentKind
        status
        createTime
        templateUrls {
          contentUrl
          previewUrl
          thumbnailUrl
        }
        senderProfile {
            id
            title
            kind
            emailProfile{
                fromAddress
                fromName
            }
            smsProfile{
                sender
            }
        }
      }
    }
  }`

export const GET_LIBRARY_TEMPLATES = gql`
  query GetLibraryTemplates($operatorId: ID!, $nextToken: ID) {
    operator(id: $operatorId) {
      libraryTemplates(nextToken: $nextToken) {
        items {
          id
          title
          description
          createTime
          contentKind
          status
          templateUrls {
            contentUrl
            previewUrl
            thumbnailUrl
          }
        }
        nextToken
      }
    }
  }
`;

export const GET_TEMPLATES_STATS = gql`
  query GetOperatorStats($operatorId: ID!) {
    operator(id: $operatorId) {
      libraryTemplatesCount
    }
  }
`;