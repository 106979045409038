import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
  createFilterOptions,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { Attribute } from '../../../../../../types/segment.types';

interface SelectInputProps {
  value: string[] | null;
  onChange: any;
  attribute: Attribute;
  disabled?: boolean;
  error?: string;
  options: _.Dictionary<{
    label: string;
    value: string[];
    id: string;
  }>;
}

const SelectInput: React.FC<SelectInputProps> = ({
  onChange,
  value,
  error,
  options,
}) => {
  const valueId = value ? value.join(',') : '';
  const [inputValue, setInputValue] = useState(options[valueId || '']?.label);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (o: string) => options[o].label,
  });

  return (
    <FormControl fullWidth error={error ? true : false}>
      <Autocomplete
        size="small"
        fullWidth
        options={_.keys(options)}
        value={valueId || null}
        onChange={(__, newValue) => {
          if (newValue !== null) {
            setInputValue(options[newValue]?.label || '');
            onChange({ target: { value: options[newValue].value } });
          }
        }}
        inputValue={inputValue || ''}
        getOptionLabel={(option) => options[option || '']?.label || ''}
        autoComplete
        onInputChange={(__, newInputValue) => {
          setInputValue(newInputValue);
        }}
        filterOptions={filterOptions}
        renderInput={(params) => <TextField {...params} placeholder="Type" />}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default SelectInput;
