import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from "yup";
import { ReactComponent as Logo } from '../../../../assets/branding/logo-text-light.svg';
import { ControlledTextField } from '../../../../components/form';
import { SignInFormValues } from '../../types';

type Props = {
    onSubmit: (formValues: SignInFormValues) => void
    hasError: boolean
    isLoading: boolean
}

const schema = yup.object({
    email: yup.string().required(),
    password: yup.string().required(),
}).required();

export default function SignInForm({ onSubmit, isLoading, hasError }: Props) {
    const [t] = useTranslation("signIn");

    const form = useForm<SignInFormValues>({
        shouldUnregister: true,
        defaultValues: {
            email: "",
            password: ""
        },
        resolver: yupResolver(schema)
    })

    return (
        <Box component="form" onSubmit={form.handleSubmit(onSubmit)} sx={{ maxWidth: "400px", alignSelf: "center", p: 2 }}>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, mb: 2 }}>
                <Logo />
            </Box>
            <Typography variant="h5" fontWeight={700}>{t("FORM::TITLE", { appName: "Clicktelligence" })}</Typography>
            <ControlledTextField<SignInFormValues> control={form.control} name="email" label={t("FORM::INPUTS::EMAIL::LABEL")} type="email" sx={{ mt: 4 }} />
            <ControlledTextField<SignInFormValues> control={form.control} name="password" label={t("FORM::INPUTS::PASSWORD::LABEL")} type="password" sx={{ mt: 2 }} />
            {hasError && (
                <Box sx={{ mt: 2 }}>
                    <Alert severity="error">{t("FORM::ERRORS::INVALID_CREDENTIALS")}</Alert>
                </Box>
            )}
            <Box>
                <Button
                    sx={{ mt: 2, float: "right" }}
                    variant="text"
                >
                    {t("FORM::BUTTONS::FORGOT_PASSWORD")}
                </Button>
                <Button
                    sx={{ mt: 2 }}
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                >
                    {t("FORM::BUTTONS::SUBMIT")}
                </Button>
            </Box>
        </Box>
    )
}