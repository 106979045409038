import { Close, Event } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import WebPushIcon from '@mui/icons-material/Web';
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { TemplateStatusMapping } from '../../../mappings';
import {
  LibraryTemplateStatus,
  LibraryTemplateUrls,
} from '../../../types/template.types';
import { timeAgo } from '../../../utils/timeUtils';

interface TemplateCardProps {
  templateTitle: string;
  templateDescription: string;
  templateStatus: LibraryTemplateStatus;
  templateUrls: LibraryTemplateUrls;
  createTime: string;
  contentKind: string;

  onDetailsClick: () => void;
}

const ContentKindIconMapping: Record<string, React.ElementType> = {
  Email: EmailIcon,
  SMS: SmsIcon,
  WEB_PUSH: WebPushIcon,
};

const TemplateCard: React.FC<TemplateCardProps> = ({
  templateStatus,
  templateUrls,
  templateTitle,
  templateDescription,
  createTime,
  contentKind,
  onDetailsClick,
}) => {
  const ContentKindIcon = ContentKindIconMapping[contentKind] || EmailIcon;
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

  const handleImageClick = () => {
    setIsImagePreviewOpen(true);
  };

  const handleCloseImagePreview = () => {
    setIsImagePreviewOpen(false);
  };
  return (
    <Card>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                }}
              >
                <Chip
                  label={TemplateStatusMapping[templateStatus]}
                  color={
                    templateStatus === LibraryTemplateStatus.READY
                      ? 'success'
                      : 'warning'
                  }
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                }}
              >
                <Typography variant="h5" noWrap>
                  {templateTitle}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ p: 2 }}
                noWrap
              >
                {templateDescription}
                &nbsp;
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '100%',
              pr: 1.5,
              mt: 1,
            }}
          >
            <img
              onClick={handleImageClick}
              style={{
                cursor: 'pointer',
                borderRadius: '4px',
                objectFit: 'fill',
              }}
              src={
                templateUrls.previewUrl
                  ? templateUrls.previewUrl
                  : 'https://placehold.co/170'
              }
              height={'200'}
              width={'100%'}
              alt="Template preview"
            />
          </Box>
        </Grid>
        <Dialog
          open={isImagePreviewOpen}
          onClose={handleCloseImagePreview}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography variant="subtitle1">{templateTitle}</Typography>
            </div>

            <IconButton onClick={handleCloseImagePreview}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ position: 'relative' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                style={{
                  objectPosition: 'cover',
                }}
                src={
                  templateUrls.previewUrl
                    ? templateUrls.previewUrl
                    : 'https://placehold.co/500'
                }
                height={'95%'}
                width={'95%'}
                alt="Template preview"
              />
            </Box>
          </DialogContent>
        </Dialog>

        <Grid item xs={12} mb={1} ml={1} mr={1}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Tooltip title={`Created at ${createTime}`}>
                <Typography
                  variant="body2"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.secondary"
                >
                  <Event sx={{ mr: 0.5 }} />
                  {timeAgo(createTime)}
                </Typography>
              </Tooltip>
              <Tooltip title={`Template Type: ${contentKind}`}>
                <Typography
                  variant="body2"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.secondary"
                  ml={1}
                >
                  <ContentKindIcon sx={{ mr: 0.5 }}>
                    {contentKind}
                  </ContentKindIcon>
                </Typography>
              </Tooltip>
            </Box>
            <Button variant="text" color="secondary" onClick={onDetailsClick}>
              Details
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TemplateCard;
