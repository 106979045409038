import { gql, useLazyQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { AwsAmplifyUser } from '../types/awsAmplify.types';
import { Languages } from '../types/language.types';
import { CurrentOperator } from '../types/operator.types';

export type Permission = 'MANAGEMENT';
export interface AuthenticatedUser {
  language: Languages;
  isSuperUser: boolean;
  email: string;
  name: string;
  authorizations: Record<string, Permission[]>;
}

const GET_OPERATOR = gql`
  query GetOperator($id: ID!) {
    operator(id: $id) {
      id
      title
      timezone
    }
  }
`;

/**
 * Custom React hook for fetching authenticated user details from AWS Cognito.
 * @returns
 * - user: AuthenticatedUser | null - the authenticated user details or null if not authenticated
 * - hasError: boolean - is true in any error that occurs while fetching user
 * - isLoading: boolean - is true while fetching user data
 */
const useAuthenticatedUser = () => {
  const [user, setUser] = useState<AuthenticatedUser | null>(null);
  const [hasError, setError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  const [getOperator] = useLazyQuery<
    { operator: CurrentOperator },
    { id: string }
  >(GET_OPERATOR);

  useEffect(() => {
    const fetchUser = async () => {
      // set loading to true while we fetch user
      setLoading(true);
      try {
        const awsUser: AwsAmplifyUser = await Auth.currentAuthenticatedUser();
        const attributes = awsUser.attributes;

        // parse user attributes
        const parsedUser: AuthenticatedUser = {
          name: attributes.name,
          email: attributes.email,
          isSuperUser: attributes['custom:ct_superuser'] === 'true',
          language: attributes['custom:ct_language'] as Languages,
          authorizations: JSON.parse(attributes['custom:ct_authorizations']),
        };
        const res = await getOperator({
          variables: { id: Object.keys(parsedUser.authorizations)[0] },
        });

        localStorage.setItem('operator', JSON.stringify(res.data?.operator));

        setUser(parsedUser);
        // reset error state
        setError(false);
        // reset loading state
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user: ', err);
        // set error state
        setError(true);
        // reset loading state
        setLoading(false);
      }
    };

    fetchUser();
  }, [getOperator]);

  return { user, hasError, isLoading };
};

export default useAuthenticatedUser;
