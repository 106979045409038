import { useMutation, gql } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import useOperator from '../../../hooks/useOperator';

interface DeleteSegmentButtonProps {
  segmentId: string;
  onSuccess?: () => void;
  onError?: (message: string) => void;

  label?: string;
  showLabel?: boolean;
  showIcon?: boolean;
  btnProps?: LoadingButtonProps;
}

const DELETE_SEGMENT = gql`
  mutation DeleteSegment($input: DeleteSegmentInput!) {
    deleteSegment(input: $input) {
      id
    }
  }
`;

const DeleteSegmentButton: React.FC<DeleteSegmentButtonProps> = ({
  segmentId,
  onSuccess,
  onError,
  label = 'Delete',
  showLabel = true,
  showIcon = true,
  btnProps,
}) => {
  const [operator] = useOperator();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [deleteSegment, { loading, error, reset }] = useMutation(
    DELETE_SEGMENT,
    {
      onCompleted: () => {
        setDialogOpen(false);
        onSuccess && onSuccess();
      },
      onError: (error) => {
        onError && onError(error.message);
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = () => {
    deleteSegment({
      variables: {
        input: { operatorId: operator!.id, id: segmentId! },
      },
    });
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Delete Segment</DialogTitle>
        <DialogContent>
          {error ? (
            <Typography color="error">{error.message}</Typography>
          ) : (
            <DialogContentText>
              Segment will be deleted. Would you like to continue?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <LoadingButton
            startIcon={<Delete />}
            loadingPosition="start"
            loading={loading}
            onClick={handleDelete}
            autoFocus
            disabled={error ? true : false}
          >
            {label}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button
        startIcon={showIcon ? <Delete /> : undefined}
        {...btnProps}
        onClick={() => {
          reset();
          setDialogOpen(true);
        }}
      >
        {showLabel && label}
      </Button>
    </>
  );
};
export default DeleteSegmentButton;
