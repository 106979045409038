import { gql, useQuery } from '@apollo/client';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import useOperator from '../../../../hooks/useOperator';
import {
  RuleSet,
  RuleSetInput,
  SegmentInput,
} from '../../../../types/segment.types';
import SegmentRulesView from '../../SegmentRulesView';

interface SegmentReviewProps {
  errors: FormikErrors<{}>;
  handleChange: any;
}

interface SegmentCustomerCountQueryVariables {
  operatorId: string;
  ruleSets: RuleSetInput[];
}

type SegmentCustomerCountQueryResponse = {
  operator: {
    segmentCustomersCount: number;
  };
};

const SEGMENT_CUSTOMERS_COUNT = gql`
  query SegmentCustomersCount($operatorId: ID!, $ruleSets: [RuleSetInput!]!) {
    operator(id: $operatorId) {
      segmentCustomersCount(ruleSets: $ruleSets)
    }
  }
`;

const removeKeys = (ruleSets: RuleSetInput[]): any => {
  if (_.isObject(ruleSets)) {
    if (_.isArray(ruleSets)) {
      return _.map(ruleSets, removeKeys);
    } else {
      return _.mapValues(_.omit(ruleSets, 'key'), removeKeys);
    }
  } else {
    return ruleSets;
  }
};

const SegmentReview: React.FC<SegmentReviewProps> = () => {
  const [operator] = useOperator();
  const { values } = useFormikContext<SegmentInput>();

  const { data, loading } = useQuery<
    SegmentCustomerCountQueryResponse,
    SegmentCustomerCountQueryVariables
  >(SEGMENT_CUSTOMERS_COUNT, {
    skip: !values,
    variables: {
      operatorId: operator!.id,
      ruleSets: removeKeys(values.ruleSets as RuleSetInput[]),
    },
  });
  return (
    <Grid
      container
      spacing={5}
      sx={{
        py: {
          md: 5,
          xs: 2,
        },
        px: 2,
      }}
    >
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ mb: 2, p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Stack>
                <Typography variant="caption">Operator</Typography>
                <Typography noWrap variant="body1">
                  {operator?.title}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack>
                <Typography variant="caption">Title</Typography>
                <Typography noWrap variant="body1">
                  {values.title}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Stack>
                <Typography variant="caption">Description</Typography>
                <Typography noWrap variant="body1">
                  {values.description || '-'}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack>
                <Typography variant="caption">Customer Count</Typography>
                <Typography variant="body1">
                  {loading
                    ? 'Calculating...'
                    : data?.operator.segmentCustomersCount}{' '}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Paper>

        <Grid item xs={12}>
          <SegmentRulesView
            ruleSets={values.ruleSets as RuleSet[]}
            height={400}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SegmentReview;
