import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

interface Props {
  value: number | null;
  expectedValue: number;
  customSize?: number;
}

const CustomCircularProgress: React.FC<Props> = ({
  value,
  expectedValue,
  customSize,
}) => {
  const size = customSize || 200;
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
      }}
    >
      <CircularProgress
        variant="determinate"
        thickness={3}
        value={((value || 0) * 100) / expectedValue}
        sx={{
          position: 'relative',
          zIndex: 2,
          svg: {
            color: 'primary.main',
            circle: {},
          },
        }}
        size={size}
      />
      <CircularProgress
        variant="determinate"
        value={100}
        thickness={3}
        size={size}
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: 0,
          svg: {
            color: 'lightgray',
            circle: {
              strokeDashoffset: '0px !important',
            },
          },
        }}
      />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant={customSize ? (customSize > 75 ? 'h4' : 'body1') : 'h4'}
          component="div"
          color="text.secondary"
        >
          {value !== null ? Math.round(value).toLocaleString() : '-'}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomCircularProgress;
