import { Grid, TextField } from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import React from 'react';

interface SegmentTitleProps {
  errors: FormikErrors<{
    title: string;
    description: string;
  }>;
  values: FormikValues;
  handleChange: any;
}

const SegmentTitle: React.FC<SegmentTitleProps> = ({
  errors,
  values,
  handleChange,
}) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        py: {
          md: 5,
          xs: 2,
        },
      }}
    >
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="title"
          required
          label="Title"
          variant="standard"
          error={!!errors.title}
          helperText={errors.title}
          value={values.title}
          onChange={handleChange('title')}
          inputProps={{ maxLength: 120 }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="description"
          label="Description"
          variant="standard"
          error={!!errors.description}
          helperText={errors.description}
          value={values.description}
          onChange={handleChange('description')}
          inputProps={{ maxLength: 4096 }}
        />
      </Grid>
    </Grid>
  );
};

export default SegmentTitle;
