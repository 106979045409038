import { gql, useMutation } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Segment } from '../../../types/segment.types';
import { time } from '../../../utils/timeUtils';
import CustomCircularProgress from '../../CustomCircularProgress/CustomCircularProgress';

interface SegmentCustomersCountCardProps {
  segment: Segment;
  totalCustomersCount: number;
}

interface UpdateSegmentCustomersCountMutationResponse {
  updateSegmentCustomersCount: {
    customersCount: number;
    customersCountUpdateTime: string;
  };
}

const CountWarningLimit = 1000;
const PercentageWarningLimit = 10;

interface UpdateSegmentCustomersCountMutationVariables {
  operatorId: string;
  segmentId: string;
}

const UPDATE_SEGMENT_CUSTOMERS_COUNT = gql`
  mutation UpdateSegmentCustomersCount($operatorId: ID!, $segmentId: ID!) {
    updateSegmentCustomersCount(
      operatorId: $operatorId
      segmentId: $segmentId
    ) {
      customersCount
      customersCountUpdateTime
    }
  }
`;

const SegmentCustomersCountCard: React.FC<SegmentCustomersCountCardProps> = ({
  segment,
  totalCustomersCount,
}) => {
  const [count, setCount] = useState<number | null>(null);
  const [countUpdateTime, setCountUpdateTime] = useState<string | null>(null);

  const [updateSegmentCustomersCount, { loading }] = useMutation<
    UpdateSegmentCustomersCountMutationResponse,
    UpdateSegmentCustomersCountMutationVariables
  >(UPDATE_SEGMENT_CUSTOMERS_COUNT, {
    onCompleted: (data) => {
      setCount(data.updateSegmentCustomersCount.customersCount);
      setCountUpdateTime(
        data.updateSegmentCustomersCount.customersCountUpdateTime,
      );
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setCount(segment.customersCount);
  }, [segment.customersCount]);

  useEffect(() => {
    setCountUpdateTime(segment.customersCountUpdateTime);
  }, [segment.customersCountUpdateTime]);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography
        variant="h6"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        Customers Count
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          p: 2,
        }}
      >
        <CustomCircularProgress
          value={count}
          expectedValue={totalCustomersCount}
        />
      </Box>

      <Stack
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingButton
          startIcon={<Refresh />}
          sx={{ my: 1 }}
          loadingPosition="start"
          loading={loading}
          onClick={() =>
            updateSegmentCustomersCount({
              variables: {
                operatorId: segment.operatorId,
                segmentId: segment.id,
              },
            })
          }
        >
          Refresh
        </LoadingButton>
        <Typography variant="body2" color="text.secondary" sx={{ my: 0.5 }}>
          {countUpdateTime
            ? `Last updated at ${time(countUpdateTime)}`
            : 'Customer count not updated yet'}
        </Typography>
        {count !== null && count > CountWarningLimit && (
          <Alert
            variant="outlined"
            severity="warning"
            sx={{ width: '100%', mt: 2 }}
          >
            <Typography variant="body2" color="text.secondary">
              This segment has more than {CountWarningLimit.toLocaleString()}{' '}
              customers.
            </Typography>
          </Alert>
        )}
        {count !== null &&
          ((count || 0) * 100) / totalCustomersCount >
            PercentageWarningLimit && (
            <Alert
              variant="outlined"
              severity="warning"
              sx={{ width: '100%', mt: 2 }}
            >
              <Typography variant="body2" color="text.secondary">
                This segment has more customers than {PercentageWarningLimit}%
                of your total customers.
              </Typography>
            </Alert>
          )}
        {count !== null && (
          <Alert
            variant="outlined"
            severity="info"
            sx={{ width: '100%', mt: 2 }}
          >
            <Typography variant="body2" color="text.secondary">
              Number of customers in a segment might change over time.
            </Typography>
          </Alert>
        )}
        {count !== null && (
          <Alert
            variant="outlined"
            severity="info"
            sx={{ width: '100%', mt: 2 }}
          >
            <Typography variant="body2" color="text.secondary">
              Some customers might be filtered out at campaign execution due to
              their communication preferences, marketing limits, etc.
            </Typography>
          </Alert>
        )}
      </Stack>
    </Paper>
  );
};

export default SegmentCustomersCountCard;
