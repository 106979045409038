import { People } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import {
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Segment } from '../../../types/segment.types';
import { time, timeAgo } from '../../../utils/timeUtils';

interface SegmentCardProps {
  segment: Segment;
}

const SegmentCard: React.FC<SegmentCardProps> = ({ segment }) => {
  const navigate = useNavigate();
  const isSmallerThanSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Chip label={`ID: ${segment.id}`} variant="outlined" />
      </Box>
      <Typography variant="h6" sx={{ mt: 1 }} color="text.primary" noWrap>
        {segment.title}
      </Typography>
      <Typography
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          lineClamp: 2,
          WebkitLineClamp: 2,
          lineHeight: '1.5em',
          minHeight: '3em',
          mt: 2,
        }}
        paragraph
        gutterBottom
        color="text.secondary"
        variant="body1"
      >
        {segment.description}
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction={isSmallerThanSm ? 'column' : 'row'} spacing={2}>
          <Tooltip
            enterDelay={1000}
            title={`Created at ${time(segment.createTime)}`}
          >
            <Typography
              variant="body2"
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
              color="text.secondary"
            >
              <EventIcon sx={{ mr: 0.5 }} />
              {timeAgo(segment.createTime)}
            </Typography>
          </Tooltip>
          <Tooltip
            enterDelay={1000}
            title={
              segment.customersCountUpdateTime !== null
                ? `Last updated at ${time(segment.customersCountUpdateTime)}`
                : 'Customer count not updated yet'
            }
          >
            <Typography
              variant="body2"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="text.secondary"
            >
              <People sx={{ mr: 0.5 }} />
              {segment.customersCount !== null
                ? segment.customersCount?.toLocaleString()
                : null}
            </Typography>
          </Tooltip>
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <Button
            variant="text"
            color="secondary"
            onClick={() => navigate(`/segments/${segment.id}`)}
          >
            Details
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
};

export default SegmentCard;
