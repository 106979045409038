import { gql, useMutation, useQuery } from '@apollo/client';
import { Add, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FormikValues } from 'formik';
import { FormikWizard } from 'formik-wizard-form';
import _ from 'lodash';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import useOperator from '../../../hooks/useOperator';
import { SchemasProvider } from '../../../layers/SchemasLayer';
import { Segment, SegmentInput } from '../../../types/segment.types';
import Definition from './steps/Definition';
import SegmentReview from './steps/Review';
import SegmentTitle from './steps/Title';

interface SegmentEditorProps {
  segmentId?: string;
  onCancel: () => void;
  onSuccess: () => void;
}

interface SegmentDetailQueryVariables {
  operatorId: string;
  segmentId: string;
}

type SegmentDetailQueryResponse = {
  operator: {
    segment: Segment;
  };
};

// Define the GraphQL query
const GET_SEGMENT = gql`
  query GetSegment($operatorId: ID!, $segmentId: ID!) {
    operator(id: $operatorId) {
      segment(id: $segmentId) {
        operatorId
        id
        title
        description
        ruleSets {
          logic
          set
          rules {
            logic
            conditions {
              attributeId
              negate
              relation
              values
              preconditions {
                attributeId
                negate
                relation
                values
              }
            }
          }
        }
      }
    }
  }
`;

const CREATE_SEGMENT = gql`
  mutation CreateSegment($input: CreateSegmentInput!) {
    createSegment(input: $input) {
      id
    }
  }
`;

const UPDATE_SEGMENT = gql`
  mutation UpdateSegment($input: UpdateSegmentInput!) {
    updateSegment(input: $input) {
      id
    }
  }
`;

const removeKeys = (segment: SegmentInput): any => {
  if (_.isObject(segment)) {
    if (_.isArray(segment)) {
      return _.map(segment, removeKeys);
    } else {
      return _.mapValues(_.omit(segment, 'key'), removeKeys);
    }
  } else {
    return segment;
  }
};

const SegmentEditor: React.FC<SegmentEditorProps> = ({
  onCancel,
  onSuccess,
  segmentId,
}) => {
  const [operator] = useOperator();
  const [finished, setFinished] = useState(false);

  const isSmallerThanMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const { data: existingSegmentData, error: existingSegmentError } = useQuery<
    SegmentDetailQueryResponse,
    SegmentDetailQueryVariables
  >(GET_SEGMENT, {
    skip: !segmentId,
    variables: { operatorId: operator!.id, segmentId: segmentId || '' },
  });

  const [mutate, { loading, error: mutationError }] = useMutation<SegmentInput>(
    segmentId ? UPDATE_SEGMENT : CREATE_SEGMENT,
    {
      onCompleted: (data) => {
        onSuccess();
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  let segmentInput: SegmentInput = {
    operatorId: operator!.id,
    title: '',
    description: null,
    ruleSets: [
      {
        key: uuidv4(),
        logic: 'AND',
        set: 'UNION',
        rules: [
          {
            key: uuidv4(),
            logic: 'AND',
            conditions: [
              {
                key: uuidv4(),
                attributeId: null,
                relation: null,
                values: null,
                negate: false,
                preconditions: null,
              },
            ],
          },
        ],
      },
    ],
  };

  const existingSegment: Segment | undefined =
    existingSegmentData?.operator.segment;

  if (segmentId && existingSegment) {
    segmentInput = {
      ..._.pick(existingSegment, ['id', 'operatorId', 'title', 'description']),
      ruleSets: existingSegment.ruleSets.map((ruleSet) => ({
        ..._.pick(ruleSet, ['logic', 'set']),
        rules: ruleSet.rules.map((rule) => ({
          ..._.pick(rule, ['logic']),
          conditions: rule.conditions.map((condition) => ({
            ..._.pick(condition, [
              'attributeId',
              'relation',
              'values',
              'negate',
            ]),
            preconditions:
              condition.preconditions?.map((precondition) => ({
                ..._.pick(precondition, [
                  'attributeId',
                  'relation',
                  'values',
                  'negate',
                ]),
                key: uuidv4(),
              })) || null,
            key: uuidv4(),
          })),
          key: uuidv4(),
        })),
        key: uuidv4(),
      })),
    };
  }

  const stepLabels = ['Title and Description', 'Definition', 'Review'];

  const handleSubmit = (values: FormikValues) => {
    mutate({ variables: { input: removeKeys(values as SegmentInput) } });
    setFinished(true);
  };

  if (existingSegmentError) {
    return (
      <Alert severity="error">
        <Typography fontSize="small" noWrap>
          {existingSegmentError.message}
        </Typography>
      </Alert>
    );
  }

  return (
    <Card>
      <Box p={2}>
        <Typography variant="h6">
          {segmentId ? 'Update' : 'Create'} Segment
        </Typography>
        <Box mt={2}>
          <FormikWizard
            initialValues={segmentInput}
            onSubmit={handleSubmit}
            validateOnNext
            validateOnChange={false}
            validateOnBlur={false}
            activeStepIndex={0}
            steps={[
              {
                component: SegmentTitle,
                validationSchema: Yup.object().shape({
                  title: Yup.string().required('Title is required'),
                }),
              },
              {
                component: Definition,
                validationSchema: Yup.object().shape({
                  ruleSets: Yup.array()
                    .of(
                      Yup.object().shape({
                        logic: Yup.string().required(
                          'Logic operator is required',
                        ),
                        set: Yup.string().required('Set operator is required'),
                        rules: Yup.array().of(
                          Yup.object().shape({
                            logic: Yup.string().required(
                              'Logic operator is required',
                            ),
                            conditions: Yup.array().of(
                              Yup.object().shape({
                                attributeId: Yup.string().required(
                                  'Attribute is required',
                                ),
                                relation: Yup.string().required(
                                  'Relation is required',
                                ),
                                values: Yup.array()
                                  .of(
                                    Yup.string().required('Value is required'),
                                  )
                                  .required('Value is required'),
                                preconditions: Yup.array()
                                  .of(
                                    Yup.object().shape({
                                      attributeId: Yup.string().required(
                                        'Attribute is required',
                                      ),
                                      relation: Yup.string().required(
                                        'Relation is required',
                                      ),
                                      values: Yup.array()
                                        .of(
                                          Yup.string().required(
                                            'Value is required',
                                          ),
                                        )
                                        .required('Value is required'),
                                    }),
                                  )
                                  .nullable(),
                              }),
                            ),
                          }),
                        ),
                      }),
                    )
                    .min(1, 'At least one rule set should be selected'),
                }),
              },
              {
                component: SegmentReview,
              },
            ]}
          >
            {({
              currentStepIndex = 0,
              renderComponent,
              handlePrev,
              handleNext,
              isPrevDisabled,
              isLastStep,
            }) => {
              return (
                <>
                  <Box sx={{ width: '100%', height: '100%' }}>
                    <Stepper activeStep={currentStepIndex}>
                      <Step completed={currentStepIndex > 0}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[0]}
                        </StepLabel>
                      </Step>
                      <Step completed={currentStepIndex > 1}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[1]}
                        </StepLabel>
                      </Step>
                      <Step completed={finished}>
                        <StepLabel>
                          {isSmallerThanMd ? '' : stepLabels[2]}
                        </StepLabel>
                      </Step>
                    </Stepper>
                  </Box>
                  <Box mt={2} display={{ md: 'none' }}>
                    <Typography variant="button">
                      {stepLabels[currentStepIndex]}
                    </Typography>
                  </Box>
                  <Box>
                    <SchemasProvider>{renderComponent()}</SchemasProvider>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ mt: 2 }}
                  >
                    <Button variant="contained" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Stack direction="row" spacing={1}>
                      {mutationError ? (
                        <Alert severity="error" sx={{ py: 0 }}>
                          <Typography fontSize="small" noWrap>
                            {mutationError.message}
                          </Typography>
                        </Alert>
                      ) : null}
                      <Button
                        variant="contained"
                        disabled={isPrevDisabled}
                        onClick={handlePrev}
                      >
                        Previous
                      </Button>
                      {isLastStep ? (
                        <LoadingButton
                          loading={loading}
                          loadingPosition="start"
                          startIcon={segmentId ? <Save /> : <Add />}
                          variant="contained"
                          onClick={handleNext}
                        >
                          {segmentId ? 'Update' : 'Create'} Segment
                        </LoadingButton>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={(e) => {
                            handleNext();
                          }}
                        >
                          Next
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </>
              );
            }}
          </FormikWizard>
        </Box>
      </Box>
    </Card>
  );
};

export default SegmentEditor;
