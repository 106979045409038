import { gql } from '@apollo/client';

export const GET_OPERATOR_STATS = gql`
  query GetOperatorStats($operatorId: ID!) {
    operator(id: $operatorId) {
      customersCount,
      campaignsCount,
      segmentsCount,
      libraryTemplatesCount,
    }
  }
`;

