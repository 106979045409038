import { gql } from '@apollo/client';

export const UPDATE_LANGUAGE = gql`
  mutation UpdateLanguage($language: String!) {
    updateLanguage(language: $language) @client
  }
`;

export const UPDATE_THEME = gql`
  mutation UpdateTheme($theme: String!) {
    updateTheme(theme: $theme) @client
  }
`;
