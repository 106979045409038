import { gql } from '@apollo/client';

export const LIST_SEGMENTS = gql`
  query ListSegments($operatorId: ID!, $nextToken: ID) {
    operator(id: $operatorId) {
      segmentsCount
      customersCount
      segments(nextToken: $nextToken) {
        items {
          id
          title
          description
          createTime
        }
        nextToken
      }
    }
  }
`;