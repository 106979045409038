// RoutingLayer.tsx
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import {
  CampaignDetails,
  Campaigns,
  Customers,
  Dashboard,
  Reports,
  SignIn,
  Templates,
} from '../../page';
import { SegmentDetails, SegmentsList } from '../../page/Segment';
import TemplateDetails from '../../page/Templates/TemplateDetails';
import OperatorDetails from '../../page/UserProfile/OperatorDetails/OperatorDetails';

const RoutingLayer = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Navigate to="/dashboard" replace={true} />
            </PrivateRoute>
          }
        />
        <Route path="/signin" element={<SignIn />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/segments"
          element={
            <PrivateRoute>
              <SegmentsList />
            </PrivateRoute>
          }
        />
        <Route
          path="/segments/:segmentId"
          element={
            <PrivateRoute>
              <SegmentDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/templates"
          element={
            <PrivateRoute>
              <Templates />
            </PrivateRoute>
          }
        />
        <Route
          path="/templates/:templateId"
          element={
            <PrivateRoute>
              <TemplateDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/campaigns"
          element={
            <PrivateRoute>
              <Campaigns />
            </PrivateRoute>
          }
        />
        <Route
          path="/campaigns/:campaignId"
          element={
            <PrivateRoute>
              <CampaignDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/campaigns/archived"
          element={
            <PrivateRoute>
              <Campaigns />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <PrivateRoute>
              <Customers />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <OperatorDetails />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutingLayer;
