import * as React from 'react';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { lightTheme, darkTheme } from '../../styles/theme';
import { useQuery } from '@apollo/client';
import { GET_USER_SETTINGS } from '../../graphql/local';

type Props = {
  children: React.ReactNode;
};

export default function MUILayer({ children }: Props) {
  const { data } = useQuery(GET_USER_SETTINGS);

  const currentThemeMode = data?.userSettings?.theme || 'light';
  const theme = currentThemeMode === 'light' ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </ThemeProvider>
  );
}
