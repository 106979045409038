import { useQuery } from '@apollo/client';
import { Add, AddCircle, BubbleChart } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  Fab,
  Grid,
  LinearProgress,
  TablePagination,
  Tooltip,
  useTheme,
} from '@mui/material';

import { Campaign } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { ReactComponent as CampaignBannerLeftDarkImage } from '../../assets/illustrations/Campaigns/campaignsBannerDarkLeft.svg';
import { ReactComponent as CampaignBannerRightDarkImage } from '../../assets/illustrations/Campaigns/campaignsBannerDarkRight.svg';
import { ReactComponent as CampaignBannerLeftImage } from '../../assets/illustrations/Campaigns/campaignsBannerLeft.svg';
import { ReactComponent as CampaignBannerRightImage } from '../../assets/illustrations/Campaigns/campaignsBannerRight.svg';
import Banner from '../../components/Banner';
import CampaignCard from '../../components/Campaign/CampaignCard';
import KpiCard from '../../components/KpiCard';
import Navigation from '../../components/Navigation/Navigation';
import TabView from '../../components/TabView';
import {
  GET_ARCHIVED_CAMPAIGNS,
  GET_CAMPAIGNS_COUNT,
  GET_CURRENT_CAMPAIGNS,
} from '../../graphql/local/queries/campaigns';
import useOperator from '../../hooks/useOperator';
import {
  CampaignQueryResponse,
  CurrentCampaignStatus,
} from '../../types/campaign.types';
import CreateCampaign from './CreateCampaign';

export default function Campaigns() {
  const itemPerPage = 25;
  const navigate = useNavigate();
  const [operator] = useOperator();
  const [createCampaignOpen, setCreateCampaignOpen] = React.useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [currentPage, setCurrentPage] = useState(0);
  const [pageTokens, setPageTokens] = useState<Map<number, string | null>>(
    new Map([[0, null]]),
  );

  const tabValues = ['Current', 'Archived'];

  const [tabIndex, setTabIndex] = useLocalStorage('campaignsTabIndex', 0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    switch (tabValues.indexOf(newValue)) {
      case 0:
        handleRefresh();
        break;
      case 1:
        handleArchivedRefresh();
        break;
    }
    setPageTokens(new Map([[0, null]]));
    setCurrentPage(0);
    setTabIndex(tabValues.indexOf(newValue));
  };

  const createCampaignButtonClicked = () => {
    setCreateCampaignOpen(true);
  };

  const handleClose = () => {
    setCreateCampaignOpen(false);
  };

  const {
    data: archivedData,
    loading: archivedLoading,
    error: archivedError,
    refetch: archivedRefetcher,
  } = useQuery<CampaignQueryResponse>(GET_ARCHIVED_CAMPAIGNS, {
    variables: {
      operatorId: operator?.id || 0,
      status: CurrentCampaignStatus.ARCHIVED,
      nextToken: pageTokens.get(currentPage) || null,
    },
    skip: tabIndex === 0,
    pollInterval: 15000,
  });

  const handleArchivedRefresh = useCallback(() => {
    archivedRefetcher();
  }, [archivedRefetcher]);

  const archivedCampaigns =
    archivedData?.operator.archivedCampaigns?.items || [];

  React.useEffect(() => {
    handleArchivedRefresh();
  }, [operator, handleArchivedRefresh]);

  const {
    loading,
    error,
    data,
    refetch: campaignsRefetcher,
  } = useQuery<CampaignQueryResponse>(GET_CURRENT_CAMPAIGNS, {
    variables: {
      operatorId: operator?.id || 0,
      status: CurrentCampaignStatus.RUNNING,
      nextToken: pageTokens.get(currentPage) || null,
    },
    skip: tabIndex === 1,
    pollInterval: 15000,
  });

  const {
    data: campaignsCountData,
    fetchMore,
    refetch: campaignsCountRefetcher,
  } = useQuery(GET_CAMPAIGNS_COUNT, {
    variables: {
      operatorId: operator?.id || 0,
    },
    pollInterval: 15000,
  });

  const handleRefresh = useCallback(() => {
    campaignsRefetcher();
    campaignsCountRefetcher();
  }, [campaignsRefetcher, campaignsCountRefetcher]);

  const campaignsCount = campaignsCountData?.operator.campaignsCount;
  const activeCampaignsCount =
    campaignsCountData?.operator.activeCampaignsCount;
  const campaigns = data?.operator.currentCampaigns?.items || [];
  const [isLoading, setIsLoading] = React.useState(loading);

  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  React.useEffect(() => {
    handleRefresh();
    setCurrentPage(0);
    setPageTokens(new Map([[0, null]]));
  }, [operator, handleRefresh]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    if (loading || !data || page < 0 || page > pageTokens.size) {
      return;
    }

    var token: string | null = null;

    if (pageTokens.has(page)) {
      token = pageTokens.get(page)!;
    } else {
      token = data?.operator.currentCampaigns?.nextToken || null;
      setPageTokens((prev) => new Map(prev.set(page, token)));
    }

    setCurrentPage(page);

    fetchMore({
      variables: {
        nextToken: token,
      },
    });
  };

  const handleArchivedPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    if (
      archivedLoading ||
      !archivedData ||
      page < 0 ||
      page > pageTokens.size
    ) {
      return;
    }

    var token: string | null = null;

    if (pageTokens.has(page)) {
      token = pageTokens.get(page)!;
    } else {
      token = archivedData?.operator.archivedCampaigns?.nextToken || null;
      setPageTokens((prev) => new Map(prev.set(page, token)));
    }

    setCurrentPage(page);

    fetchMore({
      variables: {
        nextToken: token,
      },
    });
  };

  const archivedCampaignCount = campaignsCount - activeCampaignsCount;

  const page =
    tabIndex === 0
      ? currentPage > activeCampaignsCount / itemPerPage
        ? 0
        : currentPage
      : currentPage > archivedCampaignCount / itemPerPage
      ? 0
      : currentPage;

  return (
    <Navigation>
      <Banner
        leftImage={
          isDarkMode ? (
            <CampaignBannerLeftDarkImage />
          ) : (
            <CampaignBannerLeftImage />
          )
        }
        rightImage={
          isDarkMode ? (
            <CampaignBannerRightDarkImage />
          ) : (
            <CampaignBannerRightImage />
          )
        }
        kpiCards={[
          <KpiCard
            icon={<BubbleChart />}
            title="Total Campaigns"
            value={campaignsCount}
          ></KpiCard>,
          <KpiCard
            icon={<AddCircle />}
            title="Max Active Campaigns"
            value="25"
          ></KpiCard>,
          <KpiCard
            icon={<Campaign />}
            title="Active Campaigns"
            value={activeCampaignsCount}
          ></KpiCard>,
        ]}
      />
      <Dialog fullWidth maxWidth="md" open={createCampaignOpen}>
        <Box
          style={{
            maxHeight: '90%',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <CreateCampaign
            closeHandler={handleClose}
            refreshHandler={handleRefresh}
          />
        </Box>
      </Dialog>
      {tabIndex === 0 ? (
        <TablePagination
          component="div"
          count={activeCampaignsCount || 0}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={itemPerPage}
          rowsPerPageOptions={[itemPerPage]}
          nextIconButtonProps={{
            disabled:
              loading ||
              activeCampaignsCount < itemPerPage ||
              page === activeCampaignsCount / itemPerPage - 1,
          }}
          backIconButtonProps={{ disabled: loading || page === 0 }}
        />
      ) : (
        <TablePagination
          component="div"
          count={archivedCampaignCount || 0}
          page={page}
          onPageChange={handleArchivedPageChange}
          rowsPerPage={itemPerPage}
          rowsPerPageOptions={[itemPerPage]}
          nextIconButtonProps={{
            disabled:
              archivedLoading ||
              archivedCampaignCount < itemPerPage ||
              page === archivedCampaignCount / itemPerPage - 1,
          }}
          backIconButtonProps={{ disabled: archivedLoading || page === 0 }}
        />
      )}

      {isLoading && <LinearProgress />}
      {error && (
        <Alert severity="error">
          <AlertTitle>Error fetching campaigns.</AlertTitle>
          {error.message}
        </Alert>
      )}

      {archivedError && (
        <Alert severity="error">
          <AlertTitle>Error fetching archived campaigns.</AlertTitle>
          {archivedError.message}
        </Alert>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TabView
            tabValues={tabValues}
            defaultValue={tabValues[tabIndex]}
            onChange={handleTabChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {tabIndex === 0 &&
          (campaigns || archivedCampaigns).map((campaign) => (
            <Grid item key={campaign.id} xs={12} md={6} lg={6} xl={4}>
              <CampaignCard
                campaignId={campaign.id}
                campaignState={campaign.state}
                campaignTitle={campaign.title}
                messageType={campaign.messageType}
                schedulingType={campaign.executionSpec.schedulingType}
                onDetailsClick={() => {
                  navigate(`/campaigns/${campaign.id}`);
                }}
                stats={campaign.stats}
                createTime={campaign.createTime}
                progress={campaign.progress}
              />
            </Grid>
          ))}
        {tabIndex === 1 &&
          archivedCampaigns.map((campaign) => (
            <Grid item key={campaign.id} xs={12} md={6} lg={6} xl={4}>
              <CampaignCard
                campaignId={campaign.id}
                campaignState={campaign.state}
                campaignTitle={campaign.title}
                messageType={campaign.messageType}
                schedulingType={campaign.executionSpec.schedulingType}
                onDetailsClick={() => {
                  navigate(`/campaigns/${campaign.id}`);
                }}
                stats={campaign.stats}
                createTime={campaign.createTime}
                progress={campaign.progress}
              />
            </Grid>
          ))}
      </Grid>
      <Tooltip title={`Create New Campaign`}>
        <Fab
          style={{
            margin: '0px',
            top: 'auto',
            right: '20px',
            bottom: '20px',
            left: 'auto',
            position: 'fixed',
          }}
          onClick={createCampaignButtonClicked}
          color="primary"
          aria-label="add"
        >
          <Add />
        </Fab>
      </Tooltip>
    </Navigation>
  );
}
