import { ApolloCache, InMemoryCache } from '@apollo/client';
import { GET_USER_SETTINGS } from './queries/userSettings';

// Define the types
type ResolverArgs = {
  language?: string;
  theme?: string;
};

type ResolverContext = {
  cache: ApolloCache<InMemoryCache>;
};

export const resolvers = {
  Mutation: {
    updateLanguage: (
      _root: any,
      { language }: ResolverArgs,
      { cache }: ResolverContext,
    ) => {
      const existingData: any = cache.readQuery({ query: GET_USER_SETTINGS });
      const newData = {
        userSettings: {
          ...existingData.userSettings,
          __typename: 'UserSettings',
          language,
        },
      };
      cache.writeQuery({ query: GET_USER_SETTINGS, data: newData });
      return null;
    },
    updateTheme: (
      _root: any,
      { theme }: ResolverArgs,
      { cache }: ResolverContext,
    ) => {
      const existingData: any = cache.readQuery({ query: GET_USER_SETTINGS });
      const newData = {
        userSettings: {
          ...existingData.userSettings,
          __typename: 'UserSettings',
          theme,
        },
      };
      cache.writeQuery({ query: GET_USER_SETTINGS, data: newData });
      return null;
    },
  },
};
