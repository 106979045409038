import {
  Box,
  Paper,
  Stack,
  SvgIconProps,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import _ from 'lodash';
import ChecklistAllIcon from '../../../icons/ChecklistAllIcon';
import ChecklistAnyIcon from '../../../icons/ChecklistAnyIcon';

type LogicalOperatorOption = 'AND' | 'OR' | 'EXCEPT';

interface Option {
  value: LogicalOperatorOption;
  label: string;
  icon: React.FC<SvgIconProps>;
}

const Options: Option[] = [
  {
    value: 'AND',
    label: 'ALL',
    icon: ChecklistAllIcon,
  },
  {
    value: 'OR',
    label: 'ANY',
    icon: ChecklistAnyIcon,
  },
];

interface LogicalOperatorTBGProps extends ToggleButtonGroupProps {
  tbgProps?: ToggleButtonGroupProps;
  value: LogicalOperatorOption | null;
  onChange: any;
  hideIcons?: boolean;
  hideText?: boolean;
  values?: LogicalOperatorOption[];
  error?: string;
}

const LogicalOperatorTBG: React.FC<LogicalOperatorTBGProps> = ({
  tbgProps,
  value,
  values,
  onChange,
  hideIcons,
  hideText,
  error,
}) => {
  const valueOptions: LogicalOperatorOption[] = values || ['AND', 'OR'];
  const disabled = valueOptions.length === 1;
  const options = _.filter(Options, (o) => _.includes(valueOptions, o.value));

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'inline-flex',
        visibility: disabled ? 'hidden' : 'visible',
        ...(error ? { color: 'error.main' } : {}),
      }}
    >
      <Stack
        direction={tbgProps?.orientation !== 'vertical' ? 'row' : 'column'}
        spacing={1}
      >
        <ToggleButtonGroup
          orientation="horizontal"
          size="small"
          color="primary"
          {...tbgProps}
          sx={{
            ...tbgProps?.sx,
            ...(error
              ? {
                  outlineColor: 'error.main',
                  outlineWidth: 1.4,
                  outlineOffset: 1.5,
                  outlineStyle: 'dashed',
                }
              : {}),
          }}
          exclusive
          value={value}
        >
          {options.map((option) => (
            <ToggleButton
              key={option.value}
              value={option.value}
              onClick={(e) => {
                onChange({ target: { value: option.value } });
              }}
            >
              {hideIcons ? null : (
                <option.icon sx={{ mr: 0.5 }} fontSize="small" />
              )}
              {hideText ? null : option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {error &&
          (tbgProps?.orientation !== 'vertical' ? (
            <Box sx={{ px: 0.5, pt: 0.8 }}>{error}</Box>
          ) : (
            <Box
              sx={{
                mx: 1.5,
                mt: 0.8,
                maxWidth: 10,
              }}
            >
              Required
            </Box>
          ))}
      </Stack>
    </Paper>
  );
};

export default LogicalOperatorTBG;
