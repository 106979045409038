import { Auth } from 'aws-amplify';
import { Permission } from '../../hooks/useAuthenticatedUser';
import { AwsAmplifyUser } from '../../types/awsAmplify.types';
import { SignInResponseError, SignInResponseSuccess } from './types';

type SignInParameters = {
  username: string;
  password: string;
};

export interface AuthorizedUser {
  authorizations: Record<string, Permission[]>;
}

export async function signIn({
  username,
  password,
}: SignInParameters): Promise<SignInResponseSuccess | SignInResponseError> {
  try {
    const user: AwsAmplifyUser = await Auth.signIn(username, password);
    const attributes = user.attributes;

    const parsedUser: AuthorizedUser = {
      authorizations: JSON.parse(attributes['custom:ct_authorizations']),
    };

    if (Object.keys(parsedUser.authorizations).length < 1) {
      return {
        data: null,
        hasError: true,
      };
    }

    return {
      data: user,
      hasError: false,
    };
  } catch (error) {
    console.error('error signing in', error);
    return {
      data: null,
      hasError: true,
    };
  }
}
