import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ApolloLayer, MUILayer, RoutingLayer } from './layers';

function App() {
  return (
    <div className="ct-app">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ApolloLayer>
          <MUILayer>
            <RoutingLayer />
          </MUILayer>
        </ApolloLayer>
      </LocalizationProvider>
    </div>
  );
}

export default App;
