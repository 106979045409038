import { Add, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  Paper,
  Stack,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import {
  ConditionInput,
  RuleInput,
  SegmentInput,
} from '../../../../types/segment.types';
import LogicalOperatorTBG from '../components/LogicalOperatorTBG';
import ConditionForm from './Condition';

export interface RuleFormProps {
  path: string;
  value: RuleInput;
  handleDelete: any;
  hideDelete: boolean;
  errors?: FormikErrors<RuleInput> | string;
}

const RuleForm: React.FC<RuleFormProps> = ({
  handleDelete,
  value,
  path,
  hideDelete,
  errors,
}) => {
  const isSmallerThanMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const [focused, setFocused] = useState<boolean>(false);
  const { setFieldValue, handleChange, validateForm } =
    useFormikContext<SegmentInput>();

  const handleAddCondition = (): void => {
    const condition: ConditionInput = {
      key: uuidv4(),
      attributeId: null,
      relation: null,
      values: null,
      negate: false,
      preconditions: null,
    };
    validateForm().then((v) => {
      if (!v.ruleSets) {
        if (value.conditions.length === 1) {
          setFieldValue(`${path}.logic`, null);
        }
        setFieldValue(`${path}.conditions`, [...value.conditions, condition]);
      }
    });
  };

  const handleRemoveCondition = (key: string): void => {
    if (value.conditions.length === 2) {
      setFieldValue(`${path}.logic`, 'AND');
    }
    setFieldValue(`${path}.conditions`, [
      ...value.conditions.filter(
        (condition: ConditionInput) => condition.key !== key,
      ),
    ]);
  };

  const formikErrors =
    errors && typeof errors !== 'string'
      ? (errors as FormikErrors<RuleInput>)
      : undefined;
  //const errorText = errors && typeof errors === 'string' ? errors : undefined;

  return (
    <Paper
      variant="outlined"
      sx={{
        mt: 1,
        mb: 3,
        width: '100%',
        borderStyle: 'solid',
        borderColor: focused ? 'error.main' : 'divider',
      }}
    >
      <Stack>
        <Box
          sx={{
            mt: -2.5,
            ml: 2,
          }}
        >
          <LogicalOperatorTBG
            orientation="horizontal"
            hideText={isSmallerThanMd}
            value={value.logic}
            onChange={handleChange(`${path}.logic`)}
            values={value.conditions.length < 2 ? ['AND'] : undefined}
            error={formikErrors?.logic}
          />
        </Box>

        <Stack alignItems={'center'} direction="row">
          <List sx={{ width: '100%' }}>
            <TransitionGroup>
              {value.conditions.map(
                (condition: ConditionInput, index: number) => (
                  <Collapse key={condition.key} timeout={300}>
                    <ListItem>
                      <ConditionForm
                        value={condition}
                        path={`${path}.conditions.${index}`}
                        onRemove={() => handleRemoveCondition(condition.key)}
                        removeVisible={value.conditions.length > 1}
                        errors={formikErrors?.conditions?.[index]}
                      />
                    </ListItem>
                  </Collapse>
                ),
              )}
            </TransitionGroup>

            <Box
              sx={{
                mt: -1,
                ml: 1,
              }}
            >
              <Button
                size="small"
                onClick={handleAddCondition}
                startIcon={<Add />}
              >
                Add Condition
              </Button>
            </Box>
          </List>
          <Box
            sx={{
              visibility: hideDelete ? 'hidden' : 'visible',
              mr: -2.3,
              mt: -2,
            }}
          >
            <Paper elevation={0}>
              <ToggleButtonGroup color="primary" size="small">
                <ToggleButton
                  onMouseEnter={() => setFocused(true)}
                  onMouseLeave={() => setFocused(false)}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  onClick={handleDelete}
                  value={''}
                  color="primary"
                >
                  <Delete fontSize="small" color="error" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default RuleForm;
