import {
  Autocomplete,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Paper,
  Stack,
  TextField,
  createFilterOptions,
} from '@mui/material';
import _ from 'lodash';
import { FormEvent, useState } from 'react';
import { Attribute } from '../../../../../../types/segment.types';

interface MultipleSelectInputProps {
  value: string[] | null;
  onChange: any;
  attribute: Attribute;
  disabled?: boolean;
  error?: string;
  options: _.Dictionary<{
    label: string;
    value: string[];
    id: string;
  }>;
  forceChoices?: boolean;
}

const MultipleSelectInput: React.FC<MultipleSelectInputProps> = ({
  onChange,
  value,
  error,
  options,
  forceChoices,
}) => {
  const [inputValue, setInputValue] = useState<string | null>(null);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (o: string) => options[o].label,
  });

  const handleDelete = (val: string) => {
    onChange({ target: { value: value?.filter((v) => val !== v) } });
  };

  const handleAdd = (val: string[]) => {
    onChange({
      target: { value: _.uniq([...(value || []), ...val]) },
    });

    setInputValue(null);
  };

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    !forceChoices && handleAdd([inputValue || '']);
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 0,
        m: 0,
        width: '100%',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <FormControl fullWidth error={error ? true : false}>
          <form onSubmit={handleOnSubmit}>
            <Autocomplete
              size="small"
              slotProps={
                {
                  //paper: { sx: { p: 0, m: 0, borderStyle: 'none' } },
                }
              }
              sx={{
                p: 0,
                m: 0,
                borderStyle: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
              fullWidth
              options={_.difference(_.keys(options), value || [])}
              value={null}
              onChange={(__, newValue) => {
                if (newValue !== null) {
                  setInputValue(options[newValue]?.label || '');
                  handleAdd(options[newValue].value);
                }
              }}
              inputValue={inputValue || ''}
              getOptionLabel={(option) => options[option || '']?.label || ''}
              autoComplete
              onInputChange={(__, newInputValue) => {
                setInputValue(newInputValue);
              }}
              filterOptions={filterOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    forceChoices ? 'Select values' : 'Select or enter values'
                  }
                  size="small"
                />
              )}
            />
          </form>

          <FormHelperText>{error}</FormHelperText>
        </FormControl>
        <Divider
          sx={{
            mx: 1,
            borderStyle: 'dashed',
            visibility: value?.length ? 'visible' : 'hidden',
          }}
        />
        <Stack
          direction="row"
          sx={{ flexWrap: 'wrap', p: value?.length ? 1 : 0, m: 0 }}
        >
          {value?.map((v) => {
            return (
              <Chip
                size="small"
                sx={{ m: 0.3 }}
                label={v}
                key={v}
                onDelete={() => handleDelete(v)}
              />
            );
          })}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default MultipleSelectInput;
