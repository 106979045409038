import {
  Box,
  Paper,
  SvgIconProps,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import SetExcludeIcon from '../../../icons/SetExcludeIcon';
import SetIntersectionIcon from '../../../icons/SetIntersectionIcon';
import SetUnionIcon from '../../../icons/SetUnionIcon';

type SetOperatorOption = 'UNION' | 'INTERSECT' | 'EXCEPT';

interface Option {
  value: SetOperatorOption;
  label: string;
  icon: React.FC<SvgIconProps>;
}

const Options: Option[] = [
  { value: 'UNION', label: 'INCLUDE', icon: SetUnionIcon },
  { value: 'INTERSECT', label: 'INTERSECT', icon: SetIntersectionIcon },
  { value: 'EXCEPT', label: 'EXCLUDE', icon: SetExcludeIcon },
];

interface SetOperatorTBGProps extends ToggleButtonGroupProps {
  tbgProps?: ToggleButtonGroupProps;
  values?: SetOperatorOption[];
  value: SetOperatorOption | null;
  onChange: any;
  hideIcons?: boolean;
  hideText?: boolean;
  error?: string;
}

const SetOperatorTBG: React.FC<SetOperatorTBGProps> = ({
  tbgProps,
  value,
  onChange,
  hideIcons,
  hideText,
  values,
  error,
}) => {
  const valueOptions: SetOperatorOption[] = values || [
    'UNION',
    'INTERSECT',
    'EXCEPT',
  ];
  const disabled = valueOptions.length === 1;
  const options = _.filter(Options, (o) => _.includes(valueOptions, o.value));

  useEffect(() => {
    if (disabled) {
      onChange(valueOptions[0]);
    }
  }, [disabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'inline-flex',
        zIndex: 2,
        ...(error ? { color: 'error.main' } : {}),
      }}
    >
      <ToggleButtonGroup
        orientation="horizontal"
        size="small"
        color="primary"
        {...tbgProps}
        sx={{
          ...tbgProps?.sx,
          display: disabled ? 'none' : 'block',
          ...(error
            ? {
                outlineColor: 'error.main',
                outlineWidth: 1.4,
                outlineOffset: 1.5,
                outlineStyle: 'dashed',
              }
            : {}),
        }}
        exclusive
        value={value}
        disabled={disabled}
      >
        {options.map((option) => (
          <ToggleButton
            key={option.value}
            value={option.value}
            disabled={disabled}
            onClick={(e) => {
              onChange({ target: { value: option.value } });
            }}
          >
            {hideIcons ? null : (
              <option.icon sx={{ mr: 0.5 }} fontSize="small" />
            )}
            {hideText ? null : option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {error && <Box sx={{ mx: 1.5, mt: 0.8 }}>{error}</Box>}
    </Paper>
  );
};

export default SetOperatorTBG;
