import { useMutation } from '@apollo/client';
import LightThemeIcon from '@mui/icons-material/Brightness4Rounded';
import DarkThemeIcon from '@mui/icons-material/BrightnessHighRounded';
import SignoutIcon from '@mui/icons-material/ExitToAppRounded';
import MenuIcon from '@mui/icons-material/MenuRounded';
import {
  Box,
  IconButton,
  AppBar as MUIAppBar,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import CTLogo from '../../../../assets/branding/brand-logo.svg';
import { UPDATE_THEME } from '../../../../graphql/local';
import useAuthenticatedUser from '../../../../hooks/useAuthenticatedUser';
import useSignOut from '../../../../hooks/useSignOut';
import UserProfileButton from './UserProfileButton';

interface AppBarProps {
  toggleDesktopNav?: () => void;
  toggleMobileNav?: () => void;
}

const AppBar: FC<AppBarProps> = ({
  toggleDesktopNav = () => {},
  toggleMobileNav = () => {},
}) => {
  const { user, hasError, isLoading } = useAuthenticatedUser();
  const signOut = useSignOut();
  const [t] = useTranslation();
  const theme = useTheme();
  const [updateThemeMutation] = useMutation(UPDATE_THEME);

  const isDesktopSize = useMediaQuery(theme.breakpoints.up('lg'));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleTheme = () => {
    const newTheme = theme.palette.mode === 'light' ? 'dark' : 'light';
    updateThemeMutation({ variables: { theme: newTheme } });
  };

  const themeToggleTitle =
    theme.palette.mode === 'light'
      ? t('Switch to Dark Mode')
      : t('Switch to Light Mode');

  return (
    <>
      <Box sx={{ minHeight: '80px' }} />
      <MUIAppBar
        position="fixed"
        elevation={0}
        sx={{
          pt: '4px',
          pb: '4px',
          zIndex: theme.zIndex.drawer + 1,
          background:
            theme.palette.mode === 'dark'
              ? theme.palette.background.paper
              : theme.palette.secondary.main,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={isDesktopSize ? toggleDesktopNav : toggleMobileNav}
            edge="start"
            size="large"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <RouterLink to="/">
              <Box
                component="img"
                src={CTLogo}
                alt="clicktelligence logo"
                sx={{ height: 44, verticalAlign: 'middle' }}
              />
            </RouterLink>
          </Box>
          {isDesktopSize ? (
            <Box>
              <UserProfileButton
                user={user}
                isLoading={isLoading}
                hasError={hasError}
              />
              <Tooltip disableInteractive title={themeToggleTitle}>
                <IconButton onClick={toggleTheme} color="inherit" size="large">
                  {theme.palette.mode === 'dark' ? (
                    <LightThemeIcon />
                  ) : (
                    <DarkThemeIcon />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip disableInteractive title={t('Signout')}>
                <IconButton onClick={signOut} color="inherit" size="large">
                  <SignoutIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <IconButton onClick={handleClick} color="inherit" size="large">
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={toggleTheme}>
                  <Tooltip disableInteractive title={themeToggleTitle}>
                    <IconButton color="inherit" size="large">
                      {theme.palette.mode === 'dark' ? (
                        <LightThemeIcon />
                      ) : (
                        <DarkThemeIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    /* handle user profile click */
                  }}
                >
                  <UserProfileButton
                    user={user}
                    isLoading={isLoading}
                    hasError={hasError}
                  />
                </MenuItem>
                <MenuItem onClick={signOut}>
                  <Tooltip disableInteractive title={t('Signout')}>
                    <IconButton color="inherit" size="large">
                      <SignoutIcon />
                    </IconButton>
                  </Tooltip>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </MUIAppBar>
    </>
  );
};

export default AppBar;
