import { useMutation } from '@apollo/client';
import { Cancel } from '@mui/icons-material';
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React from 'react';
import { ABORT_EXECUTION } from '../../../graphql/local/mutations';
import { Execution, ExecutionState } from '../../../types/campaign.types';
import RadialChart from '../../Chart/RadialChart';
import LinearProgressWithPercentage from '../../CustomLinearProgress/LinearProgressPercentage';
import DownloadFilteredCustomersCSV from '../../Download/filteredCustomersCSV';

interface CampaignExecutionDetailsProps {
  execution: Execution;
  operatorId: string;
  campaignId: string;
  refreshHandler: () => void;
}

const CampaignExecutionDetails: React.FC<CampaignExecutionDetailsProps> = (
  props: CampaignExecutionDetailsProps,
) => {
  const [isErrorWindowOpen, setIsErrorWindowOpen] = React.useState(false);
  const [aborted, setAborted] = React.useState(false);

  const handleErrorOpen = () => {
    setIsErrorWindowOpen(true);
  };

  const handleErrorClose = () => {
    setIsErrorWindowOpen(false);
  };

  const [abortExecution, abortExecutionData] = useMutation(ABORT_EXECUTION);

  const abortExecutionClicked = async () => {
    try {
      await abortExecution({
        variables: {
          input: {
            id: props.execution.id,
            campaignId: props.campaignId,
            operatorId: props.operatorId,
          },
        },
      });
      setAborted(true);
    } catch (e) {
      setAborted(false);
      handleErrorOpen();
    } finally {
      props.refreshHandler();
    }
  };

  const executionStateTextMapping: Record<ExecutionState, string> = {
    [ExecutionState.SCHEDULED]: 'Execution scheduled.',
    [ExecutionState.START_REQUESTED]: 'Execution is starting...',
    [ExecutionState.TARGETING]:
      'Calculating the final target set for delivery...',
    [ExecutionState.MESSAGE_PROCESSING]: 'Rendering messages for targets...',
    [ExecutionState.DELIVERY]: 'Sending rendered messages to recipients...',
    [ExecutionState.SUCCEEDED]: 'Execution successfully finished.',
    [ExecutionState.FAILED]: 'Execution faced errors.',
  };
  const getExecutionStateText = (state: ExecutionState): string => {
    return executionStateTextMapping[state] || '';
  };

  const state = props.execution.state;
  const stateText = getExecutionStateText(state);

  const steps = ['Targeting', 'Rendering', 'Delivering'];
  const stepNumbers = [
    props.execution.progress.targetingExpectedCount > 0
      ? (props.execution.progress.targetingSucceededCount /
          props.execution.progress.targetingExpectedCount) *
        100
      : 0,

    props.execution.progress.renderingExpectedCount > 0
      ? (props.execution.progress.renderingSucceededCount /
          props.execution.progress.renderingExpectedCount) *
        100
      : 0,
    props.execution.progress.deliveryExpectedCount > 0
      ? (props.execution.progress.deliverySucceededCount /
          props.execution.progress.deliveryExpectedCount) *
        100
      : 0,
  ];

  const activeStep =
    props.execution.state === ExecutionState.TARGETING
      ? 0
      : props.execution.state === ExecutionState.MESSAGE_PROCESSING
      ? 1
      : props.execution.state === ExecutionState.DELIVERY
      ? 2
      : 3;

  const alertConfig = props.execution.isAborted
    ? ({ variant: 'outlined', severity: 'warning' } as const) // Use warning color for aborted executions
    : state === ExecutionState.FAILED
    ? ({ variant: 'outlined', severity: 'error' } as const)
    : state === ExecutionState.SUCCEEDED
    ? ({ variant: 'outlined', severity: 'success' } as const)
    : ({ variant: 'outlined', severity: 'info' } as const);

  return (
    <Box p={2}>
      <Dialog
        open={isErrorWindowOpen}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ERROR</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            An error occurred. Please try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Typography
        variant="h6"
        sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
      >
        Campaign Execution Details
      </Typography>
      <Box p={2}>
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                optional={
                  <LinearProgressWithPercentage
                    value={(index === 3 ? '' : stepNumbers[index]) as number}
                    expectedCount={
                      index === 0
                        ? props.execution.progress.targetingExpectedCount
                        : index === 1
                        ? props.execution.progress.renderingExpectedCount
                        : index === 2
                        ? props.execution.progress.deliveryExpectedCount
                        : 0
                    }
                    succeededCount={
                      index === 0
                        ? props.execution.progress.targetingSucceededCount
                        : index === 1
                        ? props.execution.progress.renderingSucceededCount
                        : props.execution.progress.deliverySucceededCount
                    }
                  />
                }
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadialChart
          labels={['Filtered', 'Targeted', 'Delivered', 'Views', 'Clicked']}
          series={[
            props.execution.stats.filteredCount,
            props.execution.stats.targetedCount,
            props.execution.stats.deliveredCount,
            props.execution.stats.viewCount,
            props.execution.stats.clickCount,
          ]}
        />
      </Box>
      {(state === ExecutionState.START_REQUESTED ||
        state === ExecutionState.SCHEDULED) &&
        !aborted && (
          <Button
            fullWidth
            color="error"
            variant="outlined"
            onClick={abortExecutionClicked}
            disabled={abortExecutionData.loading}
            startIcon={<Cancel />}
          >
            Cancel This Execution
          </Button>
        )}
      {props.execution.isAborted && (
        <Alert {...(alertConfig as AlertProps)} sx={{ mt: 2 }}>
          <Typography variant="body2">
            This execution has been aborted by user.
          </Typography>
        </Alert>
      )}

      {state !== ExecutionState.SUCCEEDED &&
        state !== ExecutionState.FAILED &&
        !aborted && (
          <Box>
            <Alert
              variant="outlined"
              severity="info"
              sx={{ width: '100%', mt: 2 }}
            >
              <Typography variant="body2" color="text.secondary">
                Number of customers in this execution might change over time.
              </Typography>
            </Alert>
            <Alert
              variant="outlined"
              severity="info"
              sx={{ width: '100%', mt: 2 }}
            >
              <Typography variant="body2" color="text.secondary">
                Some customers might be filtered out at campaign execution due
                to their communication preferences, marketing limits, etc.
              </Typography>
            </Alert>
          </Box>
        )}
      {!props.execution.isAborted && (
        <Alert {...(alertConfig as AlertProps)} sx={{ mt: 2 }}>
          <Typography variant="body2">{stateText}</Typography>
        </Alert>
      )}

      {props.execution.stats.filteredCount !== 0 && (
        <Box mt={1}>
          <DownloadFilteredCustomersCSV
            variables={{
              operatorId: props.operatorId,
              campaignId: props.campaignId,
              executionId: props.execution.id,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CampaignExecutionDetails;
