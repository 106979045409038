import { gql, useQuery } from '@apollo/client';
import { AddCircle, BubbleChart, Groups } from '@mui/icons-material';
import { ReactComponent as SegmentsBannerDarkLeftImage } from '../../../assets/illustrations/Segments/segmentsBannerDarkLeft.svg';
import { ReactComponent as SegmentsBannerDarkRightImage } from '../../../assets/illustrations/Segments/segmentsBannerDarkRight.svg';
import { ReactComponent as SegmentsBannerLightLeftImage } from '../../../assets/illustrations/Segments/segmentsBannerLeft.svg';
import { ReactComponent as SegmentsBannerLightRightImage } from '../../../assets/illustrations/Segments/segmentsBannerRight.svg';

import { useTheme } from '@mui/material';
import useOperator from '../../../hooks/useOperator';
import Banner from '../../Banner';
import KpiCard from '../../KpiCard';

interface CountsQueryVariables {
  operatorId: string;
}

type CountsQueryResponse = {
  operator: {
    segmentsCount: number;
    customersCount: number;
  };
};

const COUNTS = gql`
  query Counts($operatorId: ID!) {
    operator(id: $operatorId) {
      segmentsCount
      customersCount
    }
  }
`;

const SegmentsBanner: React.FC = () => {
  const [operator] = useOperator();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const { data } = useQuery<CountsQueryResponse, CountsQueryVariables>(COUNTS, {
    variables: { operatorId: operator!.id },
    notifyOnNetworkStatusChange: true,
    pollInterval: 15000,
  });
  return (
    <Banner
      leftImage={
        isDarkMode ? (
          <SegmentsBannerDarkLeftImage />
        ) : (
          <SegmentsBannerLightLeftImage />
        )
      }
      rightImage={
        isDarkMode ? (
          <SegmentsBannerDarkRightImage />
        ) : (
          <SegmentsBannerLightRightImage />
        )
      }
      kpiCards={[
        <KpiCard
          icon={<BubbleChart />}
          title="Total Segments"
          value={data?.operator.segmentsCount.toLocaleString()}
        />,
        <KpiCard icon={<AddCircle />} title="Max Segments" value="100" />,
        <KpiCard
          icon={<Groups />}
          title="Total Customers"
          value={data?.operator.customersCount.toLocaleString()}
        />,
      ]}
    />
  );
};

export default SegmentsBanner;
