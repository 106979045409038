import { useQuery } from '@apollo/client';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import React from 'react';
import { GET_CONNECTIONS } from '../../../graphql/local/queries';
import useOperator from '../../../hooks/useOperator';
import { ConnectionsQueryResponse } from '../../../types/connection.types';
import { PlainLoading } from '../../Loading';
interface SelectConnectionProps {
  errors: FormikErrors<{
    connectionId: string;
  }>;
  values: FormikValues;
  handleChange: any;
}

const SelectConnection: React.FC<SelectConnectionProps> = ({
  errors,
  values,
  handleChange,
}) => {
  const [operator] = useOperator();

  const { loading, error, data } = useQuery<ConnectionsQueryResponse>(
    GET_CONNECTIONS,
    {
      variables: {
        operatorId: operator?.id || 0,
      },
      onCompleted: (data) => {
        if (values.connectionId !== '' && data?.operator.connections?.length) {
          values.connectionName = connections?.find(
            (connection) => connection.id === values.connectionId,
          )?.label;
        }
      },
    },
  );

  const connections = data?.operator.connections?.filter(
    (connection) => connection.connectorKind === values.campaignType,
  );
  const [isLoading, setIsLoading] = React.useState(loading);

  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  if (isLoading) {
    return <PlainLoading />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Grid
      container
      spacing={3}
      sx={{
        py: {
          md: 5,
          xs: 2,
        },
      }}
    >
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="connection-label">Connection</InputLabel>
          <Select
            fullWidth
            labelId="connection-label"
            id="connectionId"
            value={values.connectionId}
            onChange={(e) => {
              values.connectionName = connections?.find(
                (connection) => connection.id === e.target.value,
              )?.label;
              handleChange('connectionId')(e);
            }}
            error={!!errors.connectionId}
            label="Connection"
            displayEmpty
          >
            {connections?.map((connection) => (
              <MenuItem key={connection.id} value={connection.id}>
                {connection.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SelectConnection;
