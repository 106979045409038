import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

const SetIntersectionIcon: React.FC<SvgIconProps> = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg
        fill="none"
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 4.5C2 3.11929 3.11929 2 4.5 2H10.5C11.8807 2 13 3.11929 13 4.5V7H15.5C16.8807 7 18 8.11929 18 9.5V15.5C18 16.8807 16.8807 18 15.5 18H9.5C8.11929 18 7 16.8807 7 15.5V13H4.5C3.11929 13 2 11.8807 2 10.5V4.5ZM12 4.5C12 3.67157 11.3284 3 10.5 3H4.5C3.67157 3 3 3.67157 3 4.5V10.5C3 11.3284 3.67157 12 4.5 12H7V9.5C7 9.1439 7.07445 8.8052 7.20864 8.49859C7.44841 7.95074 7.8789 7.5054 8.41615 7.24653C8.44335 7.23342 8.47084 7.22079 8.49858 7.20864C8.80519 7.07445 9.1439 7 9.5 7H12V4.5ZM8 10.7072V12H8.29302L12 8.293V8H10.7072L8 10.7072ZM8.01654 9.27648L9.27648 8.01654C8.62699 8.11358 8.11357 8.62699 8.01654 9.27648ZM13 8V10.5C13 11.8807 11.8807 13 10.5 13H8V15.5C8 16.3284 8.67157 17 9.5 17H15.5C16.3284 17 17 16.3284 17 15.5V9.5C17 8.67157 16.3284 8 15.5 8H13ZM9.70723 12H10.5C11.3284 12 12 11.3284 12 10.5V9.70722L9.70723 12Z"
          fill={theme.palette.primary.main}
        />
      </svg>
    </SvgIcon>
  );
};

export default SetIntersectionIcon;
