import { ButtonBase, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

interface SelectKindButtonProps {
  title: string;
  description: string;
  selected?: boolean;
  disabled?: boolean;
  buttonImage: React.ReactElement;
  onClick?: () => void;
}

const SelectKindButton: React.FC<SelectKindButtonProps> = (
  props: SelectKindButtonProps,
) => {
  return (
    <ButtonBase
      disabled={props.disabled}
      onClick={props.onClick}
      sx={{ width: '100%' }}
    >
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          sx={{
            opacity: props.disabled ? '50%' : '100%',
            backgroundColor: props.selected ? 'lightgray' : '',
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ textAlign: 'center', mt: 1 }}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
              {props.description}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mt: 3,
              mb: 1,
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
          >
            {props.buttonImage}
          </Grid>
        </Grid>
      </Paper>
    </ButtonBase>
  );
};

export default SelectKindButton;
