import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ChangeEvent } from 'react';

interface BoolenInputProps {
  value: string[] | null;
  onChange: any;
  disabled?: boolean;
  error?: string;
}

const BoolenInput: React.FC<BoolenInputProps> = ({
  onChange,
  value,
  disabled,
  error,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      target: {
        value: e.target.value !== null ? [e.target.value] : null,
      },
    });
  };

  return (
    <FormControl fullWidth disabled={disabled} error={error ? true : false}>
      <RadioGroup
        row
        sx={{ mx: 1 }}
        value={value && value.length === 1 ? value[0] : null}
        onChange={handleChange}
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" />
        <FormControlLabel value="false" control={<Radio />} label="No" />
      </RadioGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default BoolenInput;
