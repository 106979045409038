import { createTheme } from '@mui/material/styles';

export const globalThemeConfig = {
  drawerWidth: 240,
};

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FF4F79',
      dark: '#C6004D',
      light: '#FF85A8',
    },
    secondary: {
      main: '#232457',
      dark: '#17183B',
      light: '#5B5CAE',
    },

  },
});
