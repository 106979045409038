import { useQuery, gql } from '@apollo/client';
import { ArrowBackIos, Edit } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import {
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from '../../../components/Navigation/Navigation';
import DeleteSegmentButton from '../../../components/Segment/DeleteSegmentButton';
import SegmentCustomersCountCard from '../../../components/Segment/SegmentCustomersCountCard';
import SegmentEditor from '../../../components/Segment/SegmentEditor';
import SegmentRulesView from '../../../components/Segment/SegmentRulesView';
import useOperator from '../../../hooks/useOperator';
import { SchemasProvider } from '../../../layers/SchemasLayer';
import { Segment } from '../../../types/segment.types';
import { time, timeAgo } from '../../../utils/timeUtils';

interface SegmentDetailQueryVariables {
  operatorId: string;
  segmentId: string;
}

type SegmentDetailQueryResponse = {
  operator: {
    customersCount: number;
    segment: Segment;
  };
};

const GET_SEGMENT = gql`
  query GetSegment($operatorId: ID!, $segmentId: ID!) {
    operator(id: $operatorId) {
      customersCount
      segment(id: $segmentId) {
        id
        createTime
        description
        customersCount
        customersCountUpdateTime
        ruleSets {
          logic
          set
          rules {
            logic
            conditions {
              attributeId
              negate
              relation
              values
              preconditions {
                attributeId
                negate
                relation
                values
              }
            }
          }
        }
        title
        operatorId
      }
    }
  }
`;

const Detail: React.FC<{ segment: Segment; direction: 'row' | 'column' }> = ({
  segment,
  direction,
}) => {
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Chip label={`ID: ${segment.id}`} variant="outlined" />
      </Box>
      <Typography
        variant="h5"
        sx={{ mt: 1, ml: 0.5 }}
        color="text.primary"
        noWrap
      >
        {segment.title}
      </Typography>
      <Typography
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          lineClamp: 2,
          WebkitLineClamp: 2,
          lineHeight: '1.5em',
          minHeight: '3em',
          mt: 2,
          ml: 0.5,
          mb: 0,
        }}
        paragraph
        gutterBottom
        color="text.secondary"
        variant="h6"
      >
        {segment.description}
      </Typography>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
        <Stack direction={direction} spacing={1}>
          <Typography
            variant="body1"
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
            color="text.secondary"
          >
            <EventIcon sx={{ mr: 0.5 }} />
            {time(segment.createTime)}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
            color="text.secondary"
          >
            {`(${timeAgo(segment.createTime)})`}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

const SegmentDetail: React.FC = () => {
  const [operator, { prevOperator }] = useOperator();

  const { segmentId } = useParams<{ segmentId: string }>();
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery<
    SegmentDetailQueryResponse,
    SegmentDetailQueryVariables
  >(GET_SEGMENT, {
    skip: !operator || !segmentId,
    notifyOnNetworkStatusChange: true,
    variables: { operatorId: operator!.id, segmentId: segmentId! },
  });

  const segment = data?.operator.segment;
  const totalCustomersCount = data?.operator.customersCount || 1;

  useEffect(() => {
    if (prevOperator!.id !== operator!.id) {
      navigate(`/segments`);
    }
  }, [navigate, operator, prevOperator]);

  const [editSegmentDialogOpen, setEditSegmentDialogOpen] = useState(false);

  useEffect(() => {
    if (!segmentId) {
      navigate(`/segments`);
    }
  }, [navigate, segmentId]);

  const isSmallerThanMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const isSmallerThanSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const handleEditSegment = () => {
    setEditSegmentDialogOpen(true);
  };

  const handleEditSegmentDialogClose = () => {
    setEditSegmentDialogOpen(false);
  };

  if (error) {
    return (
      <Navigation>
        <p>Error: {error.message}</p>
      </Navigation>
    );
  }

  return (
    <Navigation>
      <SchemasProvider>
        <Stack spacing={1}>
          <Dialog fullWidth maxWidth="lg" open={editSegmentDialogOpen}>
            <SegmentEditor
              segmentId={segmentId}
              onCancel={handleEditSegmentDialogClose}
              onSuccess={() => {
                refetch();
                handleEditSegmentDialogClose();
              }}
            />
          </Dialog>
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <Button
              onClick={() => navigate('/segments')}
              startIcon={<ArrowBackIos />}
              sx={{ color: 'gray' }}
            >
              <Typography
                sx={{
                  display: isSmallerThanMd ? 'none' : 'block',
                }}
              >
                BACK TO SEGMENTS
              </Typography>
            </Button>
            <Stack direction="row" spacing={1}>
              <Button
                onClick={handleEditSegment}
                startIcon={<Edit />}
                sx={{ color: 'gray' }}
              >
                <Typography
                  sx={{
                    display: isSmallerThanMd ? 'none' : 'block',
                  }}
                >
                  Edit
                </Typography>
              </Button>
              <DeleteSegmentButton
                onSuccess={() => {
                  navigate('/segments');
                }}
                btnProps={{ sx: { color: 'gray' }, size: 'large' }}
                segmentId={segmentId || ''}
              />
            </Stack>
          </Stack>
          <LinearProgress
            sx={{ my: 1, visibility: loading ? 'visible' : 'hidden' }}
          />
          {segment &&
            (isSmallerThanMd ? (
              <Stack spacing={1} direction="column">
                <Detail
                  segment={segment}
                  direction={isSmallerThanSm ? 'column' : 'row'}
                />
                <SegmentCustomersCountCard
                  segment={segment}
                  totalCustomersCount={totalCustomersCount}
                />
                <SegmentRulesView ruleSets={segment?.ruleSets || []} />
              </Stack>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Stack spacing={1}>
                    <Detail
                      segment={segment}
                      direction={isSmallerThanSm ? 'column' : 'row'}
                    />
                    <SegmentRulesView ruleSets={segment?.ruleSets || []} />
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <SegmentCustomersCountCard
                    segment={segment}
                    totalCustomersCount={totalCustomersCount}
                  />
                </Grid>
              </Grid>
            ))}
        </Stack>
      </SchemasProvider>
    </Navigation>
  );
};
export default SegmentDetail;
