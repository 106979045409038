import {
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { FormEvent, useRef, useState } from 'react';
import { AttributeDataType } from '../../../../../../types/segment.types';

interface MultipleInputProps {
  value: string[] | null;
  onChange: any;
  disabled?: boolean;
  dataType: AttributeDataType;
  error?: string;
}

const MultipleInput: React.FC<MultipleInputProps> = ({
  onChange,
  value,
  dataType,
  error,
}) => {
  const tagRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<Dayjs | string | null>(null);

  const handleDelete = (val: string) => {
    onChange({ target: { value: value?.filter((v) => val !== v) } });
  };

  const handleAdd = () => {
    let val: string = '';

    if (inputValue === null) {
      return;
    }

    if (typeof inputValue === 'string' && inputValue.trim().length) {
      val = inputValue.trim();
    }

    if (
      inputValue instanceof dayjs &&
      inputValue !== null &&
      (inputValue as Dayjs).isValid()
    ) {
      val = (inputValue as Dayjs).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
    }

    if (val && !_.includes(value, val)) {
      onChange({
        target: { value: [...(value || []), val] },
      });
    }
    setInputValue(null);
  };

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAdd();
  };

  return (
    <Paper variant="outlined" sx={{ p: 0, m: 0, width: '100%' }}>
      <Stack sx={{ width: '100%' }}>
        <FormControl fullWidth error={error ? true : false}>
          <form onSubmit={handleOnSubmit}>
            {dataType === 'DATETIME' || dataType === 'DATE' ? (
              <DateTimePicker
                minutesStep={1}
                value={inputValue}
                onChange={(newValue) => setInputValue(newValue)}
                slotProps={{
                  textField: {
                    size: 'small',
                    variant: 'standard',
                    fullWidth: true,
                    sx: { m: 0, mt: 1, px: 1.5 },
                    InputProps: { disableUnderline: true },
                  },
                }}
                inputRef={tagRef}
              />
            ) : (
              <TextField
                InputProps={{ disableUnderline: true }}
                variant="standard"
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue || ''}
                inputRef={tagRef}
                fullWidth
                sx={{ m: 0, mt: 1, px: 1.5 }}
                size="small"
                placeholder="Enter value and press enter"
                type={
                  _.includes(['INTEGER', 'DOUBLE', 'MONEY'], dataType)
                    ? 'number'
                    : 'text'
                }
              />
            )}
          </form>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
        <Divider
          sx={{
            mx: 1,
            borderStyle: 'dashed',
            visibility: value?.length ? 'visible' : 'hidden',
          }}
        />
        <Stack
          direction="row"
          sx={{ flexWrap: 'wrap', p: value?.length ? 1 : 0, m: 0 }}
        >
          {value?.map((v) => {
            return (
              <Chip
                size="small"
                sx={{ m: 0.3 }}
                label={v}
                key={v}
                onDelete={() => handleDelete(v)}
              />
            );
          })}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default MultipleInput;
