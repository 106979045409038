import { TextField, OutlinedTextFieldProps } from "@mui/material"
import { useController, Control, FieldValues } from "react-hook-form"
import PropTypes from "prop-types"

type TextFieldProps = Omit<OutlinedTextFieldProps, "name" | "control" | "defaultValue" | "onChange" | "onBlur" | "ref" | "value" | "error" | "helperText">

interface Props<TFormValues extends FieldValues> extends TextFieldProps {
  name: TFormValues[Extract<keyof TFormValues, string>];
  control: Control<TFormValues>;
  defaultValue?: TFormValues[Extract<keyof TFormValues, string>];
}

/**
 * `ControlledTextField` is a React component that wraps the `TextField` component from the 
 * Material-UI library and integrates it with the `react-hook-form` library. It uses the 
 * `useController` hook from `react-hook-form` to control the `TextField` input.
 *
 * This component accepts all properties of the `OutlinedTextFieldProps` from Material-UI, 
 * excluding certain specific props that are internally handled ("name", "control", 
 * "defaultValue", "onChange", "onBlur", "ref", "value", "error", "helperText"). 
 * It requires the "name" and "control" props for `react-hook-form` integration.
 *
 * If a form error is detected, it will display the error message as a helper text under the input.
 * 
 * PropTypes are used to enforce the type of the props and defaultProps are used to provide 
 * default values for the props.
 *
 * @component
 * @param {object} props - The properties that define the component.
 * @param {string} props.name - The name of the form field.
 * @param {Control} props.control - The control object from `react-hook-form`.
 * @param {string} [props.defaultValue] - The default value of the form field.
 * @param {...TextFieldProps} textFieldProps - The remaining properties that are passed to the underlying `TextField` component.
 * @example
 * import { useForm } from 'react-hook-form';
 *
 * const formControl = useForm();
 * return (
 *   <ControlledTextField 
 *     name="myTextField" 
 *     control={formControl.control} 
 *     defaultValue="Default value" 
 *     variant="outlined" 
 *     size="medium" 
 *     fullWidth 
 *   />
 * )
 */
export default function ControlledTextField<TFormValues extends FieldValues>({ name, control, defaultValue, ...textFieldProps }: Props<TFormValues>) {
  /**
   * Hook the Input using useController
   */
  const {
    field: { ...fieldProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  })

  return (
    <TextField {...fieldProps} {...textFieldProps} error={!!error} helperText={error?.message} />
  )
}

ControlledTextField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
  size: PropTypes.oneOf(["small", "medium"]),
  fullWidth: PropTypes.bool,
}

ControlledTextField.defaultProps = {
  defaultValue: undefined,
  variant: "outlined",
  size: "medium",
  fullWidth: true,
}