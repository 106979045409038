import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { ReactElement } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

interface NavItemProps {
  to: string;
  label: string;
  iconComponent: ReactElement;
}

const NavItem: React.FC<NavItemProps> = ({ to, label, iconComponent }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <ListItemButton
      component={Link}
      to={to}
      sx={{
        borderRadius: '0px 8px 8px 0px',
        pl: 2,
        fontSize: 14,
        color: 'text.primary',
        ...(match && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemIcon>{iconComponent}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};

export default NavItem;
