import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { DrawerContent, MobileDrawer, DesktopDrawer } from "./components";
import { globalThemeConfig } from "../../../../styles/theme";

interface SideBarProps {
  children: ReactNode | ReactNode[];
  desktopNavOpen?: boolean;
  mobileNavOpen?: boolean;
  toggleMobileNav?: () => void;
}

const SideBar: FC<SideBarProps> = ({
  children,
  desktopNavOpen = false,
  mobileNavOpen = false,
  toggleMobileNav = () => { },
}) => {

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "80vh",
      }}
    >
      <Box
        component="nav"
        sx={{
          width: { lg: `${globalThemeConfig.drawerWidth}px` },
          flexShrink: { lg: 0 },
        }}
      >
        {/* Mobile Drawer */}
        <MobileDrawer open={mobileNavOpen} onClose={toggleMobileNav}>
          <DrawerContent />
        </MobileDrawer>

        {/* Desktop Drawer */}
        <DesktopDrawer open={desktopNavOpen}>
          <DrawerContent />
        </DesktopDrawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 2,
          pb: 3,
          pl: 3,
          pr: 3,
          ml: `-${globalThemeConfig.drawerWidth}px`,
          transition: (theme) =>
            theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),

          // on desktop nav open
          ...(desktopNavOpen && {
            transition: (theme) =>
              theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
            marginLeft: 0,
          }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SideBar;
