import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

/**
 * Custom hook to handle the sign out functionality using AWS Amplify.
 * When called, it attempts to sign the user out and, on success, redirects them to the sign-in page.
 * Logs an error to the console if sign out fails.
 *
 * @returns {Function} - A function that can be invoked to trigger the sign-out process.
 *
 * @example
 * const signOut = useSignOut();
 */
const useSignOut = () => {
  const navigate = useNavigate();

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.sessionStorage.removeItem('operator');
      window.localStorage.removeItem('operator');
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return signOut;
};

export default useSignOut;
