import { Add, Remove, SubdirectoryArrowRight } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { ChangeEvent } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import useSchemas from '../../../../hooks/useSchemas';
import {
  ConditionInput,
  PreconditionInput,
  SegmentInput,
} from '../../../../types/segment.types';
import AttributeSelect from '../components/AttributeSelect';
import RelationOperatorSelect from '../components/RelationOperatorSelect';
import ValueInput from '../components/ValueInput/ValueInput';
import PreconditionForm from './Precondition';

const setNull = { target: { value: null } } as ChangeEvent<any>;

interface ConditionFormProps {
  value: ConditionInput;
  path: string;
  removeVisible: boolean;
  onRemove: () => void;
  errors?: FormikErrors<ConditionInput> | string;
}

const ConditionForm: React.FC<ConditionFormProps> = ({
  removeVisible: removeButtonVisible,
  onRemove,
  value,
  path,
  errors,
}) => {
  const { setFieldValue, handleChange, setFieldError, validateForm } =
    useFormikContext<SegmentInput>();

  const isSmallerThanMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const handleAddPrecondition = (): void => {
    const precondition: PreconditionInput = {
      key: uuidv4(),
      attributeId: null,
      relation: null,
      values: null,
      negate: false,
    };

    validateForm().then((v) => {
      if (!v.ruleSets) {
        setFieldValue(`${path}.preconditions`, [
          ...(value.preconditions || []),
          precondition,
        ]);
      }
    });
  };

  const handleRemovePrecondition = (key: string): void => {
    setFieldValue(`${path}.preconditions`, [
      ...(value.preconditions || []).filter(
        (precondition: PreconditionInput) => precondition.key !== key,
      ),
    ]);
  };
  const schemas = useSchemas();

  const attributes = _.keyBy(
    _.flatMap(schemas, (schema) =>
      _.map(schema.attributes, (attr) =>
        _.assign({}, attr, {
          category: schema.title,
          label: `${schema.title} ${attr.title}`,
        }),
      ),
    ),
    'id',
  );

  const schema = schemas?.[attributes[value.attributeId || '']?.schemaId || ''];

  const formikErrors =
    errors && typeof errors !== 'string'
      ? (errors as FormikErrors<ConditionInput>)
      : undefined;
  //const errorText = errors && typeof errors === 'string' ? errors : undefined;

  const preconditionButtonVisible =
    schema?.preconditionAttributes &&
    (value.preconditions === null || value.preconditions?.length === 0)
      ? true
      : false;

  return (
    <Stack sx={{ width: '100%', my: 1 }}>
      <Grid
        container
        direction={isSmallerThanMd ? 'column' : 'row'}
        spacing={1}
        sx={{ width: '100%' }}
      >
        <Grid item xs={4}>
          <Stack direction="row">
            {isSmallerThanMd && !preconditionButtonVisible ? null : (
              <IconButton
                sx={{
                  visibility: preconditionButtonVisible ? 'visible' : 'hidden',
                }}
                color="primary"
                size="small"
                onClick={handleAddPrecondition}
              >
                <SubdirectoryArrowRight />
              </IconButton>
            )}
            <AttributeSelect
              attributes={attributes}
              inputLabel="Attribute"
              onChange={(e: React.ChangeEvent<any>) => {
                handleChange(`${path}.attributeId`)(e);
                if (formikErrors?.attributeId) {
                  setFieldError(`${path}.attributeId`, undefined);
                } else {
                  setFieldError(`${path}`, undefined);
                }
                handleChange(`${path}.relation`)(setNull);
                handleChange(`${path}.values`)(setNull);
                handleChange(`${path}.preconditions`)(setNull);
              }}
              value={value.attributeId}
              grouped={true}
              error={formikErrors?.attributeId}
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row">
            <RelationOperatorSelect
              disabled={!value.attributeId}
              onChange={(e: React.ChangeEvent<any>) => {
                handleChange(`${path}.relation`)(e);
                if (formikErrors?.relation) {
                  setFieldError(`${path}.relation`, undefined);
                } else {
                  setFieldError(`${path}.values`, undefined);
                }
                if (
                  e.target.value === 'IS_NULL' ||
                  e.target.value === 'ANNIVERSARY'
                ) {
                  const setTrue = {
                    target: { value: ['true'] },
                  } as ChangeEvent<any>;

                  handleChange(`${path}.values`)(setTrue);
                } else {
                  handleChange(`${path}.values`)(setNull);
                }
              }}
              values={
                attributes[value.attributeId || '']?.relationalOperators || []
              }
              value={value.relation}
              onNegateChange={handleChange(`${path}.negate`)}
              negate={value.negate}
              error={formikErrors?.relation}
            />
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack direction="row" spacing={1}>
            <ValueInput
              attribute={attributes[value.attributeId || '']}
              onChange={handleChange(`${path}.values`)}
              value={value.values}
              relation={value.relation}
              error={formikErrors?.values}
            />
            {isSmallerThanMd && !removeButtonVisible ? null : (
              <Box>
                <IconButton
                  sx={{
                    visibility: removeButtonVisible ? 'visible' : 'hidden',
                  }}
                  onClick={onRemove}
                  color="primary"
                  size="small"
                >
                  <Remove />
                </IconButton>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
      <List sx={{ width: '100%' }}>
        <TransitionGroup>
          {(value.preconditions || []).map(
            (precondition: PreconditionInput, index: number) => (
              <Collapse key={precondition.key} timeout={300}>
                <ListItem sx={{ py: 0.65 }}>
                  <PreconditionForm
                    schemaId={attributes[value.attributeId || '']?.schemaId}
                    value={precondition}
                    errors={formikErrors?.preconditions?.[index]}
                    path={`${path}.preconditions.${index}`}
                    onAdd={handleAddPrecondition}
                    onRemove={() => handleRemovePrecondition(precondition.key)}
                    addButtonVisible={(value.preconditions || []).length > 1}
                  />
                </ListItem>
              </Collapse>
            ),
          )}
        </TransitionGroup>

        {schema?.preconditionAttributes && value.preconditions?.length ? (
          <Grid
            container
            direction="row"
            spacing={1}
            sx={{
              borderLeftColor: 'divider',
              borderLeftStyle: 'dashed',
              ml: isSmallerThanMd ? 2 : 7,
              pl: 1,
              mt: -0.5,
              pt: 0,
              height: 14,
            }}
          >
            <Grid item xs={0.1} sx={{ p: 0, alignItems: 'center' }}>
              <Divider
                sx={{
                  height: 7,
                  ml: -2,
                  borderStyle: 'dashed',
                }}
              ></Divider>
            </Grid>
            <IconButton
              color="primary"
              size="small"
              onClick={handleAddPrecondition}
            >
              <Add fontSize="small" />
            </IconButton>
          </Grid>
        ) : null}
      </List>
    </Stack>
  );
};

export default ConditionForm;
